import React from 'react'
import Grid from '../Grid'
import type { DropdownSelectOption } from '../DropdownSelect'
import type { Moment } from 'moment'
import Select from '../Select'
import MenuItem from '../MenuItem'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  select: {
    padding: '4px !important',
    display: 'flex',
    boxShadow: 'none !important'
  },
  extraPadding: {
    paddingLeft: '8px !important'
  },
  selectRoot: {
    lineHeight: '1.5 !important',
    fontSize: '0.875rem !important',
    fontWeight: '500 !important',
    border: `1px solid ${theme.palette.grey.A100} !important`,
    borderRadius: `${theme.borderRadius.md} !important`,
    backgroundColor: theme.palette.background.paper + ' !important',
    '&:hover:not([aria-expanded="true"])': {
      borderColor: `transparent !important`,
      boxShadow: `0 0 0 2px ${theme.palette.grey[500]} !important`
    },

    '&[aria-expanded="true"]': {
      borderColor: 'transparent !important',
      boxShadow: `0 0 0 2px ${theme.paintscout.palette.primary} !important`
    }
  },
  selectIcon: {
    right: '0.25em !important',
    top: '4px !important',
    paddingLeft: '4px !important',
    width: '1em !important',
    transform: 'none !important'
  }
}))

interface DatePickerMonthDropdownProps {
  month: Moment
  onMonthSelect: (currentMonth: Moment, newMonthVal: string) => void
  onYearSelect: (currentMonth: Moment, newMonthVal: string) => void
}

export default function DatePickerMonthDropdown(props: DatePickerMonthDropdownProps) {
  const { month, onMonthSelect, onYearSelect } = props
  const classes = useStyles()

  const getYearOptions = () => {
    const currentYear = moment().year()
    const yearOptions: DropdownSelectOption[] = []
    for (let i = currentYear + 2; i >= currentYear - 10; i--) {
      yearOptions.push({ value: i, label: i.toString() })
    }
    return yearOptions
  }

  const yearOptions = getYearOptions()

  const monthOptions = [
    { value: 0, label: 'January' },
    { value: 1, label: 'February' },
    { value: 2, label: 'March' },
    { value: 3, label: 'April' },
    { value: 4, label: 'May' },
    { value: 5, label: 'June' },
    { value: 6, label: 'July' },
    { value: 7, label: 'August' },
    { value: 8, label: 'September' },
    { value: 9, label: 'October' },
    { value: 10, label: 'November' },
    { value: 11, label: 'December' }
  ]
  const monthValue = monthOptions.find((m) => m.value === month.month())
  const yearValue = { value: month.year(), label: month.year().toString() }

  return (
    <Grid container spacing={1}>
      <Grid item xs={7}>
        <Select
          classes={{
            outlined: classes.select,
            selectRoot: classnames(classes.selectRoot, classes.extraPadding),
            icon: classes.selectIcon
          }}
          value={monthValue.value}
          variant="outlined"
          fullWidth
          onChange={(e) => {
            const month = monthOptions.find((m) => m.value === e.target.value)
            if (month) {
              onMonthSelect(props.month, month.value.toString())
            }
          }}
        >
          {monthOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={5}>
        <Select
          classes={{
            outlined: classes.select,
            selectRoot: classes.selectRoot,
            icon: classes.selectIcon
          }}
          value={yearValue.value}
          variant="outlined"
          fullWidth
          onChange={(e) => {
            const year = yearOptions.find((m) => m.value === e.target.value)
            if (year) {
              onYearSelect(props.month, year.value.toString())
            }
          }}
        >
          {yearOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Grid>
  )
}
