/**
 * @module builder
 */
import type { QuoteDocument, OptionsDocument } from 'paintscout'
import type { UpdateableTerms } from '../../index'
import { template, createQuoteContext } from '../../../../templater'

export function getTerms(args: {
  quote: QuoteDocument
  options: OptionsDocument
  includePaymentTerms?: boolean
}): UpdateableTerms {
  const { quote, options, includePaymentTerms } = args
  const terms = quote?.terms

  const PAYMENT_TERMS = `
  <p></p>
  <h3>Payment Processing</h3>
  <p></p>
  <p><strong>Refund Policy:</strong> Refer to the above terms or contact us for details.</p>
  <p></p>
  <p><strong>Consumer Data Privacy:</strong> We protect your personal and payment information, collecting only what’s necessary to process transactions. Your data is not shared without consent, except when required by law. Encryption ensures security, and you can request access, modification, or deletion of your data anytime.</p>
  <p></p>
  <p><strong>Secure Checkout:</strong> All transactions are secured with SSL encryption. We work with trusted third-party processors to protect your payment details, and no sensitive information is stored on our servers.</p>
  `

  if (!terms) {
    return {
      name: '',
      label: '',
      custom: false,
      content: '',
      disabled: false,
      showToc: true
    }
  }

  const baseContent = terms.content
    ? template({ text: terms.content, context: createQuoteContext({ quote, options }) })
    : ''

  return {
    name: terms.name,
    label: terms.label,
    custom: terms.custom,
    disabled: terms.disabled,
    showToc: terms.showToc,
    content: includePaymentTerms ? `${baseContent}${PAYMENT_TERMS}` : baseContent
  }
}
