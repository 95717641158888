import { Grid } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import { createStyles, withStyles } from '@material-ui/core/styles'
import type { DialogProps, WithDialogStackContext } from '@ui/paintscout'
import {
  Button,
  ConfirmationDialog,
  Dialog,
  DialogActions,
  DialogContent,
  EditableDialogTitle,
  FormSection,
  InputField,
  withDialogStackContext
} from '@ui/paintscout'
import {
  copyRate,
  createRate,
  deleteRate,
  getObjectLabels,
  getRates,
  reorderRates,
  updateRateOption,
  updateRateSection
} from '@paintscout/util/builder'
import type { EditableRate, RateSectionOption } from '@paintscout/util/builder'
import clone from 'lodash/clone'
import type { OptionsDocument, RatesDocument } from 'paintscout'
import React from 'react'
import OptionsTileList from '../../OptionsTileList'
import QuoteTypeSelect from '../../QuoteTypeSelect'
import EditRateOptionDialog from '../EditRateOptionDialog'

export interface EditRateSectionDialogProps extends WithDialogStackContext, DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  item: RateSectionOption
  options: OptionsDocument
  rates: RatesDocument
  isNew?: boolean
  onConfirm?: (options: OptionsDocument, rates: RatesDocument, isDirty?: boolean) => void
  onCancel?: () => void
}

export interface EditRateSectionDialogState {
  item: RateSectionOption
  rates: RatesDocument
  isDirty: boolean
}

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    divider: {
      marginBottom: theme.spacing(4)
    },
    content: {
      minHeight: `400px`
    }
  })
}

class EditRateSectionDialog extends React.PureComponent<EditRateSectionDialogProps, EditRateSectionDialogState> {
  private signatureData?: string

  constructor(props: EditRateSectionDialogProps) {
    super(props)

    const { item, rates } = this.props

    this.state = {
      item: clone(item),
      rates,
      isDirty: false
    }
  }

  public render() {
    const {
      loading,
      classes,
      options,
      onConfirm: _onConfirm,
      dialogStackContext: _dialogStackContext,
      isNew: _isNew,
      ...otherProps
    } = this.props
    const { item, rates } = this.state

    const rateOptions = getRates({ options, rates, rateSection: item.key, inactive: true }).map((item) => {
      return { ...item, subLabel: item.caption }
    })

    const objectLabels = getObjectLabels({ options })

    const leftButton = (
      <Button disabled={loading} onClick={this.props.onCancel} variant={'text'}>
        Cancel
      </Button>
    )

    return (
      <Dialog classes={{ root: classes.root }} fullWidth={true} maxWidth={'md'} {...otherProps}>
        <EditableDialogTitle
          title={`${this.props.isNew ? 'Add' : 'Edit'} Rate Category`}
          loading={loading}
          onToggle={this.handleToggle}
          toggleValue={typeof item.active !== 'undefined' ? !!item.active : true}
          toggleLabel="Active"
        />
        <DialogContent className={classes.content}>
          <form
            id="rate-section-form"
            onSubmit={(e) => {
              e.preventDefault()
              this.handleConfirm(e)
            }}
          >
            <FormSection hideDivider={true} style={{ paddingTop: 0 }}>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12} sm={12} md={6}>
                  <InputField
                    label={'Label'}
                    name={'label'}
                    value={item.label}
                    fullWidth={true}
                    onChange={this.handleChange}
                    required
                    error={!item.label}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={12} md={6}>
                  <QuoteTypeSelect
                    label={`${objectLabels.quote.value} Types`}
                    variant="multi"
                    onChange={this.handleQuoteTypeChange}
                    value={item.quoteTypes}
                  />
                </Grid>
              </Grid>
            </FormSection>
            <FormSection>
              <OptionsTileList
                title={'Rates'}
                items={rateOptions.map((rate) => ({ ...rate, key: rate.name }))}
                showCreate={true}
                createTitle={'Add New Rate'}
                onReorder={this.handleReorderRates}
                onDeleteItem={this.handleDeleteRate}
                onEditItem={this.handleEditRate}
                onCopyItem={this.handleCopyItem}
                onCreateClick={this.handleCreateRate}
              />
            </FormSection>
          </form>
        </DialogContent>
        <DialogActions showBorder={true} leftButton={leftButton}>
          <Button type="submit" form="rate-section-form" disabled={loading} variant={'contained'}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  public handleReorderRates = (items: any) => {
    const { rates } = this.state
    const updatedRates = reorderRates({ rates, rateOptions: items })
    this.setState({ rates: updatedRates, isDirty: true })
    /* does nothing */
  }

  public handleDeleteRate = (event: any, key: string) => {
    const { dialogStackContext } = this.props
    dialogStackContext.openDialog(ConfirmationDialog, {
      message: 'Are you sure you want to delete this rate?',
      onConfirm: () => {
        const { options } = this.props
        const { rates, item } = this.state
        const rateOptions = getRates({ options, rates, rateSection: item.key, inactive: true })
        const { name } = rateOptions.find((rate) => rate.name === key)

        const updatedRates = deleteRate({ rates, key: name })

        this.setState({ rates: updatedRates, isDirty: true })

        dialogStackContext.dismissDialog()
      },
      onCancel: () => {
        dialogStackContext.dismissDialog()
      }
    })
  }

  public handleEditRate = (event: any, key: string) => {
    const { dialogStackContext, options } = this.props
    const { rates, item } = this.state
    const rateOptions = getRates({ options, rates, rateSection: item.key, inactive: true })

    dialogStackContext.openDialog(EditRateOptionDialog, {
      item: rateOptions.find((r) => r.name === key),
      quoteType: item.quoteTypes.length === 1 ? item.quoteTypes[0] : 'all',
      options,
      onConfirm: (updatedRate: EditableRate, isDirty?: boolean) => {
        if (isDirty) {
          const updatedRates = updateRateOption({ rates, updatedRate })

          this.setState({ rates: updatedRates, isDirty: true })
        }

        dialogStackContext.dismissDialog()
      },
      onCancel: () => {
        dialogStackContext.dismissDialog()
      }
    })
  }

  public handleCreateRate = (_event: React.MouseEvent<HTMLElement>) => {
    const { dialogStackContext, options } = this.props
    const { rates, item } = this.state

    const newRateOption = createRate({ rateSection: item.key })

    dialogStackContext.openDialog(EditRateOptionDialog, {
      item: newRateOption,
      quoteType: item.quoteTypes.length === 1 ? item.quoteTypes[0] : 'all',
      options,
      isNew: true,
      onConfirm: (updatedRate: EditableRate) => {
        const updatedRates = updateRateOption({ rates, updatedRate })
        this.setState({ rates: updatedRates, isDirty: true })
        dialogStackContext.dismissDialog()
      },
      onCancel: () => {
        dialogStackContext.dismissDialog()
      }
    })
  }

  public handleChange = (event: any) => {
    const { name, value } = event.target

    this.setState({
      item: {
        ...this.state.item,
        [name]: value
      },
      isDirty: true
    })
  }

  public handleCheckboxChange = (event: any, value: string) => {
    const { name } = event.target

    this.setState({
      item: {
        ...this.state.item,
        [name]: value
      },
      isDirty: true
    })
  }

  public handleConfirm = (_event: any) => {
    if (this.props.onConfirm) {
      const { options } = this.props
      const updatedOptions = updateRateSection({ options, rateSection: this.state.item })
      this.props.onConfirm(updatedOptions, this.state.rates, this.state.isDirty)
    }
  }

  public handleQuoteTypeChange = (values: string[]) => {
    this.setState({
      item: {
        ...this.state.item,
        quoteTypes: values
      },
      isDirty: true
    })
  }

  public handleToggle = (_event: React.ChangeEvent<HTMLElement>, _checked: boolean) => {
    this.setState({
      item: {
        ...this.state.item,
        active: !this.state.item.active
      },
      isDirty: true
    })
  }

  public handleCopyItem = (event: any, key: string) => {
    const rates = copyRate({ rates: this.state.rates, key })
    this.setState({ rates, isDirty: true })
  }
}

export default withStyles(styles)(withDialogStackContext(EditRateSectionDialog))
