import React from 'react'
import { Grid } from '@ui/paintscout'
import { makeStyles } from '@material-ui/core'
import { getPayments } from '@paintscout/util/builder'
import { useQuote } from '../context'
import PaymentRequest from '../PaymentRequest'

const useStyles = makeStyles((theme) => ({
  root: (props: PaymentRequestsProps) => ({
    ...(props.noTopMargin
      ? { marginTop: 0 }
      : {
          marginTop: theme.spacing(4),
          [theme.breakpoints.down('lg')]: {
            marginTop: theme.spacing(6)
          },
          [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(5)
          }
        }),
    '& > *:last-child': {
      marginBottom: '0 !important'
    }
  })
}))

interface PaymentRequestsProps {
  noTopMargin?: boolean
}

export default function PaymentRequests(props: PaymentRequestsProps) {
  const classes = useStyles(props)
  const { quote } = useQuote()
  const paymentRequests = getPayments({ quote })?.filter(
    (payment) => payment.status === 'pending' && payment.amount <= quote.totals.balance_due
  )

  if (!paymentRequests?.length) {
    return null
  }

  return (
    <Grid container justifyContent="center" className={classes.root}>
      {paymentRequests.map((payment) => {
        return <PaymentRequest key={payment.id} paymentRequest={payment} />
      })}
    </Grid>
  )
}
