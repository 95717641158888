import React, { useState, useEffect } from 'react'
import { useDialogs, useProcessEvent } from '@ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import { AlertDialog } from '@ui/paintscout'
import type { Theme } from '@material-ui/core/styles'
import { createStyles, withStyles } from '@material-ui/core/styles'

import { Elements as StripeElements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import type { QuoteEventType } from 'paintscout'

import { useSnackbar } from 'notistack'

import { getObjectLabels, getQuoteOptions } from '@paintscout/util/builder'

import { isEmailValid } from '@paintscout/util/util'
import type { CollectPaymentDialogProps } from '../CollectPaymentDialog/CollectPaymentDialog'
import CollectPaymentDialog from '../CollectPaymentDialog/CollectPaymentDialog'
import round from 'lodash/round'

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    slate: {
      minHeight: '10em'
    },
    dialogContent: {
      overflow: 'hidden',
      padding: theme.spacing(1, 2)
    },
    successCheckmark: {
      color: theme.palette.success.main,
      width: '3em',
      height: '3em'
    },
    checkContainer: {
      height: '100%'
    }
  })
}

function CollectStripePaymentDialog(props: CollectPaymentDialogProps) {
  const { onCancel, options, quote, isDeposit, source, amount, note, paymentRequestId, checkForMismatch } = props
  const { processEvent } = useProcessEvent()
  const objectLabels = getObjectLabels({ options, quote, invoice: quote.is_invoice })

  const [stripeOptions, setStripeOptions] = useState(null)
  const [stripePromise, setStripePromise] = useState(null)
  const { enqueueSnackbar } = useSnackbar()

  const { dismissAllDialogs, openDialog } = useDialogs()
  const [loading, setLoading] = useState(true)

  const { surcharge } = getQuoteOptions({ options, quote })
  const showSurcharge =
    !!surcharge?.enabled && surcharge?.value > 0 && (!isDeposit || (isDeposit && surcharge?.applyToDeposit))
  const roundedSurchargeAmount = showSurcharge ? round((amount * surcharge.value) / 100, 2) : 0
  const roundedAmount = round(amount, 2)

  useEffect(() => {
    async function process() {
      const res = await processEvent({
        type: 'get-payment-intent' as QuoteEventType,
        provider: 'stripe',
        params: {
          quoteId: quote._id,
          source,
          deposit: isDeposit,
          paymentAmount: roundedAmount + roundedSurchargeAmount,
          note: note?.length > 100 ? `${note.substring(0, 100)}...` : note,
          paymentRequestId,
          rev: checkForMismatch ? quote._rev : undefined,
          surcharge: roundedSurchargeAmount,
          customerViewURL: source === 'customer-view' ? window.location.toString() : undefined
        }
      })

      if (res.error || res.result?.error) {
        const error = (res.error || res.result?.error || 'Unknown Error').toString()
        if (error?.includes('Rev Mismatch')) {
          openDialog(AlertDialog, {
            title: `${objectLabels.quote.value} Has Been Updated`,
            icon: <ErrorIcon />,
            color: 'warning',
            message: `The ${objectLabels.quote.value} was updated by the estimator while you were viewing it. It may have been for internal purposes, but we suggest you look over it once more first.`,
            onConfirm: () => {
              dismissAllDialogs()
              window.location.reload()
            }
          })
        } else {
          enqueueSnackbar('Something went wrong. Please try again later.', { variant: 'error' })
          onCancel()
        }
      } else if (res.result) {
        setStripeOptions({
          clientSecret: res.result.result.clientSecret,
          account: res.result.result.account
        })
        setStripePromise(loadStripe(res.result.result.publishableKey))
      }
    }
    if (!paymentRequestId || isEmailValid(quote.contact?.email)) {
      process()
    } else {
      dismissAllDialogs()
      if (source === 'customer-view') {
        enqueueSnackbar('Something went wrong. Please contact your estimator', { variant: 'error' })
      } else {
        openDialog(AlertDialog, {
          title: 'Invalid Email',
          message: 'You must add a valid email address to your contact before you can request a payment',
          onConfirm: dismissAllDialogs
        })
        return
      }
    }
  }, [])

  return (
    <StripeElements stripe={stripePromise} options={stripeOptions}>
      <CheckoutForm loading={loading} setLoading={setLoading} showSurcharge={showSurcharge} {...props} />
    </StripeElements>
  )
}

function CheckoutForm({
  loading,
  setLoading,
  showSurcharge,
  ...props
}: Omit<CollectPaymentDialogProps, 'onSubmitPayment' | 'children'>) {
  const stripe = useStripe()
  const elements = useElements()

  async function handleSubmit() {
    return await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.toString()
      },
      redirect: 'if_required'
    })
  }

  return (
    <CollectPaymentDialog
      loading={loading}
      setLoading={setLoading}
      showSurcharge={showSurcharge}
      onSubmitPayment={handleSubmit}
      provider={'stripe'}
      {...props}
    >
      <PaymentElement
        onReady={() => {
          setLoading(false)
        }}
      />
    </CollectPaymentDialog>
  )
}
export default withStyles(styles)(CollectStripePaymentDialog)
