import React from 'react'
import type { GridJustification, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { FormikPresentationSectionConnectProps, FormikPresentationSectionValues } from '../../../'
import type { DropdownSelectOption } from '@ui/paintscout'
import {
  Grid,
  Select,
  MenuItem,
  UploadImage,
  Checkbox,
  FormControlLabel,
  InlineColorPicker,
  DropdownSelect
} from '@ui/paintscout'

import { connect } from 'formik'

const useStyles = makeStyles((_theme: Theme) => ({
  root: {},
  dropzone: {
    '& $button': {
      minHeight: 100
    }
  },
  checkboxLabel: {
    marginRight: 0
  },
  checkboxDiv: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  }
}))

export interface BackgroundOptionsProps {
  allowColor?: boolean
  allowStyle?: boolean
  allowImage?: boolean
  allowAlignOptions?: boolean
  allowPaddingOptions?: boolean
}

const BackgroundOptions = ({
  allowImage,
  allowAlignOptions,
  allowPaddingOptions,
  formik,
  ...props
}: BackgroundOptionsProps & FormikPresentationSectionConnectProps) => {
  const classes = useStyles(props)

  const { section } = formik.values
  const value = section?.background ?? {}
  const AlignmentSelect = React.useMemo(
    () => (
      <Grid className={classes.checkboxDiv} item xs={12} md={6}>
        <Select
          variant={'outlined'}
          fullWidth={true}
          label={'Alignment'}
          value={value?.alignment ?? 'flex-start'}
          onChange={handleAlignChange}
        >
          <MenuItem value="flex-start">Left Align</MenuItem>
          <MenuItem value="center">Center</MenuItem>
          <MenuItem value="space-evenly">Space Evenly</MenuItem>
        </Select>
      </Grid>
    ),
    [value?.alignment, handleAlignChange, classes.checkboxDiv]
  )

  const PaddingSelect = React.useMemo(
    () => (
      <Grid className={classes.checkboxDiv} item xs={12} md={6}>
        <Select
          variant={'outlined'}
          fullWidth={true}
          label={'Icon Padding'}
          value={value?.padding ?? 'flex-start'}
          onChange={handlePaddingChange}
        >
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
        </Select>
      </Grid>
    ),
    [value?.padding, handlePaddingChange, classes.checkboxDiv]
  )

  const backgroundOptions: DropdownSelectOption[] = [
    {
      label: 'Full-Width',
      value: 'full'
    },
    {
      label: 'Contained',
      value: 'contained'
    }
  ]
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <InlineColorPicker
              fullWidth={true}
              label={'Background Color'}
              value={value?.color ?? '#fff'}
              onChange={handleColorChange('color')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InlineColorPicker
              fullWidth={true}
              label={'Foreground Color'}
              value={value?.textColor ?? '#000'}
              onChange={handleColorChange('textColor')}
            />
          </Grid>
          {allowAlignOptions && allowPaddingOptions && (
            <>
              {AlignmentSelect}
              {PaddingSelect}
            </>
          )}
          <Grid item xs={12} md={6}>
            {/* <Select
              variant={'outlined'}
              fullWidth={true}
              label={'Background Style'}
              value={value?.style ?? 'full'}
              onChange={handleBackgroundChange}
            >
              <MenuItem value="full">Full-Width</MenuItem>
              <MenuItem value="contained">Contained</MenuItem>
            </Select> */}
            <DropdownSelect
              variant="single"
              options={backgroundOptions}
              value={backgroundOptions.find((option) => option.value === value?.style)}
              onChange={(option) => formik.setFieldValue('section.background.style' as any, option.value)}
            />
          </Grid>
          <Grid className={classes.checkboxDiv} item xs={12} md={6}>
            <FormControlLabel
              classes={{ root: classes.checkboxLabel }}
              control={<Checkbox checked={value?.noPadding ?? false} onClick={handleCheckboxChange('noPadding')} />}
              label={'No Padding'}
            />
          </Grid>
          {allowAlignOptions && !allowPaddingOptions && <>{AlignmentSelect}</>}
          {allowPaddingOptions && !allowAlignOptions && <>{PaddingSelect}</>}
        </Grid>
      </Grid>
      {allowImage && (
        <Grid item xs={12} md={4}>
          <UploadImage
            label={'Background Image'}
            s3PublicKey={value?.image?.s3PublicKey ?? ''}
            cloudinaryPublicId={value?.image?.cloudinaryPublicId ?? ''}
            src={value?.image?.src ?? ''}
            onUpload={handleImageUpload}
            onClear={handleImageClear}
            classes={{ dropzone: classes.dropzone }}
          />
        </Grid>
      )}
    </Grid>
  )

  function handleCheckboxChange(name: string) {
    return function () {
      formik.setFieldValue(`section.background.${name}` as any, !section?.background?.[name])
    }
  }

  function handlePaddingChange(ev: React.ChangeEvent<HTMLSelectElement>) {
    formik.setFieldValue(`section.background.padding`, Number(ev.target.value))
  }

  function handleAlignChange(ev: React.ChangeEvent<HTMLSelectElement>) {
    formik.setFieldValue(`section.background.alignment` as GridJustification, ev.target.value as GridJustification)
  }

  function handleColorChange(name: string) {
    return function (hex: string) {
      formik.setFieldValue(`section.background.${name}` as any, hex)
    }
  }

  async function handleImageUpload(imageArgs: {
    src: string
    format: string
    cloudinaryPublicId: string
    s3PublicKey: string
    width: number
    height: number
    type: string
  }) {
    formik.setFieldValue('section.background.image' as any, {
      ...imageArgs,
      visibility: 'visible'
    })
  }

  async function handleImageClear() {
    formik.setFieldValue('section.background.image' as any, null)
  }
}

export default connect<BackgroundOptionsProps, FormikPresentationSectionValues>(BackgroundOptions)
