import { makeStyles } from '@material-ui/core'
import type { CardProps as MuiCardProps } from '@material-ui/core/Card'
import { default as MuiCard } from '@material-ui/core/Card'
import classnames from 'classnames'
import React from 'react'
import type { Action } from '../ActionButton'
import ActionButton from '../ActionButton'
import CustomBadge from '../CustomBadge'
import type { WithUseStyles } from '../styles'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.body1,
    position: 'relative',
    minHeight: theme.typography.pxToRem(35),
    maxWidth: theme.typography.pxToRem(230),
    width: theme.typography.pxToRem(230),
    borderRadius: theme.borderRadius.md,
    background: theme.palette.common.white,
    borderColor: theme.palette.primary.main,
    borderLeft: `solid ${theme.typography.pxToRem(7)}`,
    padding: '1em',
    textAlign: 'left',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: '0.5s min-height',
    userSelect: 'none',
    '&:hover $dragIcon': {
      opacity: 1,
      pointerEvents: 'all'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'center',
    '& label': {
      cursor: 'pointer'
    }
  },
  layout: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  selected: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main
  },
  hasThumbnail: {
    // flexDirection: 'column'
    flexDirection: 'row'
  },
  thumbnail: {
    width: theme.typography.pxToRem(24),
    height: theme.typography.pxToRem(32),
    // marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& svg': {
      width: '100%',
      height: '100%'
    }
  },
  dense: {
    minHeight: 0,
    padding: '1em 1.5em'
  },
  disabled: {
    background: theme.palette.grey[300],
    borderColor: theme.palette.grey[700],
    color: theme.palette.grey[700],
    pointerEvents: 'none',
    cursor: 'default'
  },
  inactiveBadge: {
    backgroundColor: theme.palette.error.dark
  },
  inactive: {},
  actionButton: {
    margin: -12,
    color: theme.palette.grey.A400
  },
  dot: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    backgroundColor: 'red',
    alignSelf: 'flex-start',
    justifySelf: 'flex-end',
    position: 'absolute',
    top: 3,
    right: 3
  },
  dragIcon: {
    marginLeft: -theme.spacing(1.75),
    color: theme.palette.grey[300],
    cursor: 'grab',
    alignSelf: 'center',
    // drag icon is only visible on hover
    opacity: 0,
    pointerEvents: 'none',
    [theme.breakpoints.down('sm')]: {
      opacity: 1,
      pointerEvents: 'all'
    }
  }
}))

export interface TileProps extends WithUseStyles<typeof useStyles>, Omit<MuiCardProps, 'classes'> {
  children?: React.ReactNode
  selected?: boolean
  dense?: boolean
  disabled?: boolean
  active?: boolean
  id?: any
  Thumbnail?: React.FunctionComponent
  borderColor?: string
  actions?: Action[]
  draggable?: boolean
  onActionClick?: (event: React.MouseEvent<HTMLElement>, actionName: string, rowId: string | number) => void
}

const Tile = React.forwardRef(function Tile(props: TileProps, ref) {
  const classes = useStyles(props)

  const {
    selected: selectedClass,
    dense: denseClass,
    disabled: disabledClass,
    inactive: inactiveClass,
    content: contentClass,
    layout: layoutClass,
    thumbnail: thumbnailClass,
    hasThumbnail: hasThumbnailClass
  } = classes
  const {
    selected,
    dense,
    disabled,
    active,
    actions,

    Thumbnail,
    borderColor,
    draggable,
    ...baseCardProps
  } = props

  const cardClasses = [classes.root]
  const inactive = !active && typeof active !== 'undefined'
  if (selected) {
    cardClasses.push(selectedClass)
  } else if (disabled) {
    cardClasses.push(disabledClass)
  } else if (inactive) {
    cardClasses.push(inactiveClass)
  }

  if (dense) {
    cardClasses.push(denseClass)
  }

  const content =
    props.active || typeof props.active === 'undefined' ? (
      props.children
    ) : (
      <CustomBadge message={'This item is inactive and will not be shown.'} classes={{ root: classes.inactiveBadge }}>
        {props.children}
      </CustomBadge>
    )

  function handleClick(event: any) {
    if (props.onClick && !props.disabled) {
      props.onClick(event)
    }
  }
  return (
    <MuiCard
      component="button"
      // @ts-ignore - explicitly set type as 'button' to prevent submitting forms
      type="button"
      {...baseCardProps}
      ref={ref}
      onClick={handleClick}
      classes={{ root: cardClasses.join(' ') }}
      style={borderColor ? { borderColor: borderColor } : {}}
      data-testid="tile"
    >
      {inactive && <div className={classes.dot} />}
      <div className={classnames({ [layoutClass]: true, [hasThumbnailClass]: !!Thumbnail })}>
        {draggable && (
          <DragIndicatorIcon onClick={(e) => e.stopPropagation()} id="drag-indicator" className={classes.dragIcon} />
        )}
        {Thumbnail && (
          <div className={thumbnailClass}>
            <Thumbnail />
          </div>
        )}
        <div className={layoutClass}>
          <div className={contentClass}>{content}</div>
          {actions && (
            <ActionButton
              classes={{
                button: classes.actionButton
              }}
              onActionClick={(ev, action) => props.onActionClick(ev, action, props.id)}
              disabled={disabled}
              actions={props.actions}
            />
          )}
        </div>
      </div>
    </MuiCard>
  )
})

Tile.defaultProps = {
  color: 'primary',
  dense: false,
  disabled: false
}

export default Tile
