import React, { useRef, useState, useMemo } from 'react'
import { Grid } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { DialogProps } from '@ui/paintscout'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  EditableDialogTitle,
  InputField,
  Typography,
  Editor
} from '@ui/paintscout'
import type { TermsOption } from '@paintscout/util/builder'
import { getObjectLabels, updateTermsOption } from '@paintscout/util/builder'
import clone from 'lodash/clone'
import type { OptionsDocument } from 'paintscout'
import { makeStyles } from '@material-ui/core'

import QuoteTypeSelect from '../../QuoteTypeSelect'

export interface EditTermsOptionDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  item: TermsOption
  options: OptionsDocument
  onConfirm: (options: OptionsDocument, isDirty?: boolean) => void
  onCancel: () => void
}

export interface EditTermsOptionDialogState {
  item: TermsOption
  isDirty?: boolean
}
const useStyles = makeStyles<Theme, EditTermsOptionDialogProps>(
  (theme) => ({
    root: {},
    wrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    },
    editor: {
      flexGrow: 1
    },
    divider: {},
    content: {
      minHeight: `400px`
    },
    htmlContent: {
      padding: theme.spacing(),
      border: '2px solid #000'
    },
    disabledMessage: {
      marginBottom: theme.spacing(2)
    }
  }),
  { name: 'EditTermsOptionDialog' }
)

export default function EditTermsOptionDialog({
  loading,
  options,
  onConfirm,
  onCancel,
  item: propsItem,
  ...otherProps
}: EditTermsOptionDialogProps) {
  const classes = useStyles({ loading, onConfirm, onCancel, item: propsItem, options, ...otherProps })
  const [item, setItem] = useState(clone(propsItem))
  const [isDirty, setIsDirty] = useState(false)

  const objectLabels = useMemo(() => getObjectLabels({ options }), [])

  const contentRef = useRef(null)

  const leftButton = (
    <Button disabled={loading} onClick={onCancel} variant={'text'}>
      Cancel
    </Button>
  )

  return (
    <Dialog className={classes.root} maxWidth={'md'} fullWidth={true} {...otherProps}>
      <EditableDialogTitle
        title={'Edit Terms'}
        loading={loading}
        onToggle={handleToggle}
        toggleValue={typeof item.active !== 'undefined' ? !!item.active : true}
        toggleLabel="Active"
      />
      <DialogContent className={classes.content}>
        <form
          id="terms-option-form"
          onSubmit={(e) => {
            e.preventDefault()
            handleConfirm(e)
          }}
        >
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} sm={12} md={6}>
              <InputField
                label={'Label'}
                name={'label'}
                value={item.label}
                fullWidth={true}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item={true} xs={12} sm={12} md={6}>
              <QuoteTypeSelect
                label={`${objectLabels.quote.value} Types`}
                variant="multi"
                onChange={handleQuoteTypeChange}
                value={item.quoteTypes}
                placeholder={{
                  plural: `${objectLabels.quote.value} Types`
                }}
              />
            </Grid>
          </Grid>
          <Grid container={true} spacing={3}>
            <Grid item={true} xs={12} sm={12} md={12}>
              <div className={classes.wrapper}>
                {item.disabled && (
                  <Typography variant={'body2'} classes={{ root: classes.disabledMessage }}>
                    These terms have been customized to include images, videos, or other content not editable here.
                    Please contact us for assistance editing these terms.
                  </Typography>
                )}
                <Editor
                  key={item.name}
                  disabled={item.disabled}
                  label={'Terms Content'}
                  sublabel={'Make changes this terms template.'}
                  content={item.content}
                  fullWidth={true}
                  ref={contentRef}
                  toolbar={{
                    headings: true,
                    align: true
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions showBorder={true} leftButton={leftButton}>
        <Button type="submit" form="terms-option-form" disabled={loading} variant={'contained'}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )

  function handleChange(event: any) {
    const { name, value } = event.target
    setItem({
      ...item,
      [name]: value
    })
    setIsDirty(true)
  }

  function handleConfirm(_event: any) {
    const updatedTerms = updateTermsOption({
      options,
      termsOption: { ...item, content: contentRef.current?.getHTML() }
    })
    const confirmIsDirty = isDirty || contentRef.current?.isDirty()
    const updatedOptions = {
      ...updatedTerms,
      options: {
        ...updatedTerms.options,
        onboardingTasks: {
          ...updatedTerms.options.onboardingTasks,
          termsUpdated: confirmIsDirty ? true : updatedTerms.options.onboardingTasks?.termsUpdated
        }
      }
    }
    onConfirm(updatedOptions, confirmIsDirty)
  }

  function handleQuoteTypeChange(values: string[]) {
    setItem({
      ...item,
      quoteTypes: values
    })
    setIsDirty(true)
  }
  function handleToggle(_event: React.ChangeEvent<HTMLElement>, _checked: boolean) {
    setItem({
      ...item,
      active: !item.active
    })
    setIsDirty(true)
  }
}
