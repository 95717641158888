import {
  LearnMoreLink,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  EditableDialogTitle,
  Grid,
  InputField,
  DropdownSelect
} from '@ui/paintscout'
import { getFeatures, getObjectLabels, updateProductOption, type ProductOption } from '@paintscout/util/builder'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { DialogProps, DropdownSelectOption } from '@ui/paintscout'
import type { OptionsDocument } from 'paintscout'
import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import * as Sentry from '@sentry/core'
import get from 'lodash/get'
import find from 'lodash/find'
import QuoteTypeSelect from '../../QuoteTypeSelect'

const useStyles = makeStyles<Theme, EditProductOptionDialogProps>(
  (theme) => ({
    root: {},
    dialogTitle: {},
    dialogContent: {
      overflow: 'visible'
    },
    cancel: {},
    confirm: {},
    overflowVisible: {
      overflow: 'visible'
    },
    customUnit: {
      padding: theme.spacing(2)
    }
  }),
  { name: 'EditProductOptionDialog' }
)

export interface EditProductOptionDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  item: ProductOption
  options: OptionsDocument
  isNew?: boolean
  onConfirm: (options: OptionsDocument, isDirty?: boolean) => void
  onCancel: () => void
}

function EditProductOptionDialog(props: EditProductOptionDialogProps) {
  const classes = useStyles(props)
  const { onConfirm, onCancel, options, item, isNew, ...baseDialogProps } = props
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [isDirty, setisDirty] = useState(false)
  const [productOption, setProductOption] = useState<ProductOption>(item)
  const objectLabels = getObjectLabels({ options })
  const features = getFeatures({ options })
  const materialCostFeature = get(features, 'quotes.materialCost.enabled', false)
  let showSqftCoverage = false
  if (productOption.rateTypes) {
    if (
      productOption.rateTypes.indexOf('sqftWalls') !== -1 ||
      productOption.rateTypes.indexOf('sqftCeiling') !== -1 ||
      productOption.rateTypes.indexOf('sqftFloor') !== -1 ||
      productOption.rateTypes.indexOf('all') !== -1
    ) {
      showSqftCoverage = true
    }
  }

  console.log({ rateTypes: productOption.rateTypes })
  const showLnftCoverage =
    productOption.rateTypes.indexOf('lnft') !== -1 || productOption.rateTypes.indexOf('all') !== -1
  const showQuantityCoverage =
    productOption.rateTypes.indexOf('quantity') !== -1 || productOption.rateTypes.indexOf('all') !== -1

  const productUnitOptions = [
    { label: 'gal', value: 'gal' },
    { label: 'item', value: 'item' },
    { label: 'Custom...', value: '__custom__' }
  ]
  let selectedProductUnitOption = null

  selectedProductUnitOption = find(productUnitOptions, { value: productOption.unitLabel })
  if (!selectedProductUnitOption && typeof productOption.unitLabel === 'undefined') {
    selectedProductUnitOption = productUnitOptions[0]
  }

  const unitLabel = selectedProductUnitOption?.label ?? productOption.unitLabel

  const leftButton = (
    <Button className={classes.cancel} disabled={isLoading} variant={'text'} onClick={onCancel}>
      {'Cancel'}
    </Button>
  )

  const handleToggle = () => {
    setProductOption({ ...productOption, active: !productOption.active })
    setisDirty(true)
  }

  const handleQuoteTypeChange = (values: string[]) => {
    setProductOption({ ...productOption, quoteTypes: values })
    setisDirty(true)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setProductOption({ ...productOption, [name]: value })
    setisDirty(true)
  }

  const handleUnitLabelChange = (option: DropdownSelectOption) => {
    setProductOption({ ...productOption, unitLabel: option.value === '__custom__' ? '' : option.value })
    setisDirty(true)
  }

  const handleUnitLabelInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target
    setProductOption({ ...productOption, unitLabel: value })
    setisDirty(true)
  }

  const handleConfirm = () => {
    setIsLoading(true)
    try {
      const updatedOptions = updateProductOption({ options, productOption })
      onConfirm(updatedOptions, isDirty)
    } catch (error) {
      Sentry.captureException(error)
      enqueueSnackbar('error', { variant: 'error' })
    }
    setIsLoading(false)
  }

  return (
    <Dialog classes={{ root: classes.root, paper: classes.overflowVisible }} fullWidth {...baseDialogProps}>
      <EditableDialogTitle
        title={`${isNew ? 'Add' : 'Edit'} Product`}
        loading={isLoading}
        onToggle={handleToggle}
        toggleValue={typeof productOption.active !== 'undefined' ? !!productOption.active : true}
        toggleLabel="Active"
      />
      <DialogContent classes={{ root: classes.dialogContent }}>
        <form
          id="edit-product-option-dialog-form"
          onSubmit={(e) => {
            e.preventDefault()
            handleConfirm()
          }}
        >
          <Grid container={true} spacing={3} justifyContent="flex-start" alignItems="flex-start">
            <Grid item xs={12} sm={12} md={6}>
              <InputField
                classes={{ root: classes.input }}
                label={'Label or Name'}
                name={'label'}
                value={productOption.label}
                fullWidth
                onChange={handleChange}
                required
                error={!productOption.label}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <QuoteTypeSelect
                label={`${objectLabels.quote.value} Types`}
                variant="multi"
                onChange={handleQuoteTypeChange}
                value={productOption.quoteTypes}
                popperModifiers={{
                  flip: {
                    enabled: false
                  },
                  preventOverflow: {
                    enabled: false
                  }
                }}
              />
            </Grid>
            {showSqftCoverage && (
              <Grid item xs={12} sm={12} md={4}>
                <InputField
                  classes={{ root: classes.input }}
                  label={`Coverage (${objectLabels.unit.abbreviation.square}/${unitLabel})`}
                  name={'coverage_rate'}
                  value={productOption.coverage_rate}
                  format={'rate'}
                  fullWidth
                  onChange={handleChange}
                  toolTip={`The number of square ${objectLabels.unit.plural.toLowerCase()} that the product can cover with one coat.`}
                />
              </Grid>
            )}
            {showLnftCoverage && (
              <Grid item xs={12} sm={12} md={4}>
                <InputField
                  classes={{ root: classes.input }}
                  label={`Coverage (${objectLabels.unit.abbreviation.linear}/${unitLabel})`}
                  name={'lnft_coverage'}
                  value={productOption.lnft_coverage}
                  format={'rate'}
                  fullWidth
                  onChange={handleChange}
                  toolTip={`The number of linear ${objectLabels.unit.plural.toLowerCase()} that the product can cover with one coat.`}
                />
              </Grid>
            )}
            {showQuantityCoverage && (
              <Grid item xs={12} sm={12} md={4}>
                <InputField
                  classes={{ root: classes.input }}
                  label={`Coverage (${unitLabel}/item)`}
                  name={'quantity_coverage'}
                  value={productOption.quantity_coverage}
                  format={'rate'}
                  fullWidth
                  onChange={handleChange}
                  toolTip={
                    <Grid container>
                      <Grid item>
                        {`How much of the product is being used to cover one item with one coat. (i.e. you use 0.05 of a gallon to cover one door with one coat).`}
                      </Grid>
                      <Grid item>
                        <LearnMoreLink link="http://help.paintscout.com/en/articles/6063072-coverage-rates#h_87aa5a7f20" />
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            )}
            {materialCostFeature && (
              <>
                <Grid item xs={12} sm={12} md={4}>
                  <InputField
                    classes={{ root: classes.input }}
                    label={`Price (${objectLabels.currency.symbol}/${unitLabel})`}
                    name={'price'}
                    format={'rate'}
                    value={productOption.price}
                    fullWidth
                    onChange={handleChange}
                    toolTip={`The price you want to charge your customer for the product.`}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <DropdownSelect
                    variant="single"
                    label={'Unit'}
                    options={productUnitOptions}
                    value={selectedProductUnitOption || { label: unitLabel, value: '__custom__' }}
                    onChange={(selectedOption) => handleUnitLabelChange(selectedOption ?? productUnitOptions[0])}
                    fullWidth
                    toolTip={'Specify how the product is measured. Select custom to add a different type of unit.'}
                    checkInvalid={() => (unitLabel.length > 0 ? '' : 'Unit label is required')}
                    pagination={{
                      currentPage: !selectedProductUnitOption ? 1 : 0,
                      pages: [
                        <Grid container className={classes.customUnit} key="custom-unit">
                          <InputField
                            autoFocus
                            classes={{ root: classes.input }}
                            label={'Custom Unit'}
                            fullWidth
                            value={unitLabel}
                            onChange={handleUnitLabelInputChange}
                            required
                          />
                        </Grid>
                      ],
                      onPageChange: (_) => {
                        // selectedProductUnitOption resets when we change pages, so we don't need to do anything here
                        // if we don't pass a function the dropdown will just close
                        return
                      }
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions leftButton={leftButton}>
        <Button
          className={classes.confirm}
          type="submit"
          form="edit-product-option-dialog-form"
          variant={'contained'}
          disabled={isLoading}
          data-testid="edit-product-option-dialog-confirm-button"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditProductOptionDialog
