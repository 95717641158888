import React, { Suspense } from 'react'
import type { WithStyles } from '@material-ui/core'
import { withStyles, createStyles, useTheme } from '@material-ui/core'

import Typography from '../Typography'

import WebfontLoader from '@dr-kobros/react-webfont-loader'
// const WebfontLoader = React.lazy(() => import('@dr-kobros/react-webfont-loader'))

import { getFontOptions } from './getFontOptions'

import values from 'lodash/values'
import sortBy from 'lodash/sortBy'
import type { DropdownSelectProps } from '../DropdownSelect'
import DropdownSelect from '../DropdownSelect'

const styles = createStyles({
  root: {}
})

export interface FontSelectProps
  extends WithStyles<typeof styles>,
    Omit<DropdownSelectProps, 'onChange' | 'classes' | 'value' | 'variant'> {
  value?: string
  onChange?: (value: string) => void
}

export interface FontOption {
  label: string
  value: string
  style: any
}

const options = getFontOptions()

const sortedOptions = sortBy(values(options), 'label')

const config = {
  google: {
    families: sortedOptions.map((option) => option.value)
  }
}

function FontSelect({ classes, value, onChange, ...baseSelectProps }: FontSelectProps) {
  const theme = useTheme()
  return (
    <Suspense fallback={null}>
      <WebfontLoader config={config}>
        <div className={classes.root}>
          <DropdownSelect
            {...baseSelectProps}
            variant="single"
            options={sortedOptions}
            value={sortedOptions.find((option) => option.value === value)}
            onChange={(option) => onChange(option.value)}
            formatOptionLabel={(option) => (
              <Typography style={{ ...option.style, color: theme.palette.text.primary }}>{option.label}</Typography>
            )}
          />
        </div>
      </WebfontLoader>
    </Suspense>
  )
}

export default withStyles(styles)(FontSelect)
