import {
  LearnMoreLink,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  InputField,
  DropdownSelect,
  useClientOptions,
  DialogTitle
} from '@ui/paintscout'
import { type Product, getFeatures, getObjectLabels } from '@paintscout/util/builder'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import type { DialogProps, DropdownSelectOption } from '@ui/paintscout'
import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import * as Sentry from '@sentry/core'
import get from 'lodash/get'
import find from 'lodash/find'

const useStyles = makeStyles<Theme, EditProductDialogProps>(
  (theme) => ({
    root: {},
    dialogTitle: {},
    dialogContent: {
      overflow: 'visible'
    },
    cancel: {},
    confirm: {},
    overflowVisible: {
      overflow: 'visible'
    },
    customUnit: {
      padding: theme.spacing(2)
    }
  }),
  { name: 'EditProductDialog' }
)

export interface EditProductDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  item: Product
  isNew?: boolean
  isCustom?: boolean
  onConfirm: (product: Product, isDirty: boolean) => void
  onCancel: () => void
}

function EditProductDialog(props: EditProductDialogProps) {
  const classes = useStyles(props)
  const { onConfirm, onCancel, item, isNew, ...baseDialogProps } = props
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [isDirty, setisDirty] = useState(false)
  const [product, setProduct] = useState<Product>(item)
  const { options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const features = getFeatures({ options })
  const materialCostFeature = get(features, 'quotes.materialCost.enabled', false)
  let showSqftCoverage = false
  if (product.rateTypes) {
    if (
      product.rateTypes.indexOf('sqftWalls') !== -1 ||
      product.rateTypes.indexOf('sqftCeiling') !== -1 ||
      product.rateTypes.indexOf('sqftFloor') !== -1 ||
      product.rateTypes.indexOf('all') !== -1
    ) {
      showSqftCoverage = true
    }
  }

  const [attemptedConfirm, setAttemptedConfirm] = useState<boolean>(false)

  const showLnftCoverage = product.rateTypes.indexOf('lnft') !== -1 || product.rateTypes.indexOf('all') !== -1
  const showQuantityCoverage = product.rateTypes.indexOf('quantity') !== -1 || product.rateTypes.indexOf('all') !== -1

  const productUnitOptions = [
    { label: 'gal', value: 'gal' },
    { label: 'item', value: 'item' },
    { label: 'Custom...', value: '__custom__' }
  ]
  let selectedProductUnitOption = null

  selectedProductUnitOption = find(productUnitOptions, { value: product.unitLabel })
  if (!selectedProductUnitOption && typeof product.unitLabel === 'undefined') {
    selectedProductUnitOption = productUnitOptions[0]
  }

  const unitLabel = selectedProductUnitOption?.label ?? product.unitLabel

  const leftButton = (
    <Button className={classes.cancel} disabled={isLoading} variant={'text'} onClick={onCancel}>
      {'Cancel'}
    </Button>
  )

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setProduct({ ...product, [name]: value })
    setisDirty(true)
  }

  const handleUnitLabelChange = (option: DropdownSelectOption) => {
    setProduct({ ...product, unitLabel: option.value === '__custom__' ? '' : option.value })
    setisDirty(true)
  }

  const handleUnitLabelInputChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = ev.target
    setProduct({ ...product, unitLabel: value })
    setisDirty(true)
  }

  const handleConfirm = () => {
    if (!product.label.length) {
      setAttemptedConfirm(true)
    } else if (!unitLabel.length) {
      enqueueSnackbar('Unit label is required.', { variant: 'error' })
    } else {
      setIsLoading(true)
      try {
        onConfirm(product, isDirty)
      } catch (error) {
        Sentry.captureException(error)
        enqueueSnackbar('error', { variant: 'error' })
      }
      setIsLoading(false)
    }
  }

  return (
    <Dialog classes={{ root: classes.root, paper: classes.overflowVisible }} fullWidth {...baseDialogProps}>
      <DialogTitle>{`${isNew ? 'Add' : 'Edit'} Product`}</DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Grid container={true} spacing={3} justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} sm={12} md={6}>
            <InputField
              showErrorBeforeValidation={!product.label.length && attemptedConfirm}
              classes={{ root: classes.input }}
              label={'Label or Name'}
              name={'label'}
              value={product.label}
              fullWidth
              onChange={handleChange}
              required
              error={!product.label.length}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputField
              classes={{ root: classes.input }}
              fullWidth
              label={`Color`}
              name={'color'}
              value={product.color}
              onChange={handleChange}
            />
          </Grid>
          {showSqftCoverage && (
            <Grid item xs={12} sm={12} md={4}>
              <InputField
                classes={{ root: classes.input }}
                label={`Coverage (${objectLabels.unit.abbreviation.square}/${unitLabel})`}
                name={'coverage'}
                value={product.coverage}
                format={'rate'}
                fullWidth
                onChange={handleChange}
                toolTip={`The number of square ${objectLabels.unit.plural.toLowerCase()} that the product can cover with one coat.`}
              />
            </Grid>
          )}
          {showLnftCoverage && (
            <Grid item xs={12} sm={12} md={4}>
              <InputField
                classes={{ root: classes.input }}
                label={`Coverage (${objectLabels.unit.abbreviation.linear}/${unitLabel})`}
                name={'lnftCoverage'}
                value={product.lnftCoverage}
                format={'rate'}
                fullWidth
                onChange={handleChange}
                toolTip={`The number of linear ${objectLabels.unit.plural.toLowerCase()} that the product can cover with one coat.`}
              />
            </Grid>
          )}
          {showQuantityCoverage && (
            <Grid item xs={12} sm={12} md={4}>
              <InputField
                classes={{ root: classes.input }}
                label={`Coverage (${unitLabel}/item)`}
                name={'quantityCoverage'}
                value={product.quantityCoverage}
                format={'rate'}
                fullWidth
                onChange={handleChange}
                toolTip={
                  <Grid container>
                    <Grid item>
                      {`How much of the product is being used to cover one item with one coat. (i.e. you use 0.05 of a gallon to cover one door with one coat).`}
                    </Grid>
                    <Grid item>
                      <LearnMoreLink link="http://help.paintscout.com/en/articles/6063072-coverage-rates#h_87aa5a7f20" />
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
          )}
          {materialCostFeature && (
            <>
              <Grid item xs={12} sm={12} md={4}>
                <InputField
                  classes={{ root: classes.input }}
                  label={`Price (${objectLabels.currency.symbol}/${unitLabel})`}
                  name={'price'}
                  format={'price'}
                  value={product.price}
                  fullWidth
                  onChange={handleChange}
                  toolTip={`The price you want to charge your customer for the product.`}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <DropdownSelect
                  variant="single"
                  label={'Unit'}
                  options={productUnitOptions}
                  value={selectedProductUnitOption || { label: unitLabel, value: '__custom__' }}
                  onChange={(selectedOption) => handleUnitLabelChange(selectedOption ?? productUnitOptions[0])}
                  fullWidth
                  toolTip={'Specify how the product is measured. Select custom to add a different type of unit.'}
                  checkInvalid={() => (unitLabel.length > 0 ? '' : 'Unit label is required')}
                  pagination={{
                    currentPage: !selectedProductUnitOption ? 1 : 0,
                    pages: [
                      <Grid container className={classes.customUnit} key="custom-unit">
                        <InputField
                          autoFocus
                          classes={{ root: classes.input }}
                          label={'Custom Unit'}
                          fullWidth
                          value={unitLabel}
                          onChange={handleUnitLabelInputChange}
                          required
                        />
                      </Grid>
                    ],
                    onPageChange: (_) => {
                      // selectedProductUnitOption resets when we change pages, so we don't need to do anything here
                      // if we don't pass a function the dropdown will just close
                      return
                    }
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
      <DialogActions leftButton={leftButton}>
        <Button
          className={classes.confirm}
          onClick={handleConfirm}
          form="edit-product-option-dialog-form"
          variant={'contained'}
          disabled={isLoading}
          data-testid="edit-product-option-dialog-confirm-button"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditProductDialog
