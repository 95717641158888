import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { SwitchProps as CoreSwitchProps } from '@ui/core/Switch'
import { default as CoreSwitch } from '@ui/core/Switch'

const useStyles = makeStyles<Theme, SwitchProps>((theme) => ({
  root: {
    position: 'relative',
    width: 42,
    height: 22,
    padding: 0,
    margin: theme.spacing(),
    '&::before': {
      content: (props) => (props.overlay ? `"${props.overlay}"` : ''),
      position: 'absolute',
      color: (props) => {
        if (props.disabled) {
          return props.checked ? theme.palette.grey[500] : theme.palette.grey[500]
        }

        return props.checked ? theme.palette.common.white : theme.palette.grey[800]
      },
      ...theme.typography.overline,
      top: '50%',
      left: (props) => (props.checked ? '0.6rem' : 'auto'),
      right: (props) => (props.checked ? 'auto' : '0.5rem'),
      transform: 'translateY(-50%)'
    }
  },
  sizeSmall: {
    '& $switchBase': {
      padding: 1
    },
    '&$root': {
      height: 18,
      width: 36
    },
    '&$root::before': {
      fontSize: '0.75em'
    }
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      '&:not($disabled)': {
        color: theme.palette.common.white
      },
      '&:not($disabled) + $track': {
        backgroundColor: '#76B657',
        opacity: 1,
        border: 'none'
      }
    }
  },
  thumb: {
    width: 20,
    height: 20
  },
  track: {
    boxSizing: 'border-box',
    borderRadius: 13,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey.A100,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border'])
  },
  checked: {},
  disabled: {}
}))

export interface SwitchProps extends Omit<CoreSwitchProps, 'inputProps'> {
  overlay?: string
  inputProps?: CoreSwitchProps & { 'data-testid'?: string }
}

const Switch = React.forwardRef<HTMLButtonElement, SwitchProps>(function Switch(props: SwitchProps, ref) {
  const classes = useStyles(props)
  return <CoreSwitch {...(props as any)} disableRipple classes={classes} ref={ref} />
})

export default Switch
