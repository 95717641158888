import { Chip, IconButton, makeStyles } from '@material-ui/core'
import Hidden from '../Hidden'
import React from 'react'
import Typography from '../Typography'
import FilterIcon from '@material-ui/icons/FilterList'

const useStyles = makeStyles((theme) => ({
  chip: {
    borderRadius: theme.borderRadius.md
  },
  icon: {
    color: theme.palette.grey[400]
  }
}))

interface FilterMenuButtonProps {
  onClick: (ev: any) => void
  iconActive?: boolean
  label?: string
}

export default function FilterMenuButton({
  onClick,
  iconActive,
  label = 'Advanced Search & Export'
}: FilterMenuButtonProps) {
  const classes = useStyles()
  return (
    <>
      <Hidden smDown>
        <Chip
          className={classes.chip}
          label={<Typography variant={'body2'}>{label}</Typography>}
          onClick={onClick}
          icon={<FilterIcon color={iconActive ? 'primary' : 'inherit'} />}
        />
      </Hidden>
      <Hidden mdUp>
        <IconButton className={classes.icon} onClick={onClick}>
          <FilterIcon color={iconActive ? 'primary' : 'inherit'} />
        </IconButton>
      </Hidden>
    </>
  )
}
