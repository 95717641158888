import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import type { SvgIconProps } from '@material-ui/core/SvgIcon'
import React from 'react'
import type { StyleClasses } from '../styles'
import { scaleVariant } from '../styles'
import type { ButtonProps as CoreButtonProps } from '@ui/core/Button'
import CoreButton from '@ui/core/Button'

const useStyles = makeStyles<Theme, ButtonProps>((theme) => ({
  root: {
    ...theme.typography.button,
    borderRadius: theme.borderRadius.md,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    whiteSpace: 'nowrap',
    boxShadow: 'none',
    padding: '0.6em 1.0em'
  },
  dialogButtonRoot: {
    background: theme.palette.common.white,

    color: theme.paintscout.palette.primary,

    border: `1px solid ${theme.paintscout.palette.primary}`,

    '&:hover': {
      background: theme.paintscout.palette.primary,

      color: theme.palette.common.white
    }
  },
  underlineRoot: (props) => ({
    backgroundColor: 'transparent !important',
    '&:hover':
      props.variant === 'underline'
        ? {
            color: theme.palette.primary.main
          }
        : {}
  }),
  contained: {
    '&$disabled': {
      background: theme.palette.grey[300],
      transition: 'none'
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main
    }
  },
  disabled: {},
  outlined: {
    background: 'none',
    color: theme.palette.primary.main,
    border: 'dashed',
    borderWidth: '2px',
    // height: theme.typography.pxToRem(50),
    borderColor: theme.palette.grey.A100,
    '&$disabled': {
      border: 'dashed',
      borderWidth: '2px'
    },
    '&:hover': {
      boxShadow: 'none',
      background: 'none',
      border: 'dashed',
      borderWidth: '2px',
      borderColor: 'none'
    }
  },
  text: {
    background: 'none',
    color: theme.palette.grey[700]
  },
  textPrimary: {
    background: 'none',
    color: theme.palette.primary.main
  },
  textSecondary: {
    background: 'none',
    color: theme.palette.secondary.main
  },
  sizeSmall: {
    padding: '0.3em 0.6em',
    ...scaleVariant(theme.typography.button, 0.8)
  },
  sizeLarge: {
    padding: '0.75em 1.5em',
    ...scaleVariant(theme.typography.button, 1.3)
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    // label seems to have a child span with aria-hidden false in it that prevents the children from being perfectly centered without being set to flex
    '& span': {
      display: 'flex'
    }
  },
  underline: (props) => {
    if (props.variant === 'underline') {
      return {
        '& span': {
          borderBottom: '2px solid'
        }
      }
    } else if (props.variant === 'hoverUnderline') {
      return {
        '&:hover': {
          '& span': {
            borderBottom: '2px solid',
            marginBottom: '-2px'
          }
        }
      }
    }
  },
  startIcon: (props) => ({
    '& svg': {
      fontSize: `${props?.iconSize ?? '1.0em'} !important`
    },
    borderBottom: 'none !important',
    marginBottom: '0 !important'
  }),
  endIcon: (props) => ({
    '& svg': {
      fontSize: `${props?.iconSize ?? '1.0em'} !important`
    }
  }),
  roundedWhite: {
    background: theme.paintscout.palette.primary,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: 'none',
    letterSpacing: 0,
    borderRadius: theme.borderRadius.md,
    '& span': {
      fontWeight: theme.typography.fontWeightMedium,
      color: theme.palette.common.white
    },
    '&:hover': {
      background: theme.paintscout.palette.primary,
      boxShadow: theme.shadows[3]
    }
  }
}))

export interface ButtonProps extends Omit<CoreButtonProps, 'variant'> {
  classes?: StyleClasses<typeof useStyles>
  children?: React.ReactNode

  /**
   * Icon to show on the button
   */
  icon?: React.ComponentType<SvgIconProps>

  /**
   * Icon is shown on the left by default. If this is true, icon will show on right.
   */
  flipIconSide?: boolean

  variant?: ButtonVariant | 'underline' | 'hoverUnderline' | 'roundedWhite' | 'dialog'
  iconSize?: string
}
type ButtonVariant = 'contained' | 'outlined' | 'text'

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { icon, flipIconSide, children, ...props },
  ref
) {
  const classes = useStyles(props)

  const underline = props.variant === 'underline' || props.variant === 'hoverUnderline'
  const dialogButton = props.variant === 'dialog'
  const Icon = icon
  return (
    <CoreButton
      startIcon={icon && !flipIconSide ? <Icon /> : null}
      endIcon={icon && flipIconSide ? <Icon /> : null}
      {...props}
      variant={underline ? 'text' : (props.variant as ButtonVariant)}
      disableRipple={underline || props.disableRipple}
      classes={{
        ...classes,
        root: `${classes.root} ${underline ? classes.underlineRoot : ''} ${dialogButton ? classes.dialogButtonRoot : ''}`,
        label: `${classes.label} ${underline ? classes.underline : ''}`
      }}
      ref={ref}
    >
      {children}
    </CoreButton>
  )
})

Button.defaultProps = {
  variant: 'contained',
  flipIconSide: false
}

export default Button
