import React, { useState, useRef, forwardRef } from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'

import Paper from '@material-ui/core/Paper'

import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import IconButton from '../IconButton'
import Button from '../Button'

import BackspaceIcon from '@material-ui/icons/Backspace'

import Tile from '../Tile'
import InputField from '../InputField'
import Typography from '../Typography'
import { parseNumber } from '@paintscout/util/calculator'

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      padding: theme.spacing(1),
      zIndex: theme.zIndex.modal + 1,
      display: 'flex',
      flexDirection: 'column',
      borderRadius: theme.borderRadius.md
    },
    leftSide: {
      width: theme.typography.pxToRem(220)
    },
    rightSide: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      paddingLeft: theme.typography.pxToRem(5)
    },
    inputWrapper: {
      width: theme.typography.pxToRem(220),
      display: 'flex',
      marginBottom: theme.typography.pxToRem(5),
      alignItems: 'flex-start'
    },
    input: {
      marginRight: theme.typography.pxToRem(5)
    },
    hiddenInput: {
      display: 'none'
    },
    initialValue: {
      ...theme.typography.body2,
      minHeight: theme.typography.pxToRem(18),
      marginTop: theme.spacing(0.5),
      color: theme.palette.grey[700]
    },
    bottom: {
      display: 'flex',
      flexDirection: 'row'
    },
    keys: {
      display: 'grid',
      gridTemplateColumns: '0.333fr 0.333fr 0.333fr',
      gridGap: theme.typography.pxToRem(5)
    },
    tile: {
      padding: theme.typography.pxToRem(10),
      width: '100%',
      boxSizing: 'border-box',
      userSelect: 'none'
    },
    close: {
      paddingTop: theme.typography.pxToRem(15),
      display: 'flex'
    },
    inputFocused: {},
    icon: {
      padding: theme.spacing(1, 2)
    },
    adornment: {},
    helperText: {}
  })
}

const keys = ['7', '8', '9', '4', '5', '6', '1', '2', '3', '-', '0', '.']

declare global {
  interface Window {
    HTMLInputElement: any
  }
}

export interface KeypadProps {
  value?: string | number
  name?: string
  open?: boolean
  anchor?: HTMLInputElement
  allowNegative?: boolean
  allowDecimal?: boolean
  allowClear?: boolean
  flipX?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClose?: (value: string) => void
}

export type KeypadPropsWithInjections = KeypadProps & WithStyles<typeof styles>

const Keypad = forwardRef<React.Ref<HTMLDivElement>, KeypadPropsWithInjections>((props, ref) => {
  const {
    classes,
    allowNegative = false,
    allowDecimal = true,
    allowClear = false,
    onClose,
    onChange,
    open,
    name,
    anchor,
    flipX,
    value: propsValue
  } = props
  const [value, setValue] = useState('')

  const inputRef = useRef(null)

  if (!open) {
    return null
  }

  const parentNode: any = anchor?.parentNode
  const style = flipX
    ? {
        top: parentNode?.offsetTop,
        right: 0
      }
    : {
        top: parentNode?.offsetTop,
        left: parentNode?.offsetLeft
      }

  // TODO: propsValue (for previous value & placeholder needs to be NumberFormatted)
  const placeholder = typeof propsValue === 'number' ? propsValue.toString() : propsValue

  const keypad = (
    <ClickAwayListener onClickAway={() => handleClose()}>
      <Paper className={classes.root} elevation={4} square style={style} ref={ref}>
        <div className={classes.inputWrapper}>
          <div>
            <InputField
              fullWidth
              value={value}
              onChange={handleInputChange}
              classes={{ root: classes.input }}
              placeholder={placeholder}
            />
            <InputField classes={{ root: classes.hiddenInput }} onChange={onChange} inputRef={inputRef} name={name} />

            <Typography className={classes.initialValue}>
              {value !== propsValue && value !== '' && <>Previous Value: {placeholder}</>}
            </Typography>
          </div>
          <IconButton className={classes.icon} onClick={handleBackspaceClick}>
            <BackspaceIcon />
          </IconButton>
        </div>
        <div className={classes.bottom}>
          <div className={classes.leftSide}>
            <div className={classes.keys}>
              {keys.map((key) => {
                if (key === '-' && !allowNegative) {
                  return <div key={key} />
                }
                if (key === '.' && !allowDecimal) {
                  return <div key={key} />
                }

                return (
                  <Tile onClick={() => handleKeyClick(key)} classes={{ root: classes.tile }} key={key}>
                    <label>{key}</label>
                  </Tile>
                )
              })}
            </div>
            <div className={classes.close} style={{ justifyContent: allowClear ? 'space-between' : 'flex-end' }}>
              {allowClear && (
                <Button onClick={() => handleClose(true)} variant={'text'}>
                  Clear
                </Button>
              )}
              <Button onClick={() => handleClose()}>Done</Button>
            </div>
          </div>
        </div>
      </Paper>
    </ClickAwayListener>
  )

  // return createPortal(keypad, document.body)
  return keypad

  function handleKeyClick(key: string) {
    // if there is no current value - this is the start of the number string
    // otherwise, we're going to be appending to the current value
    const currentValue = typeof value === 'undefined' || value === null || value === '0' ? '' : value

    // don't ever allow a second decimal
    if (key === '.' && currentValue.indexOf('.') !== -1) {
      return null
    }

    setValue(currentValue + key)
  }

  function handleBackspaceClick() {
    setValue(value.slice(0, -1))
  }

  function handleClose(clear?: boolean) {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set
    let currentValue = value

    if (clear) {
      // Need to set the value to something other than '' to trigger the input event when we clear
      nativeInputValueSetter.call(inputRef.current, '2')
      const clearEvent = new Event('input', { bubbles: true })
      inputRef.current.dispatchEvent(clearEvent)
      setValue('')
      currentValue = ''
    }

    nativeInputValueSetter.call(inputRef.current, currentValue)
    const inputEvent = new Event('input', { bubbles: true })
    inputRef.current.dispatchEvent(inputEvent)

    if (onClose) {
      onClose(currentValue)
    }
    setValue('')
  }

  function handleInputChange(e) {
    const num = parseNumber(e.target.value)
    setValue(num > 0 ? num.toString() : '')
  }
})

Keypad.displayName = 'Keypad'

export default withStyles(styles)(Keypad)
