import React from 'react'

import ErrorIcon from '@material-ui/icons/ErrorOutline'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import SuccessIcon from '@material-ui/icons/CheckCircle'
import DefaultIcon from '@material-ui/icons/Description'

import type { AlertProps } from './Alert'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles<Theme, AlertIconProps>((theme) => ({
  title: {
    marginLeft: theme.spacing(3)
  },
  form: {
    padding: 0
  },
  color: (props: AlertIconProps) => {
    if (props?.color) {
      return {
        color: props?.severity ? theme.palette[props.severity].main : theme.palette.info.main
      }
    }
  }
}))

export interface AlertIconProps {
  severity?: AlertProps['severity']
  color?: boolean
}

function AlertIcon(props: AlertIconProps) {
  const { severity } = props
  const classes = useStyles(props)
  switch (severity) {
    case 'warning':
      return <WarningIcon className={classes.color} />
    case 'error':
      return <ErrorIcon className={classes.color} />
    case 'success':
      return <SuccessIcon className={classes.color} />
    case 'info':
      return <InfoIcon className={classes.color} />
    default:
      return <DefaultIcon className={classes.color} />
  }
}

export default AlertIcon
