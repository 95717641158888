import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import Typography from '../Typography'
import CoreAlert from '@ui/core/Alert'
import type { AlertProps as CoreAlertProps } from '@ui/core/Alert'
import AlertIcon from './AlertIcon'

export interface AlertProps extends Omit<CoreAlertProps, 'Typography'> {
  hideIcon?: boolean
}

function getSeverityColor(severity: AlertProps['severity'], theme: Theme) {
  switch (severity) {
    case 'warning':
      return theme.palette.warning
    case 'error':
      return theme.palette.error
    case 'success':
      return theme.palette.success
    case 'info':
      return theme.palette.info
    default:
      return {
        light: theme.palette.grey[200],
        main: theme.palette.grey[500],
        dark: theme.palette.grey[900],
        contrastText: '#fff'
      }
  }
}

const useStyles = makeStyles(
  (theme: Theme) => {
    return {
      root: ({ severity = 'default', variant = 'default', noMargin = false }: AlertProps) => {
        const severityColor = getSeverityColor(severity, theme)
        let background = 'transparent'
        if (variant === 'default') {
          background = severityColor.light
        } else if (variant === 'filled') {
          background = severityColor.main
        }

        const borderColor = variant === 'default' ? severityColor.light : severityColor.main

        const color = variant === 'filled' ? severityColor.contrastText : severityColor.dark

        return {
          display: 'flex',
          alignItems: 'flex-start',
          borderRadius: theme.borderRadius.md,
          padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor,
          background,
          color,
          position: 'relative',
          marginBottom: noMargin ? 0 : theme.spacing(2)
        }
      },
      contentWrapper: {
        marginTop: 2,
        fontSize: theme.typography.body1.fontSize,
        width: '100%'
      },
      iconWrapper: ({ severity = 'default', variant = 'default' }: AlertProps) => {
        const severityColor = getSeverityColor(severity, theme)
        return {
          display: 'flex',
          alignItems: 'center',
          color: variant === 'filled' ? '#fff' : severityColor.main,
          marginTop: 4,
          '& svg': {
            width: 20,
            height: 20
          }
        }
      },
      closeWrapper: {
        position: 'absolute',
        top: 6,
        right: 0
      },
      title: {
        marginBottom: theme.spacing(0.5),
        color: 'inherit'
      },
      content: {}
    }
  },
  {
    name: 'Alert'
  }
)

export default function Alert({ severity = 'default', variant = 'default', hideIcon, ...other }: AlertProps) {
  const classes = useStyles({ severity, variant, ...other })
  const Icon = hideIcon ? null : <AlertIcon severity={severity} />
  return <CoreAlert Typography={Typography} icon={Icon} classes={classes} {...other} />
}
