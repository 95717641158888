import type { Theme } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core'
import type { FollowUp } from '@paintscout/api'
import {
  getFeatures,
  getObjectLabels,
  getQuoteTypeOptions,
  reformatSpecialChars,
  replaceBlockTags,
  uuid
} from '@paintscout/util/builder'
import { parseNumber } from '@paintscout/util/calculator'
import { getTemplateContext, getTemplateTags, isHtml } from '@paintscout/util/util'
import classNames from 'classnames'
import { FastField, Field, Form, Formik } from 'formik'
import get from 'lodash/get'
import { useSnackbar } from 'notistack'
import React from 'react'
import striptags from 'striptags'
import * as Yup from 'yup'
import { useClientOptions } from '../ClientOptionsProvider'
import Collapse from '../Collapse'
import type { DialogProps } from '../Dialog/Dialog'
import Dialog from '../Dialog/Dialog'
import DialogActions, { DialogActionButton } from '../Dialog/DialogActions'
import DialogContent from '../Dialog/DialogContent'
import DialogTitle from '../Dialog/DialogTitle'
import DropdownSelect from '../DropdownSelect'
import FormControlLabel from '../FormControlLabel'
import Grid from '../Grid'
import Switch from '../Switch'
import Tooltip from '../Tooltip'
import Typography from '../Typography'
import { useUser } from '../UserProvider'
import { FormikCheckbox, FormikHtmlEditor, FormikInputField } from '../formik'

const useStyles = makeStyles<Theme, FollowUpDialogProps>((theme) => ({
  titleRoot: {
    padding: theme.spacing(2, 1, 1, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 3),
      margin: 0
    }
  },
  contentRoot: {
    padding: theme.spacing(3, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2, 3)
    }
  },
  checkbox: {
    marginRight: 0
  },
  customIntervalContainer: {
    paddingTop: theme.spacing(1),
    // months field
    '& > div:first-child': {
      paddingRight: theme.spacing(0.5)
    },
    // days field
    '& > div:last-child': {
      paddingLeft: theme.spacing(0.5)
    }
  },
  switch: {
    marginLeft: 0,
    marginRight: theme.spacing(3)
  },
  switchRoot: {
    marginLeft: theme.spacing(4)
  },
  selectWithSublabel: {
    lineHeight: theme.typography.pxToRem(16)
  },
  subMessage: {
    color: theme.palette.grey[500]
  }
}))

const useStepStyles = makeStyles<Theme, FollowUpStepProps>((theme) => ({
  stepRoot: ({ step, totalSteps }) => ({
    marginBottom: theme.spacing(3),
    borderBottom: step === totalSteps ? 'none' : `1px solid ${theme.palette.divider}`,
    paddingBottom: theme.spacing(3)
  }),
  enabledStepRoot: {
    opacity: 1,
    pointerEvents: 'auto'
  },
  disabledStepRoot: {
    opacity: 0.6,
    pointerEvents: 'none'
  },
  childContainer: {
    padding: theme.spacing(2, 0, 4, 0),
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  subtitle: {
    color: theme.palette.grey[500],
    fontSize: '0.95rem'
  }
}))

export interface FollowUpDialogProps extends DialogProps {
  followUp?: FollowUp
  onConfirm: (followUp: FollowUp) => any
  onCancel: () => any
}

const validationSchema = Yup.object({
  subject: Yup.string().min(10).required(),
  message: Yup.string().required().min(50).required(),
  smsMessage: Yup.string()
    .when('sendText', {
      is: true,
      then: Yup.string()
        .test('len', 'SMS message cannot be more than 95 characters.', (val) => {
          const cleanVal = replaceBlockTags(val).toString()
          return cleanVal.length <= 95
        })
        .required(),
      otherwise: Yup.string().nullable().default(null)
    })
    .when('sendText', {
      is: true,
      then: Yup.string()
        .test('len', 'Must provide SMS message', (val) => {
          const cleanVal = replaceBlockTags(val).toString()
          return cleanVal.length > 0
        })
        .required(),
      otherwise: Yup.string().nullable().default(null)
    }),

  conditions: Yup.object({
    fromStatus: Yup.string().required(),
    notStatus: Yup.string().when('fromStatus', {
      is: (fromStatus) => fromStatus !== 'overdue',
      then: Yup.string().required(),
      otherwise: Yup.string().nullable().default(null)
    })
  }),
  isInvoice: Yup.boolean().required(),
  active: Yup.boolean(),
  quoteTypes: Yup.array(Yup.string()).when('isInvoice', {
    is: false,
    then: Yup.array(Yup.string()).required()
  })
})

export default function FollowUpDialog(props: FollowUpDialogProps) {
  const { followUp, onConfirm, onCancel } = props
  const classes = useStyles(props)
  const theme = useTheme()
  const isNew = !followUp
  const { enqueueSnackbar } = useSnackbar()
  const { options } = useClientOptions()
  const { preferences } = useUser()

  const editorPreference = preferences?.editor ?? 'always'

  const features = getFeatures({ options, preferences })

  const showSendText = get(features, 'quotes.followUps.sendText', false)

  const intervalOptions = [
    {
      value: 1,
      label: '1 Day'
    },
    { value: 2, label: '2 Days' },
    { value: 7, label: '7 Days' },
    { value: 14, label: '14 Days' },
    { value: 30, label: '30 Days' },
    { value: 'custom', label: 'Custom' }
  ]
  const defaultIntervals = intervalOptions.map((option) => option.value)

  return (
    <Dialog {...props}>
      <Formik
        initialValues={{
          key: uuid(),
          created: Date.now(),
          toggled: Date.now(),
          isInvoice: null,
          active: true,
          sendText: isNew ? false : !!options.options.quotes.sendFollowUpTextDefault,
          sendEmail: false,
          quoteTypes: [],
          retroactive: false,
          sendFollowUpNotice: false,
          ...(followUp ?? {}),

          conditions: {
            fromStatus: null,
            notStatus: null,
            interval: null,
            months: 0,
            ...(followUp?.conditions ?? {}),
            // convert older followUps that used intervals into days (they were always < 30 days, so no need to worry about months)
            days:
              followUp?.conditions?.days ?? Math.floor((followUp?.conditions?.interval ?? 0) / (1000 * 60 * 60 * 24))
          },
          subject: isHtml(followUp?.subject) ? followUp.subject : `<p>${followUp?.subject ?? ''}</p>`,
          message: isHtml(followUp?.message) ? followUp.message : `<p>${followUp?.message ?? ''}</p>`,
          smsMessage: isHtml(followUp?.smsMessage) ? followUp.smsMessage : `<p>${followUp?.smsMessage ?? ''}</p>`
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          values.message = reformatSpecialChars(values.message)
          values.subject = reformatSpecialChars(values.subject)

          if (values.message.length < 50 && values.subject.length < 10) {
            enqueueSnackbar(
              'To prevent a follow-up from being marked as spam, please ensure that the subject is at least 10 characters and the message is at least 50 characters.',
              { variant: 'error' }
            )
          } else if (values.message.length < 50) {
            enqueueSnackbar(
              'To prevent a follow-up from being marked as spam, please ensure that the message is at least 50 characters.',
              { variant: 'error' }
            )
          } else if (values.subject.length < 10) {
            enqueueSnackbar(
              'To prevent a follow-up from being marked as spam, please ensure that the subject is at least 10 characters.',
              { variant: 'error' }
            )
          } else {
            values.active = values.sendText || values.sendEmail
            onConfirm({ ...values })
          }
        }}
      >
        {({ values, setFieldValue, isValid, errors }) => {
          const objectLabels = getObjectLabels({ options, invoice: values.isInvoice })
          const quoteTypeOptions = [...getQuoteTypeOptions({ options, hideAll: true })]

          const fromStatusTypeOptions: Array<{ key: string; label: string }> = [
            { key: 'sent', label: 'Sent' },
            { key: 'viewed', label: 'Viewed' },
            values.isInvoice && { key: 'overdue', label: 'Overdue' },
            values.isInvoice && { key: 'partial', label: 'Partial' }
          ].filter(Boolean)

          const selectedFromStatus = fromStatusTypeOptions.find((option) => option.key === values.conditions.fromStatus)
          const isInvoiceFromPartial = values.conditions.fromStatus === 'partial' && values.isInvoice

          const toStatusTypeOptions: Array<{ key: string; label: string; sublabel?: string }> = [
            values.conditions.fromStatus !== 'viewed' && !isInvoiceFromPartial && { key: 'viewed', label: 'Viewed' },
            values.conditions.fromStatus !== 'partial' && values.isInvoice && { key: 'partial', label: 'Partial' },
            values.isInvoice && { key: 'paid', label: 'Paid' },
            !isInvoiceFromPartial && {
              key: 'responded',
              label: 'Responded',
              sublabel: values.isInvoice ? 'Paid or Partial' : 'Accepted, Declined or On-Hold'
            }
          ].filter(Boolean)

          const selectedToStatus = toStatusTypeOptions.find((option) => option.key === values.conditions.notStatus)

          const templateContext = getTemplateContext(editorPreference)
          const templateTags = getTemplateTags({ options, invoice: values.isInvoice })

          const isIntervalChosen = !!(values.conditions.months || values.conditions.days)
          const isCustomInterval =
            values.conditions.interval === 0 ||
            values.conditions.months != 0 ||
            !defaultIntervals.includes(values.conditions.days)
          const charCount = striptags(values.message || '').length
          const smsCharCount = striptags(values.smsMessage || '').length

          const documentOptions = [
            { value: false, label: getObjectLabels({ options }).quote.value },
            { value: true, label: getObjectLabels({ options, invoice: true }).quote.value }
          ]

          const quoteTypesDisabled = values.isInvoice === null

          const conditionsDisabled = !values.isInvoice && !values.quoteTypes?.length

          const missingInterval =
            (isCustomInterval && !isIntervalChosen) || (!isCustomInterval && !values.conditions.days)
          const invalidStatuses =
            !values.conditions.fromStatus ||
            (values.conditions.fromStatus !== 'overdue' && !values.conditions.notStatus)

          const messageDisabled = conditionsDisabled || missingInterval || invalidStatuses

          const handleError = () => {
            if (values.isInvoice === null) {
              enqueueSnackbar('Please select a document type.', { variant: 'error' })
              return
            }
            if (!values.isInvoice && !values.quoteTypes?.length) {
              enqueueSnackbar(
                `Please select at least one ${getObjectLabels({ options }).quote.value.toLowerCase()} type.`,
                {
                  variant: 'error'
                }
              )
              return
            }
            if (
              !values.conditions.fromStatus ||
              (values.conditions.fromStatus !== 'overdue' && !values.conditions.notStatus) ||
              !(values.conditions.days || values.conditions.months)
            ) {
              enqueueSnackbar('Please ensure your conditions are valid.', { variant: 'error' })
              return
            }
            if (values.subject.length < 10 || values.message.length < 50) {
              enqueueSnackbar(
                'To prevent a follow-up from being marked as spam, please ensure that the subject is at least 10 characters and the message is at least 50 characters.',
                { variant: 'error' }
              )
              return
            }
          }

          return (
            <>
              <DialogTitle classes={{ root: classes.titleRoot }}>
                <span>{isNew ? 'Create New Follow-Up' : 'Edit Follow-Up'}</span>
              </DialogTitle>
              <DialogContent className={classes.contentRoot}>
                <Form id="content-form">
                  <FollowUpStep step={1} totalSteps={values.isInvoice ? 3 : 4} subtitle="Select your document type.">
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        <DropdownSelect
                          required
                          error={values.isInvoice === null}
                          variant="single"
                          value={documentOptions.find((option) => option.value === values.isInvoice)}
                          onChange={(option) => {
                            setFieldValue('isInvoice', option.value)
                            setFieldValue('quoteTypes', [])
                          }}
                          options={documentOptions}
                        />
                      </Grid>
                    </Grid>
                  </FollowUpStep>
                  <Collapse show={!values.isInvoice}>
                    <FollowUpStep
                      disabled={quoteTypesDisabled}
                      step={2}
                      totalSteps={values.isInvoice ? 3 : 4}
                      subtitle={`Select which ${objectLabels.quote.value.toLowerCase()} types this follow-up will send for.`}
                    >
                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          <DropdownSelect
                            fullWidth
                            variant="multi"
                            required
                            error={!values.quoteTypes?.length}
                            value={values.quoteTypes
                              .map((type) => {
                                const validQuoteType = quoteTypeOptions.find((option) => option.key === type)
                                if (validQuoteType) {
                                  return {
                                    value: type,
                                    label: validQuoteType.label
                                  }
                                } else {
                                  return null
                                }
                              })
                              .filter(Boolean)}
                            onChange={(selected) => {
                              setFieldValue(
                                'quoteTypes',
                                selected.map((option) => option.value)
                              )
                            }}
                            options={quoteTypeOptions.map((option) => ({
                              value: option.key,
                              label: option.label,
                              disabled: values.quoteTypes?.includes('__all__') && option.key !== '__all__'
                            }))}
                          />
                        </Grid>
                      </Grid>
                    </FollowUpStep>
                  </Collapse>
                  <FollowUpStep
                    disabled={conditionsDisabled}
                    step={values.isInvoice ? 2 : 3}
                    totalSteps={values.isInvoice ? 3 : 4}
                    subtitle="Set your follow-up requirements."
                  >
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                      <Grid item xs={12} sm={6}>
                        <Typography>Send follow-up if {objectLabels.quote.value.toLowerCase()} has been...</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DropdownSelect
                          fullWidth
                          variant="single"
                          required
                          error={!values.conditions.fromStatus}
                          value={
                            selectedFromStatus
                              ? {
                                  value: selectedFromStatus.key,
                                  label: selectedFromStatus.label
                                }
                              : null
                          }
                          onChange={(option) => {
                            setFieldValue('conditions.fromStatus', option.value)
                            setFieldValue('conditions.notStatus', null)
                          }}
                          options={fromStatusTypeOptions.map((option) => ({
                            value: option.key,
                            label: option.label
                          }))}
                          formatOptionLabel={(option) => (
                            <Typography
                              style={{
                                color: theme.palette.quote.status[option.label.toLowerCase()]
                              }}
                            >
                              {option.label}
                            </Typography>
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Collapse show={values.conditions.fromStatus !== 'overdue'}>
                          <Typography>But {objectLabels.quote.value.toLowerCase()} has not been...</Typography>
                        </Collapse>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Collapse show={values.conditions.fromStatus !== 'overdue'}>
                          <DropdownSelect
                            fullWidth
                            variant="single"
                            required
                            error={!values.conditions.notStatus}
                            value={
                              selectedToStatus
                                ? {
                                    value: selectedToStatus.key,
                                    label: selectedToStatus.label
                                  }
                                : null
                            }
                            onChange={(option) => setFieldValue('conditions.notStatus', option.value)}
                            options={toStatusTypeOptions.map((option) => ({
                              value: option.key,
                              label: option.label
                            }))}
                            formatOptionLabel={(option) => {
                              const optionWithSublabel = toStatusTypeOptions.find((o) => o.key === option.value)
                              if (!optionWithSublabel) {
                                return null
                              }
                              const color =
                                theme.palette.quote.status[
                                  optionWithSublabel.key === 'responded' ? 'sent' : optionWithSublabel.key
                                ]
                              return (
                                <Typography style={{ color }} className={classes.selectWithSublabel}>
                                  {optionWithSublabel.label}
                                  <br />
                                  {optionWithSublabel.sublabel && (
                                    <Typography variant="caption" color="textSecondary">
                                      {optionWithSublabel.sublabel}
                                    </Typography>
                                  )}
                                </Typography>
                              )
                            }}
                          />
                        </Collapse>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography>Send after...</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DropdownSelect
                          variant="single"
                          fullWidth
                          required
                          error={!values.conditions.days && !isCustomInterval}
                          value={
                            isCustomInterval
                              ? { value: 'custom', label: 'Custom' }
                              : intervalOptions.find((option) => option.value === (values.conditions.days ?? 0))
                          }
                          onChange={(selected) => {
                            if (selected.value === 'custom') {
                              setFieldValue('conditions.interval', 0)
                              setFieldValue('conditions.months', null)
                              setFieldValue('conditions.days', null)
                            } else {
                              setFieldValue('conditions.interval', null)
                              setFieldValue('conditions.months', 0)
                              setFieldValue('conditions.days', selected.value)
                            }
                          }}
                          options={intervalOptions}
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        xs={12}
                        sm={6}
                        justifyContent="space-between"
                        className={classes.customIntervalContainer}
                      >
                        <Grid item xs={6}>
                          <Collapse show={isCustomInterval}>
                            <Field
                              fullWidth
                              component={FormikInputField}
                              placeholder="Months"
                              name="conditions.months"
                              label="months"
                              inputProps={{ min: 0, max: 12 }}
                              required={isCustomInterval && !values.conditions.days}
                              error={isCustomInterval && !values.conditions.months && !values.conditions.days}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                              onChange={(e) => {
                                const value = Math.min(Math.max(parseNumber(e.target.value), 0), 12)
                                setFieldValue(
                                  'conditions.months',
                                  value == 0 && e.target.value === '0' ? 0 : value || null
                                )
                              }}
                            />
                          </Collapse>
                        </Grid>
                        <Grid item xs={6}>
                          <Collapse show={isCustomInterval}>
                            <Field
                              fullWidth
                              component={FormikInputField}
                              placeholder="Days"
                              name="conditions.days"
                              required={isCustomInterval && !values.conditions.months}
                              error={isCustomInterval && !values.conditions.months && !values.conditions.days}
                              inputProps={{ min: 0, max: 31 }}
                              label="days"
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key)) {
                                  e.preventDefault()
                                }
                              }}
                              onChange={(e) => {
                                const value = Math.min(Math.max(parseNumber(e.target.value), 0), 31)
                                setFieldValue(
                                  'conditions.days',
                                  value == 0 && e.target.value === '0' ? 0 : value || null
                                )
                              }}
                            />
                          </Collapse>
                        </Grid>
                      </Grid>
                    </Grid>
                  </FollowUpStep>
                  <FollowUpStep
                    disabled={messageDisabled}
                    step={values.isInvoice ? 3 : 4}
                    totalSteps={values.isInvoice ? 3 : 4}
                    subtitle={`Write your follow-up message for email, text, or both. A link to the ${(values.isInvoice
                      ? 'Invoice'
                      : objectLabels.quote.value.charAt(0).toUpperCase() + objectLabels.quote.value.slice(1)
                    ).toLowerCase()} will be sent along with your message.`}
                  >
                    <Grid container spacing={2} direction="column" alignItems="stretch">
                      <Grid item>
                        <FormControlLabel
                          classes={{ labelPlacementStart: classes.switch }}
                          control={
                            <Switch
                              disabled={messageDisabled}
                              checked={values.sendEmail}
                              onChange={(e) => setFieldValue('sendEmail', e.target.checked)}
                            />
                          }
                          label={'Email'}
                          labelPlacement="start"
                        />
                      </Grid>
                      {values.sendEmail && (
                        <>
                          <Grid item>
                            <FastField
                              component={FormikHtmlEditor}
                              debounce={true}
                              singleLine={true}
                              name="subject"
                              label="Subject"
                              data-testid="subject"
                              required
                              error={!values.subject.length}
                              fullWidth
                              templateContext={templateContext}
                              toolbar={{
                                bold: false,
                                italic: false,
                                underline: false,
                                color: false,
                                highlight: false,
                                lists: false,
                                link: false,
                                template: {
                                  tags: templateTags
                                }
                              }}
                              shouldUpdate={(props) => {
                                return props.disabled !== messageDisabled
                              }}
                              disabled={messageDisabled}
                            />
                          </Grid>
                          <Grid className={classes.charCountContainer} item>
                            <FastField
                              component={FormikHtmlEditor}
                              debounce={true}
                              name="message"
                              label="Message"
                              data-testid="message"
                              required
                              fullWidth
                              templateContext={templateContext}
                              toolbar={{
                                bold: true,
                                italic: true,
                                underline: true,
                                headings: true,
                                lists: true,
                                link: true,
                                color: false,
                                highlight: false,
                                template: {
                                  tags: templateTags
                                }
                              }}
                              shouldUpdate={(props) => {
                                return props.disabled !== messageDisabled
                              }}
                              disabled={messageDisabled}
                            />
                            <Typography className={classes.charCount} variant="subtitle2">
                              Characters: {charCount}{' '}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Field
                              component={FormikCheckbox}
                              classes={{ root: classes.checkbox }}
                              inputProps={{ 'data-testid': 'retroactive' }}
                              name="retroactive"
                              label={`Send this Follow-Up for existing ${(values.isInvoice
                                ? 'Invoices'
                                : objectLabels.quote.plural
                              ).toLowerCase()}`}
                              required
                              fullWidth
                              multiline
                            />
                          </Grid>
                          <Grid item>
                            <Field
                              component={FormikCheckbox}
                              classes={{ root: classes.checkbox }}
                              inputProps={{ 'data-testid': 'sendFollowUpNotice' }}
                              name="sendFollowUpNotice"
                              label={`Send me a copy when this follow-up is sent.`}
                              required
                              fullWidth
                              multiline
                            />
                          </Grid>
                        </>
                      )}
                      <br />
                      <Grid item style={{ maxWidth: '100%' }}>
                        {showSendText && (
                          <>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'start',
                                marginBottom: '15px'
                              }}
                            >
                              <FormControlLabel
                                classes={{ labelPlacementStart: classes.switch }}
                                control={
                                  <Switch
                                    classes={{ root: classes.switchRoot }}
                                    checked={values.sendText}
                                    onChange={(e) => setFieldValue('sendText', e.target.checked)}
                                    disabled={messageDisabled}
                                  />
                                }
                                label={
                                  <Tooltip content="SMS messages cannot contain more that 95 characters.">
                                    <>SMS Text</>
                                  </Tooltip>
                                }
                                labelPlacement="start"
                              />
                            </div>
                          </>
                        )}
                        {values.sendText && (
                          <>
                            <FastField
                              component={FormikHtmlEditor}
                              debounce={true}
                              name="smsMessage"
                              label="Message"
                              data-testid="smsMessage"
                              required
                              fullWidth
                              templateContext={templateContext}
                              toolbar={{
                                bold: false,
                                italic: false,
                                underline: false,
                                headings: false,
                                lists: false,
                                link: false,
                                color: false,
                                highlight: false,
                                template: {
                                  tags: templateTags
                                }
                              }}
                            />
                            <Typography
                              className={classes.charCount}
                              color={smsCharCount > 95 ? 'error' : 'initial'}
                              variant="subtitle2"
                            >
                              Characters: {smsCharCount}{' '}
                            </Typography>
                            {errors.smsMessage && (
                              <Typography color="error" variant="caption">
                                {errors.smsMessage.charAt(0).toUpperCase() + errors.smsMessage.slice(1)}
                              </Typography>
                            )}
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </FollowUpStep>
                </Form>
              </DialogContent>
              <DialogActions
                leftButton={
                  <DialogActionButton variant="text" onClick={onCancel}>
                    Cancel
                  </DialogActionButton>
                }
              >
                <DialogActionButton form="content-form" type="submit" onClick={!isValid ? handleError : null}>
                  Done
                </DialogActionButton>
              </DialogActions>
            </>
          )
        }}
      </Formik>
    </Dialog>
  )
}

interface FollowUpStepProps {
  step: number
  totalSteps: number
  subtitle: string
  disabled?: boolean
  children?: React.ReactNode
}

export function FollowUpStep(props: FollowUpStepProps) {
  const { step, totalSteps, subtitle } = props
  const classes = useStepStyles(props)
  return (
    <div
      className={classNames({
        [classes.stepRoot]: true,
        [classes.disabledStepRoot]: props.disabled,
        [classes.enabledStepRoot]: !props.disabled
      })}
    >
      <Typography gutterBottom variant="h4">
        Step {step} of {totalSteps}
      </Typography>
      <Typography gutterBottom className={classes.subtitle}>
        {subtitle}
      </Typography>
      <div className={classes.childContainer}>{props.children}</div>
    </div>
  )
}
