import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import type { WithUseStyles } from '../theme'

type DivProps = JSX.IntrinsicElements['div']

export interface ActivityContentProps extends Omit<DivProps, 'title'> {
  classes?: WithUseStyles<typeof useStyles>['classes']
  title?: React.ReactNode
}

const useStyles = makeStyles<Theme, ActivityContentProps>(
  (theme) => ({
    root: {
      paddingLeft: theme.spacing(),
      paddingTop: theme.spacing(),
      paddingBottom: theme.spacing()
    },
    bubble: {
      minWidth: 150,
      padding: theme.spacing(),
      marginRight: theme.spacing(),
      borderRadius: theme.borderRadius.md,
      background: theme.palette.grey[100]
    }
  }),
  {
    name: 'ActivityContent'
  }
)

export default function ActivityContent(props: ActivityContentProps) {
  const classes = useStyles(props)
  const { title, children, ...otherProps } = props

  return (
    <div {...otherProps} className={classnames(classes.root, props.className)}>
      {title && title}
      <div className={classes.bubble}>{children}</div>
    </div>
  )
}
