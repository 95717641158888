import { Card, makeStyles, Typography } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import CopyIcon from '@material-ui/icons/FileCopy'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import type { FollowUp } from '@paintscout/api'
import { getFeatures, getObjectLabels } from '@paintscout/util/builder'
import React, { useState } from 'react'
import { Grid, useClientOptions, Switch, DropdownSelect } from '..'
import ActionButton from '../ActionButton'
import FollowUpSummary from '../FollowUpSummary'
import HtmlContent from '../HtmlContent'
import Table, { TableBody, TableCell, TableRow } from '../Table'
import Button from '../Button'
import get from 'lodash/get'
import AddItemButton from '../AddItemButton'
import { CheckIcon } from '@ui/core'
import classnames from 'classnames'
import Hidden from '../Hidden'
import type { QuoteDocument } from 'paintscout'
import moment from 'moment'
import ErrorIcon from '@material-ui/icons/Error'
import striptags from 'striptags'

const useStyles = makeStyles<Theme, FollowUpTableProps>(
  (theme) => ({
    root: {},
    showAdditionalButton: {
      padding: 0,
      justifyContent: 'flex-start',
      paddingLeft: theme.spacing(0.5),
      width: 0
    },
    cellButton: {
      width: '100%',
      display: 'contents',
      // keep focus outline within the table
      cursor: 'pointer'
    },
    checkIcon: {
      fontSize: '15px',
      margin: '2px 5px',
      ['@media (max-width:420px)']: {
        marginLeft: 0
      }
    },
    reviewText: {
      color: theme.palette.error.main
    },
    errorIcon: {
      color: theme.palette.error.main,
      fontSize: '20px',
      margin: '0px 3px',
      ['@media (max-width:420px)']: {
        marginLeft: 0
      }
    },
    disabled: {
      color: 'grey'
    },
    addButton: {
      marginTop: theme.spacing(1)
    },
    tableRow: {
      border: `none`
    },
    border: {
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.borderRadius.md,
      boxShadow: 'none',
      padding: theme.spacing(1, 2)
    },
    tableHeader: {
      marginBottom: theme.spacing(3)
    },
    dividerRow: {
      borderBottom: `2px solid ${theme.palette.grey}`
    },
    cellHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    cellBody: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'end',
      paddingTop: 0,
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        alignItems: 'start'
      }
    },
    cellDetails: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingBottom: '3px',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '1.5px'
      }
    },
    emailDetails: {
      marginRight: '20px'
    },
    smsDetails: {
      ['@media (max-width:420px)']: {
        marginLeft: '20px'
      }
    },
    followUpsBody: {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: 0
      }
    },
    followUpStatus: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'end',
      justifyContent: 'space-between',
      paddingBottom: '3px',
      ['@media (max-width:420px)']: {
        flexDirection: 'column',
        alignItems: 'start'
      }
    },
    followUpSummary: {
      marginTop: '10px',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '10px'
      }
    },
    smallActionButton: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      height: '30px'
    },
    switch: {
      marginBottom: 0,
      ['@media (max-width:420px)']: {
        marginLeft: 0
      }
    },
    notEnabledText: {
      color: theme.palette.grey[600],
      marginLeft: theme.spacing(0.5)
    }
  }),
  { name: 'FollowUpTable' }
)

export interface FollowUpTableProps {
  followUps: FollowUp[]
  controls?: {
    toggle?: boolean
    edit?: boolean
    copy?: boolean
    delete?: boolean
  }
  disabled?: boolean
  onAction?: (action: 'edit' | 'copy' | 'delete' | 'toggle' | 'toggleSendText' | 'export', followUp: FollowUp) => any
  isSuperadmin?: boolean
  settingsView?: boolean
  onAdd?: () => void
  quoteView?: boolean
  quote?: QuoteDocument
}

export default function FollowUpTable({ ...props }: FollowUpTableProps) {
  const classes = useStyles(props)
  const {
    followUps,
    controls = { edit: true, toggle: true, copy: true, delete: true },
    onAction,
    onAdd,
    disabled,
    isSuperadmin,
    quoteView,
    quote
  } = props
  const { options } = useClientOptions()
  const features = getFeatures({ options })
  const showSendText = get(features, 'quotes.followUps.sendText', false)
  const quoteLabel = getObjectLabels({ options }).quote
  const invoiceLabel = getObjectLabels({ options, invoice: true }).quote
  const hasControls = controls?.edit || controls?.copy || controls?.delete
  const [showAdditional, setShowAdditional] = useState([])
  const filterOptions = [
    { label: 'All Document Types', value: 'all' },
    { label: `${quoteLabel.plural}`, value: 'estimate' },
    { label: `${invoiceLabel.plural}`, value: 'invoice' }
  ]
  const [filterValue, setFilterValue] = useState(filterOptions[0])

  const actions = [
    {
      key: 'edit',
      label: 'Edit',
      icon: EditIcon
    },
    {
      key: 'copy',
      label: 'Copy',
      icon: CopyIcon
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: DeleteIcon
    }
  ]
  if (isSuperadmin) {
    actions.push({
      key: 'export',
      label: 'Export',
      icon: ImportExportIcon
    })
  }

  const filteredFollowUps = followUps.filter((followUp) => {
    if (filterValue.value === 'all') {
      return true
    } else if (filterValue.value === 'estimate') {
      return !followUp.isInvoice
    } else if (filterValue.value === 'invoice') {
      return followUp.isInvoice
    }
  })

  return (
    <>
      <Table>
        {!quoteView && (
          <Grid container justifyContent="flex-start" className={classes.tableHeader}>
            <Grid item xs={12} sm={6} md={4}>
              <DropdownSelect
                variant="single"
                options={filterOptions}
                value={filterValue}
                onChange={(option) => {
                  setFilterValue(option)
                }}
              />
            </Grid>
          </Grid>
        )}
        <TableBody>
          <TableRow className={classes.dividerRow} />
        </TableBody>
        <br />
        <TableBody className={classes.followUpsBody}>
          {filteredFollowUps.map((followUp, i) => {
            const messageLength = followUp.smsMessage?.length || 0
            const overMaxTextLength = messageLength > 1500
            const quoteSentDate = quote ? quote?.dates?.client?.sent : null
            const quoteViewedDate = quote ? quote?.dates?.client?.viewed : null
            const quoteExpiryDate = quote ? quote?.dates?.expiryDate : null
            const notStatus: string[] =
              followUp.conditions.notStatus === 'responded'
                ? quote?.is_invoice
                  ? ['paid', 'partial']
                  : ['accepted', 'declined', 'onHold']
                : ['viewed']
            const notStatusHappened =
              followUp.conditions.fromStatus !== 'overdue' &&
              notStatus.some((status) => Object.keys(quote?.status?.history ?? {}).includes(status))
            const fromStatusHasHappened =
              followUp.conditions.fromStatus === 'overdue'
                ? (quote?.dates?.expiryDate ?? false) && moment(quote?.dates?.expiryDate).isBefore(Date.now())
                : Object.keys(quote?.status?.history ?? {}).includes(followUp.conditions.fromStatus)

            // Follow up can be due from 3 different start points
            const quoteOverdue =
              followUp.conditions.fromStatus === 'overdue' &&
              quoteExpiryDate &&
              moment(quoteExpiryDate).isBefore(Date.now())
            const overdueDueDate =
              quoteOverdue &&
              moment(quoteExpiryDate)
                .add(followUp.conditions.days, 'days')
                .add(followUp.conditions.months, 'months')
                .unix() * 1000

            const sentDueDate =
              followUp.conditions?.interval ||
              moment(quoteSentDate)
                .add(followUp.conditions.days, 'days')
                .add(followUp.conditions.months, 'months')
                .unix() * 1000

            const viewDueDate =
              followUp.conditions?.interval ||
              moment(quoteViewedDate)
                .add(followUp.conditions.days, 'days')
                .add(followUp.conditions.months, 'months')
                .unix() * 1000

            const hasBeenSent = Boolean(quote?.followUps && (quote?.followUps[followUp.key]?.sent ?? false))
            const dueDate = overdueDueDate
              ? overdueDueDate
              : followUp.conditions.fromStatus === 'sent'
                ? sentDueDate
                : viewDueDate
            const overdueFollowUp = dueDate < Date.now() && !hasBeenSent

            const followUpDetails = {
              hasBeenSent,
              notStatusHappened,
              fromStatusHasHappened,
              overdueFollowUp,
              dueDate
            }

            return (
              <>
                {/* Large view */}
                <TableRow
                  key={followUp.key}
                  classes={{
                    root: classnames({
                      [classes.tableRow]: true,
                      [classes.disabled]: overdueFollowUp
                    })
                  }}
                >
                  <Card className={classes.border}>
                    <TableCell className={classes.cellButton}>
                      <div onClick={() => controls?.edit && !disabled && onAction?.('edit', followUp)}>
                        <Hidden smUp>
                          <div className={classes.smallActionButton}>
                            {hasControls && (
                              <ActionButton
                                style={{ margin: '0px', padding: 0 }}
                                actions={actions}
                                onActionClick={(ev, action) => {
                                  onAction(action as any, followUp)
                                }}
                              />
                            )}
                          </div>
                        </Hidden>
                        <div className={classes.cellHeader}>
                          <Typography variant="body1" noWrap gutterBottom style={{ fontWeight: 500 }}>
                            <HtmlContent content={followUp.subject} />
                          </Typography>
                          {hasControls && (
                            <Hidden xsDown>
                              <ActionButton
                                actions={actions}
                                onActionClick={(ev, action) => {
                                  onAction(action as any, followUp)
                                }}
                              />
                            </Hidden>
                          )}
                        </div>
                        <div className={classes.cellBody}>
                          <FollowUpSummary
                            className={classes.followUpSummary}
                            followUp={followUp}
                            mt={2}
                            quote={quote}
                            followUpDetails={followUpDetails}
                          />
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className={classes.cellDetails}>
                              <div className={classnames(classes.emailDetails, classes.followUpStatus)}>
                                <Typography variant="body1" style={{ fontWeight: 450 }}>
                                  Email:
                                </Typography>
                                {quoteView && (
                                  <Switch
                                    classes={{ root: classes.switch }}
                                    checked={followUp.active}
                                    disabled={!controls.toggle || overdueFollowUp}
                                    readOnly={disabled}
                                    data-testid={`toggle-follow-up-${followUp.key}`}
                                    onClick={() => {
                                      onAction?.('toggle', followUp)
                                    }}
                                  />
                                )}
                                {!quoteView &&
                                  (followUp?.sendEmail ? (
                                    <div style={{ display: 'flex', flexDirection: 'row', color: 'green' }}>
                                      <CheckIcon className={classes.checkIcon} />
                                      <Typography variant="subtitle1" style={{ color: 'green' }}>
                                        Enabled
                                      </Typography>
                                    </div>
                                  ) : (
                                    <Typography variant="subtitle1" className={classes.notEnabledText}>
                                      Not Enabled
                                    </Typography>
                                  ))}
                              </div>
                              {showSendText && (
                                <div className={classnames(classes.smsDetails, classes.followUpStatus)}>
                                  <>
                                    <Typography variant="body1" style={{ fontWeight: 450, minWidth: '75px' }}>
                                      SMS Text:
                                    </Typography>
                                    {quoteView && (
                                      <Switch
                                        classes={{ root: classes.switch }}
                                        checked={overMaxTextLength ? false : followUp.sendText}
                                        disabled={
                                          !controls.toggle || !followUp.active || overMaxTextLength || overdueFollowUp
                                        }
                                        readOnly={disabled}
                                        data-testid={`toggle-follow-up-send-text-${followUp.key}`}
                                        onClick={() => {
                                          onAction?.('toggleSendText', followUp)
                                        }}
                                      />
                                    )}
                                    {!quoteView &&
                                      (followUp.sendText ? (
                                        striptags(followUp?.smsMessage ?? '').length > 0 &&
                                        striptags(followUp?.smsMessage ?? '').length < 95 ? (
                                          <div style={{ display: 'flex', flexDirection: 'row', color: 'green' }}>
                                            <CheckIcon className={classes.checkIcon} />
                                            <Typography variant="subtitle1" style={{ color: 'green' }}>
                                              Enabled
                                            </Typography>
                                          </div>
                                        ) : (
                                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <ErrorIcon className={classes.errorIcon} />
                                            <Typography variant="subtitle1" className={classes.reviewText}>
                                              Error
                                            </Typography>
                                          </div>
                                        )
                                      ) : (
                                        <Typography variant="subtitle1" className={classes.notEnabledText}>
                                          Not Enabled
                                        </Typography>
                                      ))}
                                  </>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {isSuperadmin && (
                          <>
                            <Button
                              className={classes.showAdditionalButton}
                              variant={'text'}
                              onClick={(ev) => {
                                ev.stopPropagation()
                                if (showAdditional.includes(followUp.key)) {
                                  setShowAdditional((showAdditional) =>
                                    showAdditional.filter((key) => key !== followUp.key)
                                  )
                                } else {
                                  setShowAdditional((showAdditional) => [...showAdditional, followUp.key])
                                }
                              }}
                            >
                              <Typography variant="subtitle1" className={classes.extraInfo}>
                                {showAdditional.includes(followUp.key) ? 'x' : '+'}
                              </Typography>
                            </Button>
                            {showAdditional.includes(followUp.key) && (
                              <>
                                <Typography variant="subtitle2" className={classes.extraInfo}>
                                  {followUp?.created
                                    ? `Created: ${new Date(followUp?.created).toLocaleDateString()} - ${new Date(
                                        followUp?.created
                                      ).toTimeString()}`
                                    : ''}
                                </Typography>
                                <Typography variant="subtitle2" className={classes.extraInfo}>
                                  {followUp?.toggled &&
                                    `Toggled: ${new Date(followUp?.toggled).toLocaleDateString()} - ${new Date(
                                      followUp?.toggled
                                    ).toTimeString()}`}
                                </Typography>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </TableCell>
                  </Card>
                </TableRow>
                {i !== followUps.length - 1 && <div style={{ margin: '20px' }} />}
              </>
            )
          })}
        </TableBody>
      </Table>
      {!!onAdd && (
        <AddItemButton className={classes.addButton} onClick={onAdd} disabled={disabled}>
          New Follow-Up
        </AddItemButton>
      )}
    </>
  )
}
