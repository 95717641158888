import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'
import * as Sentry from '@sentry/core'
import { useSnackbar } from 'notistack'
import type { DialogProps } from '../Dialog'
import Button from '../Button'
import Dialog, { DialogActions, DialogContent, DialogTitle } from '../Dialog'
import Typography from '../Typography'
import DropdownSelect from '../DropdownSelect'
import type { DropdownSelectOption } from '../DropdownSelect'
import Grid from '../Grid'
import Checkbox from '@ui/core/Checkbox'

const useStyles = makeStyles<Theme, ClientExportSelectDialogProps>(
  (_theme) => ({
    root: {},
    dialogTitle: {},
    dialogContent: {
      height: '300px'
    },
    checkBoxDiv: { display: 'flex', alignItems: 'center' },
    cancel: {},
    confirm: {}
  }),
  { name: 'ClientExportSelectDialog' }
)

export interface ClientExportSelectDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  onConfirm: (exportType: string, includeInactive: boolean) => any
  onCancel: (event: React.MouseEvent<HTMLElement>) => any
}

const exportOptions: DropdownSelectOption[] = [
  { label: 'Clients', value: 'clients' },
  { label: 'All Users', value: 'all-users' },
  { label: 'Admin Users', value: 'admin-users' }
]

function ClientExportSelectDialog(props: ClientExportSelectDialogProps) {
  const classes = useStyles(props)
  const { onConfirm, onCancel, ...baseDialogProps } = props
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [exportType, setExportType] = useState('clients')
  const [includeInactive, setIncludeInactive] = useState(false)

  const leftButton = (
    <Button className={classes.cancel} disabled={isLoading} variant={'text'} onClick={onCancel}>
      {'Cancel'}
    </Button>
  )

  return (
    <Dialog {...baseDialogProps}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <Typography variant="h2">ClientExportSelect</Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Grid item xs={4}>
          <DropdownSelect
            name="value"
            label="Export Type"
            placeholder={{
              selectPlaceholder: 'Export Type'
            }}
            variant="single"
            options={exportOptions}
            value={exportOptions.find((option) => option.value === exportType)}
            onChange={(option: DropdownSelectOption) => {
              setExportType(option.value)
            }}
            fullWidth
          />
          {exportType.includes('users') && (
            <div className={classes.checkBoxDiv}>
              <br />
              <Checkbox checked={includeInactive} onChange={() => setIncludeInactive(!includeInactive)} />
              <Typography variant="body1">Include Inactive Users</Typography>
            </div>
          )}
        </Grid>
      </DialogContent>
      <DialogActions leftButton={leftButton}>
        <Button
          className={classes.confirm}
          type="submit"
          form="client-esxport-select-dialog-form"
          variant={'contained'}
          disabled={isLoading}
          data-testid="client-esxport-select-dialog-confirm-button"
          onClick={handleSubmit}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )

  async function handleSubmit() {
    setIsLoading(true)
    try {
      onConfirm(exportType, includeInactive)
    } catch (error) {
      Sentry.captureException(error)
      enqueueSnackbar('error', { variant: 'error' })
    }
    setIsLoading(false)
  }
}

export default ClientExportSelectDialog
