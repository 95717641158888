import { Divider, makeStyles } from '@material-ui/core'
import type { QuoteFile } from 'paintscout'
import type { Theme } from '@material-ui/core/styles'
import type { StyleClasses } from '@ui/core/theme'

import CameraAltIcon from '@material-ui/icons/CameraAlt'
import { Detector } from 'react-detect-offline'
import type { OptionsDocument } from 'paintscout'
import { Button, useDialogs } from '@ui/paintscout'
import type { DialogProps } from '@ui/paintscout'
import {
  Dialog,
  DialogActions,
  DialogContent,
  EditableDialogTitle,
  DialogToggles,
  BasketIcon,
  Typography,
  CalculatorIcon,
  useClientOptions
} from '@ui/paintscout'
import FilesGrid from '../../FilesGrid'
import EditItemFilesDialog from '../EditItemFilesDialog'
import { FormSection } from '@ui/paintscout'

import { LineItemOptionForm } from '../../forms/LineItemForm'
import QuoteTypeSelect from '../../QuoteTypeSelect'
import isEqual from 'lodash/isEqual'
import type { LineItemOption } from '@paintscout/util/builder'
import { getObjectLabels, updateLineItemOption, getFeatures } from '@paintscout/util/builder'
import React, { useState } from 'react'
import DescriptionIcon from '@material-ui/icons/Description'

export interface EditLineItemOptionDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  item: LineItemOption
  isNew?: boolean
  onConfirm: (options: OptionsDocument, isDirty?: boolean) => void
  onCancel: () => void
}

const useStyles = makeStyles<Theme, DialogProps>(
  (theme) => ({
    root: {},
    quoteTypeSelect: {
      marginBottom: theme.spacing(2)
    }
  }),
  { name: 'EditLineItemOptionDialog' }
)

function EditLineItemOptionDialog(props: EditLineItemOptionDialogProps) {
  const { loading, item, onConfirm, onCancel, isNew, ...otherProps } = props
  const classes = useStyles(props)
  const { openDialog, dismissDialog } = useDialogs()
  const { isAdmin, options } = useClientOptions()
  const objectLabels = getObjectLabels({ options })
  const features = getFeatures({ options })

  const materialCostFeature = features.quotes?.materialCost?.enabled ?? false
  const mediaFeature = features?.media?.enabled ?? true
  const lineItemMediaFeature = features?.quotes?.lineItemMediaOptions?.enabled ?? false
  const showFiles = lineItemMediaFeature || isAdmin

  const [currentItem, setCurrentItem] = useState(item)
  const files = (currentItem.files ?? []).map((key) => currentItem.quoteFiles[key])
  const [isDirty, setIsDirty] = useState(false)

  const [showSections, setShowSections] = useState({
    showNotes: (currentItem && !!currentItem.crewNote) || (currentItem && !!currentItem.clientNote),
    showPricing: !!(currentItem && (currentItem.hours || currentItem.price)),
    showMaterials: currentItem.materials && currentItem.materials.length > 0
  })

  const leftButton = (
    <Button disabled={loading} onClick={onCancel} variant={'text'}>
      Cancel
    </Button>
  )

  return (
    <Dialog fullWidth={true} maxWidth={'lg'} {...otherProps}>
      <EditableDialogTitle
        title={`${isNew ? 'Add' : 'Edit'} Line Item`}
        loading={loading}
        onToggle={handleToggle}
        toggleTip={'Active'}
        toggleValue={typeof currentItem.active !== 'undefined' ? !!currentItem.active : true}
        toggleLabel="Active"
      />
      <DialogContent>
        <LineItemOptionForm
          item={currentItem}
          onChange={handleChange}
          onConfirm={handleConfirm}
          showSections={showSections}
        />
        <div>
          {mediaFeature && lineItemMediaFeature && files.length > 0 && (
            <FormSection>
              <Typography variant="h4">Media</Typography>
              <FilesGrid
                files={files}
                editable={true}
                onReorder={handleReorder}
                onFileClick={(index) => handleFileClick(files, index)}
              />
            </FormSection>
          )}
        </div>

        <Divider className={classes.quoteTypeSelect} />
        <QuoteTypeSelect
          label={`${objectLabels.quote.value} Types`}
          onChange={handleQuoteTypeChange}
          variant="multi"
          value={currentItem.quoteTypes}
        />
      </DialogContent>
      <Detector>
        {(online) => {
          const toggles = [
            {
              key: 'showPricing',
              label: 'Add Hours/Cost',
              value: showSections.showPricing,
              icon: CalculatorIcon,
              disabled: false
            },
            {
              key: 'showNotes',
              label: 'Notes',
              value: showSections.showNotes,
              icon: DescriptionIcon,
              disabled: false
            }
          ]
          if (materialCostFeature) {
            toggles.push({
              key: 'showMaterials',
              label: 'Materials',
              value: showSections.showMaterials,
              icon: BasketIcon,
              disabled: false
            })
          }
          if (showFiles && mediaFeature) {
            toggles.push({
              key: 'showMedia',
              label: 'Media',
              value: showFiles,
              icon: CameraAltIcon,
              disabled: !online
            })
          }

          return <DialogToggles toggles={toggles} onToggle={handleToggleSection} scroll={true} />
        }}
      </Detector>
      <DialogActions showBorder={true} leftButton={leftButton}>
        <Button type="submit" form="line-item-form" disabled={loading} variant={'contained'}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )

  function handleChange(lineItem: LineItemOption) {
    setCurrentItem((item) => ({
      ...lineItem,
      quoteTypes: item.quoteTypes,
      active: item.active,
      files: item.files ?? [],
      quoteFiles: item.quoteFiles ?? {}
    }))
    setIsDirty(true)
  }

  function handleConfirm(lineItemOption: LineItemOption) {
    const updatedOptions = updateLineItemOption({
      options,
      lineItemOption: {
        ...lineItemOption,
        quoteTypes: currentItem.quoteTypes,
        files: currentItem.files,
        quoteFiles: currentItem.quoteFiles,
        active: currentItem.active
      }
    })
    onConfirm(updatedOptions, isDirty || !isEqual(lineItemOption, currentItem))
  }

  function handleQuoteTypeChange(values: string[]) {
    setCurrentItem((item) => ({
      ...item,
      quoteTypes: values
    }))
    setIsDirty(true)
  }

  function handleToggle(_event: React.ChangeEvent<HTMLElement>, _checked: boolean) {
    setCurrentItem((item) => ({
      ...item,
      active: !item.active
    }))
    setIsDirty(true)
  }

  function handleToggleSection(key: string, _value: boolean) {
    if (key === 'showMedia') {
      return handleFileClick((currentItem.files ?? []).map((key) => (currentItem.quoteFiles ?? {})[key]))
    } else {
      setShowSections({
        ...showSections,
        [key]: true
      })
      setIsDirty(true)
    }
  }

  function handleFileClick(files?: QuoteFile[], index?: number, uploadFiles?: File[]) {
    openDialog(EditItemFilesDialog, {
      files,
      index,
      uploadFiles,
      onConfirm: (updatedFiles) => {
        const updatedItem = {
          ...currentItem,
          files: (updatedFiles ?? []).map((file) => file.key),
          quoteFiles: updatedFiles.reduce((acc, file) => {
            acc[file.key] = file
            return acc
          }, {})
        }

        setCurrentItem(updatedItem)
        setIsDirty(true)

        dismissDialog()
      },
      onDelete: (key: string) => {
        const updatedFiles = (files ?? []).filter((file) => file.key !== key)
        const updatedItem = {
          ...currentItem,
          files: (updatedFiles ?? []).map((file) => file.key),
          quoteFiles: updatedFiles.reduce((acc, file) => {
            acc[file.key] = file
            return acc
          }, {})
        }

        setCurrentItem(updatedItem)
      },
      onCancel: dismissDialog
    })
  }

  function handleReorder(updatedFiles: QuoteFile[]) {
    const updatedItem = {
      ...currentItem,
      files: updatedFiles.map((file) => file.key),
      quoteFiles: updatedFiles.reduce((acc: any, file: QuoteFile) => {
        acc[file.key] = file
        return acc
      }, {})
    }

    setCurrentItem(updatedItem)
    setIsDirty(true)
  }
}

export default EditLineItemOptionDialog
