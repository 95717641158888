import type { FeatureOptions } from 'paintscout'
import getE2eInjection from '../../quote/util/get-options/get-e2e-injection'

export function getDefaultFeatures(): FeatureOptions {
  return {
    announcements: {
      enabled: false,
      paintScout: false
    },
    contactLeadSources: {
      enabled: false
    },
    integrations: {
      enabled: true,
      providers: {
        getbase: false,
        wisetack: false,
        xero: false
      }
    },
    billing: {
      enabled: true
    },
    presentation: {
      enabled: true,
      jobIDInCoverSection: false
    },
    media: {
      enabled: true
    },
    quotes: {
      enabled: true,
      additionalWork: true,
      workOrderToggleTotalHours: true,
      views: {
        area: true,
        substrate: true,
        workOrder: true
      },
      emails: {
        toStaff: false,
        reminders: false,
        clientAccept: true,
        attachPdf: false,
        acceptThankYou: true
      },
      materialCost: {
        enabled: true
      },
      conversations: {
        enabled: true
      },
      followUps: {
        enabled: true,
        sendText: true
      },
      groups: {
        enabled: true
      },
      templates: {
        enabled: true
      },
      sidebar: {
        enabled: true,
        details: {
          enabled: true
        },
        presentation: {
          enabled: true,
          featuredMedia: false,
          coverLetter: false
        },
        terms: {
          enabled: true
        },
        advanced: {
          enabled: true,
          displayOptions: {
            showQuantities: false,
            groupHours: false,
            separateThousands: false,
            stackItems: false,
            showDetailedBreakdown: false
          }
        }
      },
      areaSubstrates: {
        advancedOptions: {
          enabled: true,
          excludeDiscount: false,
          excludeTax: false,
          showPrice: true,
          showProduct: true,
          showCoats: true,
          showPrep: true,
          hideQuantity: true,
          hideQuantityOnWorkOrder: true,
          workOrderOnly: true,
          hourlyRate: false
        },
        substrateViewDescription: true,
        areaViewDescription: true,
        crewNote: true,
        showSwitchButton: true
      },
      workOrderOnly: true,
      salesRate: true,
      editableAreaTotals: false,
      smartTemplate: true,
      hoursPerUnitLineItems: false,
      deletedQuotes: {
        enabled: false
      }
    },
    sms: {
      enabled: true
    },
    invoices: {
      enabled: true
    },
    dashboard: {
      enabled: true,
      statistics: true
    },
    quoteList: {
      showOnHoldTab: false
    },
    reports: {
      enabled: false,
      paymentsCollected: false,
      outstandingInvoices: false,
      financingApplications: false,
      paymentRequests: false,
      acceptedEstimates: false,
      declinedEstimates: false,
      optionsAccepted: false,
      additionalWork: false,
      taggedEstimates: false,
      tagSummary: false,
      estimatorPerformance: false,
      allActivity: false,
      integrationSources: false
    },
    templates: {
      enabled: true
    },
    logRocket: {
      enabled: true
    },
    team: {
      enabled: true,
      crew: false,
      viewer: false
    },
    fontScaling: {
      enabled: true
    },
    editorOptions: {
      enabled: false,
      textColor: false
    },
    settingsCommunications: {
      enabled: true
    },
    settingsIntegrations: {
      enabled: true
    },
    partnerContent: {
      enabled: false,
      sherwinWilliams: {
        presentationPages: {
          enabled: false
        },
        products: {
          enabled: false
        },
        productOrdering: {
          enabled: false
        }
      }
    },
    wisetackStagedPayouts: false,
    customQuoteTableColumns: true,
    gamification: {
      enabled: true,
      features: {
        enableArchiveSearch: false,
        dashboardStandings: true
      }
    },
    ...getE2eInjection()
  }
}
