import React, { useMemo } from 'react'

import type { Theme } from '@material-ui/core'
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core'
import type { PresentationSectionProps } from '../../'
import { Grid, FilePreview, Typography, ImagePlaceholder, HtmlContent, useClientOptions } from '@ui/paintscout'
import { getObjectLabels } from '@paintscout/util/builder'
import { useQuote } from '../../../context'
import isEmpty from './AboutEstimatorIsEmpty'
import { useEstimatorQuery } from '@paintscout/api'

const useStyles = makeStyles(
  (theme: Theme) => {
    return {
      root: {},
      content: {},
      image: {
        '& img': {
          borderRadius: theme.borderRadius.md
        }
      },
      signature: {
        maxHeight: 50,
        width: 'auto'
      },
      placeholder: {
        border: `2px dashed ${theme.palette.grey.A100}`,
        minHeight: theme.typography.pxToRem(100),
        textAlign: 'center',
        padding: theme.spacing(2),
        color: theme.palette.grey[700],
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
          alignSelf: 'flex-start '
        }
      },
      imagePlaceholder: {
        [theme.breakpoints.down('xs')]: {
          alignSelf: 'flex-start '
        }
      },
      imageDiv: {
        justifyContent: 'flex-start !important',
        [theme.breakpoints.down('xs')]: {
          display: 'flex',
          alignItems: 'flex-start !important',
          alignContent: 'flex-start !important'
        }
      }
    }
  },
  { name: 'PresentationSectionAboutEstimator' }
)

export default function AboutEstimatorSection(props: PresentationSectionProps) {
  const classes = useStyles(props)
  const { isPreview, isSettings, showHelp, WrapperComponent, section } = props

  const { estimator, quote } = useQuote()
  const { options } = useClientOptions()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const responsiveSpacing = isMobile ? 3 : 6
  const { data: estimatorData } = useEstimatorQuery({
    skip: !quote?.owner?.id,
    fetchPolicy: 'cache-and-network',
    variables: { id: quote?.owner?.id, quoteId: quote?._id }
  })
  const fetchedEstimator = estimatorData?.estimator

  const { objectLabels } = useMemo(() => {
    return {
      objectLabels: getObjectLabels({ options })
    }
  }, [])

  const empty = isEmpty({ section: props.section, isSettings, estimator })

  let fields = {
    name: '',
    title: '',
    description: '',
    signature: '',
    image: null
  }

  if ((estimator?.description?.length > 1 && estimator.description !== '<p></p><p></p>') || estimator?.image) {
    fields = {
      name: estimator?.name ?? '',
      title: estimator?.title ?? quote?.owner?.title ?? '',
      description: estimator?.description ?? '',
      signature: estimator?.signature ?? '',
      image: estimator?.image ?? null
    }
  }
  if (!estimator) {
    fields = {
      name: fetchedEstimator?.name ?? '',
      title: fetchedEstimator?.title ?? quote?.owner?.title ?? '',
      description: fetchedEstimator?.description ?? '',
      signature: fetchedEstimator?.signature ?? '',
      image: fetchedEstimator?.image ?? null
    }
  }

  // If the section is empty, but we're showing a preview (isPreview),
  // we shoudl show that placeholder.
  if (empty && isPreview && quote?._id === 'preview') {
    return (
      <WrapperComponent {...props}>
        <AboutEstimatorPresentationSectionPlaceholder />
      </WrapperComponent>
    )
  }

  return (
    <WrapperComponent {...props}>
      <Grid container spacing={responsiveSpacing} alignItems={'center'}>
        {section?.title && (
          <Grid item md={12}>
            <Typography variant={'h2'}>{section.title}</Typography>
          </Grid>
        )}
        <Grid item md={12}>
          <Grid container spacing={3}>
            {(fields?.image || showHelp) && (
              <Grid item md={6}>
                {fields?.image && <FilePreview file={fields.image} classes={{ root: classes.image }} />}
                {!fields?.image && showHelp && <ImagePlaceholder title={'Image'} width={'100%'} height={200} />}
              </Grid>
            )}
            {fields?.description && (
              <Grid item md={fields?.image || showHelp ? 6 : 12}>
                <Grid container spacing={3}>
                  {fields?.name && (
                    <Grid item md={12}>
                      <Typography variant={'h3'}>{fields.name}</Typography>
                      {fields?.title && <Typography variant={'h4'}>{fields.title}</Typography>}
                    </Grid>
                  )}
                  <Grid item md={12}>
                    <HtmlContent content={fields.description} classes={{ root: classes.content }} />
                  </Grid>
                  {fields?.signature && (
                    <Grid item md={12}>
                      <img src={fields.signature} className={classes.signature} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </WrapperComponent>
  )

  function AboutEstimatorPresentationSectionPlaceholder() {
    return (
      <Grid container spacing={responsiveSpacing} alignItems={'center'}>
        {section?.title && (
          <Grid item xs={12}>
            <Typography variant={'h2'}>About Your Estimator</Typography>
          </Grid>
        )}
        <Grid item xs={12} container spacing={3}>
          <Grid item classes={{ root: classes.imageDiv }} xs={12} sm={6}>
            <ImagePlaceholder
              classes={{ root: classes.imagePlaceholder }}
              subtitle={'Estimator Image'}
              width={'100%'}
              height={200}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant={'h3'}>Estimator Name</Typography>
                <Typography variant={'h4'}>Estimator Title</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant={'subtitle1'}
                >{`This section will show information about the ${objectLabels.quote.plural} estimator.`}</Typography>
                <Typography
                  variant={'subtitle1'}
                >{`The information displayed can be set in the Estimator's profile.`}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
