import React from 'react'
import Grid from '../Grid'
import { getRangePresets } from '@paintscout/util/util'
import { Chip, Typography, makeStyles } from '@material-ui/core'
import classnames from 'classnames'
import Hidden from '../Hidden'
import type { StyleClasses } from '@ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 6, 2, 6),
    borderBottom: `1px solid ${theme.palette.divider}`,
    width: '100% !important',
    margin: '0px !important',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 0, 2, 0)
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5, 0, 1, 0)
    }
  },
  title: {
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    ...theme.typography.h6,
    textTransform: 'uppercase',
    fontSize: '0.9rem',
    fontWeight: 500,
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(6.5)
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(3.5)
    }
  },
  chip: {
    cursor: 'pointer',
    borderRadius: theme.borderRadius.md
  },
  chipLabel: {
    '& span': {
      fontSize: '0.7rem'
    }
  },
  selectedChip: {
    backgroundColor: theme.paintscout.palette.primary,
    color: 'white',
    '&:focus': {
      backgroundColor: theme.paintscout.palette.primary,
      color: 'white'
    }
  },
  scrollableContainer: {
    display: 'flex',
    overflowX: 'auto',
    padding: theme.spacing(0, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3)
    },
    '& > div': {
      marginRight: theme.spacing(1)
    },
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    scrollbarWidth: 'none',
    '-ms-overflow-style': 'none'
  }
}))

type DatePickerPreset = {
  value: string
  label: string
}

interface DatePickerPresetChipsProps {
  presets?: DatePickerPreset[]
  selectedPreset?: DatePickerPreset
  onPresetSelect: (preset: DatePickerPreset) => void
  hideTitle?: boolean
  classes?: StyleClasses<typeof useStyles>
}

export default function DatePickerPresetChips(props: DatePickerPresetChipsProps) {
  const { presets = getRangePresets(), selectedPreset, onPresetSelect, hideTitle } = props
  const classes = useStyles(props)

  return (
    <Grid className={classes.root} container alignItems="center" spacing={1}>
      {!hideTitle && (
        <Hidden smDown>
          <Grid item xs={12}>
            <Typography className={classes.title}>jump to</Typography>
          </Grid>
        </Hidden>
      )}
      <Hidden mdDown>
        {(presets ?? [])?.map((preset) => (
          <Grid item key={preset.value}>
            <DateChip
              label={preset.label}
              selected={selectedPreset?.value === preset.value}
              onClick={() => onPresetSelect(preset)}
            />
          </Grid>
        ))}
      </Hidden>
      <Hidden lgUp>
        <div className={classes.scrollableContainer}>
          {(presets ?? [])?.map((preset) => (
            <Grid item key={preset.value}>
              <DateChip
                label={preset.label}
                selected={selectedPreset?.value === preset.value}
                onClick={() => onPresetSelect(preset)}
              />
            </Grid>
          ))}
        </div>
      </Hidden>
    </Grid>
  )
}

function DateChip(props: { label: string; selected?: boolean; onClick: () => void }) {
  const { label, selected, onClick } = props
  const classes = useStyles()
  return (
    <Chip
      classes={{ root: classnames(classes.chip, selected && classes.selectedChip), label: classes.chipLabel }}
      label={<Typography variant="caption">{label}</Typography>}
      onClick={onClick}
    />
  )
}
