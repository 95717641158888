import { makeStyles } from '@material-ui/core'
import { getFeatures, getObjectLabels, getEmailTemplates, uuid } from '@paintscout/util/builder'
import { Form, useFormikContext } from 'formik'
import get from 'lodash/get'
import type { EmailTemplate, UserPreferencesDocument } from 'paintscout'
import React, { useMemo, useState, useCallback, useRef } from 'react'
import { SelectUserDialog, useClientOptions } from '../..'
import { useDialogs } from '../../DialogStack'
import { NewFormSection as FormSection } from '../../FormSection'
import FormSectionTitle from '../../FormSectionTitle'
import TileList from '../../TileList'
import type { ValueType } from '../../TileList'
import AddressBook from './AddressBook'
import EmailTemplateDialog from './EmailTemplateDialog'
import ConfirmationDialog from '../../../src/dialogs/ConfirmationDialog'
import Button from '../../Button'
import InputField from '../../InputField'
import { useSnackbar } from 'notistack'
import { useTransferUserDocsMutation } from '@paintscout/api'

import DeleteIcon from '@material-ui/icons/Delete'
import type { User } from '@paintscout/api'
import SettingsPage from '../../SettingsPage'
import { TitleCallout } from '../../TitleCallout'

const useStyles = makeStyles((theme) => ({
  root: {},
  addressbookSection: {
    width: '100%',
    maxWidth: 600
  },
  templates: {
    marginTop: theme.typography.pxToRem(15)
  },
  input: {
    width: '100%'
  }
}))

export interface EmailFormProps {
  onDialogConfirm?: () => void
  isAdmin?: boolean
}

function EmailForm({ onDialogConfirm, isAdmin }: EmailFormProps) {
  const { values, setFieldValue } = useFormikContext<{ preferences: UserPreferencesDocument; user: User }>()
  const { openDialog, dismissDialog } = useDialogs()
  const { options, save } = useClientOptions()
  const currentOptions = useRef(options)
  const classes = useStyles()
  const [updateableEmailJson, setUpdateableEmailJson] = useState<string>(
    JSON.stringify(values.preferences.emails, null, 2)
  )
  const [jsonHidden, setJsonHidden] = useState<boolean>(true)
  const [jsonDirty, setJsonDirty] = useState<boolean>(false)
  const objectLabels = getObjectLabels({ options })
  const quoteLabel = objectLabels.quote
  const invoiceLabel = getObjectLabels({ options, invoice: true }).quote
  const features = getFeatures({ options })
  const [_currentSelected, setCurrentSelected] = useState<ValueType[]>([])
  const [_currentCustomSelected, setCurrentCustomSelected] = useState<ValueType[]>([])

  const [transferUserDocs] = useTransferUserDocsMutation()
  const [transferLoading, setTransferLoading] = useState<boolean>(false)
  const { enqueueSnackbar } = useSnackbar()
  const hasAddressBook = get(features, 'quotes.emails.hasAddressBook', false)
  const toFieldHidden = !get(features, 'quotes.emails.allowToField')

  const templateItems = useMemo(() => {
    return [
      {
        label: quoteLabel.value,
        value: 'send-quote-client',
        subLabel: `Set default ${quoteLabel.value.toLowerCase()} template sent to client`,
        toHidden: toFieldHidden
      },
      get(features, 'quotes.emails.toStaff', false)
        ? {
            label: `${quoteLabel.value} to Staff`,
            value: 'quote-sent-notification',
            subLabel: `Set default ${quoteLabel.value.toLowerCase()} template sent to staff`,
            toHidden: toFieldHidden
          }
        : null,
      {
        label: 'Invoice',
        value: 'send-invoice-client',
        subLabel: 'Set default invoice template sent to client',
        toHidden: toFieldHidden
      },
      {
        label: 'Work Order',
        value: 'send-work-order-staff',
        subLabel: `Set default ${objectLabels.workOrder.value.toLowerCase()} template sent to crew`,
        toHidden: toFieldHidden
      },
      // get(features, 'quotes.emails.acceptThankYou', false)
      //   ? {
      //       label: `Thank You - Accepted`,
      //       value: 'accept-thank-you',
      //       subLabel: `Send thank you to client after ${quoteLabel.value.toLowerCase()} accepted`,
      //       toHidden: true
      //     }
      //   : null,
      // get(options, 'options.allowDecline', false)
      //   ? {
      //       label: 'Thank you - Declined',
      //       value: 'decline-thank-you',
      //       subLabel: `Send repsonse to client after ${quoteLabel.value.toLowerCase()} declined`,
      //       toHidden: true
      //     }
      //   : null,
      // get(features, 'quotes.emails.reminders', false)
      //   ? {
      //       label: `Reminder/Follow-up`,
      //       value: 'quote-reminder',
      //       subLabel: `Send ${quoteLabel.value.toLowerCase()} reminder to client`,
      //       toHidden: true
      //     }
      //   : null,
      get(features, 'quotes.productOrderForm', false)
        ? {
            label: 'Product Order Form',
            value: 'product-order-form',
            subLabel: 'Send a product order form',
            toHidden: toFieldHidden
          }
        : null,
      {
        label: `Payment Request`,
        value: 'payment-request',
        subLabel: `Send payment request to client`,
        toHidden: toFieldHidden
      }
    ]
      .filter((item) => {
        return item !== null
      })
      .map((item, index) => {
        if (
          values.preferences?.emails?.templates?.values?.[item.value] ||
          ['accept-thank-you', 'decline-thank-you', 'product-order-form', 'payment-request'].includes(item.value)
        ) {
          return {
            ...item,
            key: String(index),
            active: item.value === 'product-order-form' ? true : undefined,
            enabled: get(values.preferences, `emails.templates.values[${item.value}].enabled`, true),
            toHidden: toFieldHidden
          }
        }
        return null
      })
      .filter((item) => {
        return item !== null
      })
  }, [options, values])

  const customTemplateItems = useMemo(() => {
    const customEmailTemplateValues = values.preferences?.emails?.customTemplates?.values
    const items = []
    if (customEmailTemplateValues)
      Object.keys(customEmailTemplateValues).forEach((key) => items.push(customEmailTemplateValues[key]))

    const mappedItems = items.map((item, index) => {
      return {
        enabled: item.enabled,
        key: index,
        label: item.label,
        subLabel: '',
        value: item.name,
        toHidden: toFieldHidden
      }
    })

    return mappedItems
  }, [values])

  const automaticEmailTemplates = useMemo(() => {
    return [
      get(features, 'quotes.emails.acceptThankYou', false)
        ? {
            label: `Thank You - Accepted`,
            value: 'accept-thank-you',
            subLabel: `Send thank you to client after ${quoteLabel.value.toLowerCase()} accepted`,
            toHidden: true
          }
        : null,
      get(options, 'options.allowDecline', false)
        ? {
            label: 'Thank you - Declined',
            value: 'decline-thank-you',
            subLabel: `Send repsonse to client after ${quoteLabel.value.toLowerCase()} declined`,
            toHidden: true
          }
        : null,
      get(features, 'quotes.emails.reminders', false)
        ? {
            label: `Reminder/Follow-up`,
            value: 'quote-reminder',
            subLabel: `Send ${quoteLabel.value.toLowerCase()} reminder to client`,
            toHidden: true
          }
        : null,
      {
        label: `Payment Received`,
        value: 'payment-received',
        key: 'payment-received',
        toHidden: true
      }
    ]
      .filter((item) => {
        return item !== null
      })
      .map((item, index) => {
        if (values.preferences?.emails?.templates?.values?.[item.value]) {
          return {
            ...item,
            key: index,
            enabled: get(values.preferences, `emails.templates.values[${item.value}].enabled`, true),
            toHidden: toFieldHidden
          }
        } else if (
          ['accept-thank-you', 'decline-thank-you', 'product-order-form', 'payment-received'].includes(item.value)
        ) {
          return {
            ...item,
            key: index,
            active: item.value === 'product-order-form',
            toHidden: toFieldHidden,
            enabled: get(values.preferences, `emails.templates.values[${item.value}].enabled`, true)
          }
        }
        return null
      })
      .filter((item) => {
        return item !== null
      })
  }, [options, values])

  useCallback((event: any, keys: ValueType[], type: string) => {
    event.stopPropagation()
    openDialog(SelectUserDialog, {
      companyId: values.user.client.meta._id,
      currentUser: values.user,
      onConfirm: (newUser: User) => {
        dismissDialog()
        openDialog(ConfirmationDialog, {
          message: (
            <>
              <p>Are you sure you want to transfer these email templates?</p>
            </>
          ),
          onConfirm: async () => {
            setTransferLoading(true)
            // Check for disabled, they dont xfer properly
            const anyDisabled =
              type === 'default'
                ? keys.some((key) => !templateItems[Number(key)].enabled)
                : keys.some((key) => !customTemplateItems[Number(key)].enabled)
            if (anyDisabled) {
              enqueueSnackbar('Unable to transfer diabled email, must enable all emails you wish to transfer', {
                variant: 'warning'
              })
              dismissDialog()
              setTransferLoading(false)
              return false
            }

            // Get selected values and string together to send back.
            const emailValues =
              type === 'default'
                ? keys.map((key) => templateItems[Number(key)].value)
                : keys.map((key) => customTemplateItems[Number(key)].value)
            const res = await transferUserDocs({
              variables: {
                id: values.user.user_id,
                transferTo: newUser.user_id,
                type: 'email',
                values: [...emailValues, type].map(encodeURI).join('&') // Send email values back w/ type
              }
            })

            if (res) {
              enqueueSnackbar('Transfer has been started, you will receive an email summary when complete', {
                variant: 'success'
              })
            } else {
              enqueueSnackbar('Error starting transfer', { variant: 'error' })
            }
            // Cleanup
            if (type === 'custom') {
              setCurrentCustomSelected([])
            } else {
              setCurrentSelected([])
            }

            setTransferLoading(false)
            dismissDialog()
          },
          onCancel: () => {
            dismissDialog()
          }
        })
      },
      onCancel: dismissDialog
    })
  }, [])

  function handleEditTemplateItem(event: any, selectedTemplate: ValueType[] | ValueType) {
    const templateItem = [...automaticEmailTemplates, ...templateItems].find((item) => item.key === selectedTemplate)
    const template = values.preferences.emails?.templates?.values?.[templateItem.value]
      ? values.preferences.emails.templates.values[templateItem.value]
      : getEmailTemplates({})[templateItem.value]

    openDialog(EmailTemplateDialog, {
      item: template,
      options,
      addressBook: values.preferences.addressBook,
      isAdmin,
      toHidden: templateItem?.toHidden ?? false,
      onEmailMigrate: async (selectedTemplate: EmailTemplate) => {
        setTransferLoading(true)
        const updatedOptions = {
          ...currentOptions.current,
          options: {
            ...currentOptions.current?.options,
            emails: {
              ...currentOptions.current?.options?.emails,
              values: {
                ...(currentOptions.current.options?.emails?.values ?? {}),
                [selectedTemplate.name]: selectedTemplate
              }
            }
          }
        }

        await save({ options: updatedOptions })
        currentOptions.current = updatedOptions
        setTransferLoading(false)
        dismissDialog()
      },
      onConfirm: (updatedTemplate: EmailTemplate) => {
        setFieldValue('preferences.emails.templates.values', {
          ...values.preferences.emails.templates.values,
          [templateItem.value]: updatedTemplate
        })

        const order = values.preferences.emails.templates.order
        if (order.indexOf(templateItem.value) === -1) {
          setFieldValue('preferences.emails.templates.order', [
            ...values.preferences.emails.templates.order,
            templateItem.value
          ])
        }

        const addressBook = values.preferences.addressBook
        updatedTemplate.additionalRecipients.forEach((recipient) => {
          if (!addressBook.find((email) => email === recipient)) {
            addressBook.push(recipient)
          }
        })
        setFieldValue('preferences.addressBook', addressBook)

        if (onDialogConfirm) {
          onDialogConfirm()
        }

        dismissDialog()
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleCustomTemplate(event: any, key?: number) {
    const newId = key > -1 ? '' : uuid()
    const emptyTemplate = {
      additionalRecipients: [],
      body: '<p></p>',
      defaultFor: [],
      enabled: true,
      label: 'My Custom Template', // title in dialog
      name: newId,
      subject: '<p></p>'
    }

    const name = key > -1 ? customTemplateItems.find((item) => item.key === key).value : newId
    const template = key > -1 ? values.preferences.emails.customTemplates.values[name] : emptyTemplate

    openDialog(EmailTemplateDialog, {
      item: template,
      options,
      customTemplate: true,
      addressBook: values.preferences.addressBook,
      isAdmin,
      toHidden: !get(features, 'quotes.emails.allowToField'),
      onEmailMigrate: async (selectedCustomTemplate: EmailTemplate) => {
        setTransferLoading(true)
        const updatedCustomEmails = {
          order: [
            ...(currentOptions.current?.options?.emails?.customTemplates?.order ?? []),
            selectedCustomTemplate.name
          ],
          values: {
            ...(currentOptions.current?.options?.emails?.customTemplates?.values ?? {}),
            [selectedCustomTemplate.name]: selectedCustomTemplate
          }
        }

        const updatedOptions = {
          ...currentOptions.current,
          options: {
            ...currentOptions.current?.options,
            emails: {
              ...currentOptions.current?.options?.emails,
              customTemplates: {
                ...currentOptions.current?.options?.emails?.customTemplates,
                values: {
                  ...updatedCustomEmails.values
                },
                order: [...updatedCustomEmails.order]
              }
            }
          }
        }

        await save({ options: updatedOptions })
        setTransferLoading(false)
        dismissDialog()
      },
      onConfirm: (updatedCustomTemplate: EmailTemplate) => {
        setFieldValue('preferences.emails.customTemplates.values', {
          ...values.preferences.emails.customTemplates?.values,
          [name]: updatedCustomTemplate
        })

        const addressBook = values.preferences.addressBook
        updatedCustomTemplate.additionalRecipients.forEach((recipient) => {
          if (!addressBook.find((email) => email === recipient)) {
            addressBook.push(recipient)
          }
        })
        setFieldValue('preferences.addressBook', addressBook)

        const order = values.preferences.emails.customTemplates?.order
        const updatedOrder = order ? [...order, name] : [name]
        if (!order || order.indexOf(name) === -1) {
          setFieldValue('preferences.emails.customTemplates.order', updatedOrder)
        }

        if (onDialogConfirm) {
          onDialogConfirm()
        }

        dismissDialog()
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDeleteCustomItem(ev: any, actionName: string, rowId: number) {
    openDialog(ConfirmationDialog, {
      message: (
        <>
          <p>Are you sure you want to delete this template?</p>
          <p>It will no longer be available when sending {quoteLabel.plural}.</p>
        </>
      ),
      onConfirm: () => {
        const name = customTemplateItems[rowId].value
        const updatedValues = { ...values.preferences.emails.customTemplates.values }
        delete updatedValues[name]

        const order = values.preferences.emails.customTemplates.order
        const updatedOrder = order.filter((item) => item !== name)

        setFieldValue('preferences.emails.customTemplates.values', updatedValues)
        setFieldValue('preferences.emails.customTemplates.order', updatedOrder)

        if (onDialogConfirm) {
          onDialogConfirm()
        }

        dismissDialog()
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  const deleteAction = {
    key: 'delete',
    label: 'Delete',
    icon: DeleteIcon
  }

  const handleJsonUpdate = () => {
    const updatedValues = JSON.parse(updateableEmailJson)
    if (updatedValues) {
      setFieldValue('preferences.emails', updatedValues)
      setJsonHidden(true)
    }
  }

  return (
    <SettingsPage title="Emails">
      <Form className={classes.root}>
        {hasAddressBook && (
          <FormSection>
            <FormSectionTitle
              title={'Address Book'}
              variant={'h4'}
              subTitle={'Add commonly used email addresses for easy access when sending an email.'}
            />
            <AddressBook />
          </FormSection>
        )}
        <FormSection>
          <TitleCallout
            title="Emails Sent by You"
            variant="h3"
            path="communications-email-templates"
            openContent={`Create and customize your default email templates, which will serve as the preset option when sending ${quoteLabel.plural.toLowerCase()}, ${invoiceLabel.plural.toLowerCase()}, or work orders.`}
            learnMoreLink="http://help.paintscout.com/en/articles/6063151-how-to-create-an-email-template"
          />
          <div className={classes.templates}>
            <TileList
              disabled={transferLoading}
              showActive={true}
              items={[...templateItems, ...customTemplateItems]}
              onSelect={(event: any, selected: ValueType[] | ValueType) => {
                if (typeof selected === 'number') {
                  handleCustomTemplate(event, selected as number)
                } else {
                  handleEditTemplateItem(event, selected)
                }
              }}
              createButton={'New Template'}
              onCreate={handleCustomTemplate}
              actions={(tile) => (typeof tile.key === 'number' ? [deleteAction] : null)}
              onActionClick={handleDeleteCustomItem}
            />
          </div>
        </FormSection>
        <FormSection>
          <TitleCallout
            title="Emails Sent Automatically"
            variant="h3"
            path="communications-email-automations"
            openContent={`Customize your automatic email template preferences. These emails are automatically sent when ${quoteLabel.plural.toLowerCase()} are accepted or declined.`}
            learnMoreLink="http://help.paintscout.com/en/articles/8289201-automatic-emails"
          />
          <div className={classes.templates}>
            <TileList
              disabled={transferLoading}
              items={automaticEmailTemplates}
              onSelect={(event: any, selected: ValueType[] | ValueType) => {
                handleEditTemplateItem(event, selected)
              }}
            />
          </div>
        </FormSection>
        {isAdmin && (
          <FormSection>
            <FormSectionTitle
              title={'JSON Editor'}
              variant={'h4'}
              subTitle={`Edit the JSON directly for the email preferences.`}
            />
            <div style={{ display: 'flex' }}>
              <Button onClick={() => setJsonHidden((jsonHidden) => !jsonHidden)} variant={'text'}>
                {jsonHidden ? 'Show' : 'Hide'}
              </Button>
              {jsonDirty && (
                <Button onClick={() => handleJsonUpdate()} variant={'text'}>
                  Update
                </Button>
              )}
            </div>
            {jsonHidden && (
              <div>
                <InputField
                  className={classes.input}
                  multiline
                  value={updateableEmailJson}
                  onChange={(e) => {
                    setUpdateableEmailJson(e.target.value)
                    if (!jsonDirty) {
                      setJsonDirty(true)
                    }
                  }}
                  fullWidth={true}
                />
              </div>
            )}
          </FormSection>
        )}
      </Form>
    </SettingsPage>
  )
}

export default EmailForm
