import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { DialogProps } from '../'
import type { StyleClasses } from '@ui/core/theme'
// import TaskConfirmationDialogForm from 'src/components/forms/TaskConfirmationDialogForm'
import { Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography, InputField } from '../'
import * as Sentry from '@sentry/core'
import { useSnackbar } from 'notistack'
import { isEmailValid } from '@paintscout/util/util'

const useStyles = makeStyles<Theme, TaskConfirmationDialogProps>(
  (_theme) => ({
    root: {},
    dialogTitle: {},
    dialogContent: {},
    cancel: {},
    confirm: {}
  }),
  { name: 'TaskConfirmationDialog' }
)

export interface TaskConfirmationDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  message?: string
  onConfirm: (email: string) => any
  onCancel: (event: React.MouseEvent<HTMLElement>) => any
}

function TaskConfirmationDialog(props: TaskConfirmationDialogProps) {
  const classes = useStyles(props)
  const { onConfirm, onCancel, message = '', ...baseDialogProps } = props
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const email = useRef<HTMLInputElement>(null)
  const [validEmail, setIsValidEmail] = useState<boolean>(isEmailValid(email?.current?.value ?? ''))

  const leftButton = (
    <Button className={classes.cancel} disabled={isLoading} variant={'text'} onClick={onCancel}>
      {'< Back'}
    </Button>
  )

  return (
    <Dialog {...baseDialogProps}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <Typography variant="h2">Confirm Task Start</Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Typography variant="body1">{message}</Typography>
        <InputField
          label={'Confirmation Email:'}
          className={'quote-name'}
          fullWidth
          onChange={() => {
            setIsValidEmail(isEmailValid(email?.current?.value ?? ''))
          }}
          defaultValue={email.current}
          inputRef={email}
        />
      </DialogContent>
      <DialogActions leftButton={leftButton}>
        <Button
          className={classes.confirm}
          onClick={handleSubmit}
          type="submit"
          variant={'contained'}
          disabled={isLoading || !validEmail}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )

  async function handleSubmit() {
    setIsLoading(true)
    try {
      onConfirm(email.current.value)
    } catch (error) {
      Sentry.captureException(error)
      enqueueSnackbar('error', { variant: 'error' })
    }
    setIsLoading(false)
  }
}

export default TaskConfirmationDialog
