import React, { useEffect, useState } from 'react'
import type { FormProps } from './CollectPayrixPaymentDialog'
import { usePayrixStyles } from './usePayrixStyles'
import type { DropdownSelectOption } from '@ui/paintscout'
import { DropdownSelect, Grid, InputLabel, Spinner } from '@ui/paintscout'
import { useTheme } from '@material-ui/core'
import { useCreatePaymentActivityMutation } from '@paintscout/api'
import type { PayrixTxnMetadata } from 'paintscout'
import { randomString } from '@paintscout/util/util'
import { useSnackbar } from 'notistack'
import * as Sentry from '@sentry/react'

export default function ACHForm(props: FormProps) {
  const classes = usePayrixStyles()
  const [createPaymentActivity] = useCreatePaymentActivityMutation()
  const [accountType, setAccountType] = useState<DropdownSelectOption>({ label: 'Personal Checking', value: 8 })
  const theme = useTheme()
  const {
    amount,
    companyId,
    handlePaymentError,
    handleSuccessfulPayment,
    merchantId,
    note,
    payFieldsLoading,
    paymentRequestId,
    quote,
    setActivityId,
    setPayFieldsLoading,
    setSubmitting,
    source,
    surcharge
  } = props
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    // Load the Payrix script
    let submitButton
    let handleSubmit
    let activityId

    const script = document.createElement('script')
    script.src = `${process.env.PAYRIX_API_URL}/payFieldsScript?spa=1`
    script.type = 'text/javascript'
    script.async = true
    script.onload = () => {
      const PayFields = ((window as any).PayFields as any) || {}
      PayFields.config.apiKey = process.env.PAYRIX_PUBLIC_KEY
      PayFields.config.merchant = merchantId
      PayFields.config.mode = 'txn'
      PayFields.config.txnType = 'ecsale'
      PayFields.config.amount = Math.round(amount * 100)
      PayFields.customizations.optionalFields = ['#payment_cvv', '#expiration']
      PayFields.customizations.placeholders = {
        '#account_number': '123456789',
        '#name': ''
      }

      PayFields.fields = [
        // Number must be present for PayField divs to load
        {
          type: 'number',
          element: '#number'
        },
        {
          type: 'name',
          element: '#name',
          values: {
            name: quote.contact ? `${quote.contact.first_name} ${quote.contact.last_name}` : null
          }
        },
        {
          type: 'account_number',
          element: '#account_number'
        },
        {
          type: 'account_type',
          element: '#account_type'
        },
        {
          type: 'routing',
          element: '#routing'
        }
      ]
      PayFields.customizations.style = {
        '.number': {
          display: 'none'
        },
        '.input': {
          ...theme.typography.body1,
          border: `1px solid ${theme.palette.grey.A100}`,
          borderRadius: theme.borderRadius.md,
          boxSizing: 'border-box',
          height: '40px',
          padding: '10px 16px',
          width: '100%',
          fontSize: '1rem',
          color: theme.palette.text.primary
        },
        '.card-icon': {
          marginRight: '6rem',
          marginTop: '.25rem'
        }
      }

      PayFields.ready()

      // Set setPayFieldsLoading false after 1 second to prevent flickering
      setTimeout(() => {
        setPayFieldsLoading(false)
      }, 1000)

      handleSubmit = () => {
        setSubmitting(true)
        setPayFieldsLoading(true)
        PayFields.customizations.optionalFields = ['#number']

        // create an optimistic activityId and create the activity on success
        activityId = randomString({ length: 32, charset: 'abcdefghijklmnopqrstuvwxyz0123456789' })

        // 94/100 characters already used
        const payrixMeta: PayrixTxnMetadata = {
          activityId,
          companyId: companyId,
          env: process.env.DEPLOYMENT_STAGE
        }

        PayFields.config.additionalData = { txnDescription: JSON.stringify(payrixMeta) }

        PayFields.submit()
      }

      submitButton = document.querySelector('#payrix-submit-button')
      submitButton?.addEventListener('click', handleSubmit)

      // Failure Callback
      PayFields.onFailure = function (err) {
        setSubmitting(false)
        setPayFieldsLoading(false)
        const errors = (err.errors as any[]) || []
        console.log(errors)
        const error15 = errors.find((error) => error.code === 15)
        if (error15) {
          if (error15.field === 'payment.number') {
            enqueueSnackbar('Please enter a valid account number', { variant: 'error' })
            return
          } else if (error15.errorCode === 'txn_limit_exceeded') {
            enqueueSnackbar('Transaction limit exceeded. Please contact support.', { variant: 'error' })
            return
          }

          // Error code 15 is a validation error that isn't caught by the onValidationFailure callback
          // So we'll just set submitting to false and hope the error message shows up in the PayFields div or is obvious to the user
          // We'll also capture the error in Sentry if its not handled by a snackbar above
          enqueueSnackbar('Validation error. Please make sure all fields are complete.', { variant: 'error' })
          Sentry.captureException(err)
          return
        }
        handlePaymentError(err)
      }

      // Success Callback
      PayFields.onSuccess = async function (response) {
        const activity = await createPaymentActivity({
          variables: {
            ach: true,
            amount,
            deposit: !quote.is_invoice,
            docId: quote._id,
            id: activityId,
            note: note?.length > 100 ? `${note.substring(0, 100)}...` : note,
            paymentRequestId,
            provider: 'payrix',
            source,
            isProcessing: true,
            surcharge
          }
        })

        const responseId = activity.data.createPaymentActivity.activity._id
        setActivityId(responseId)

        console.log(response)
        handleSuccessfulPayment()
      }

      PayFields.onValidationFailure = function () {
        console.log('onValidationFailure')
        setSubmitting(false)
        setPayFieldsLoading(false)
      }
    }

    document.head.appendChild(script)

    return () => {
      // cleanup
      const PayFields = ((window as any).PayFields as any) || {}
      submitButton.removeEventListener('click', handleSubmit)
      document.head.removeChild(script)
      PayFields.unmountAll()
      ;(window as any).PayFields = undefined
    }
  }, [])

  return (
    <>
      {payFieldsLoading && <Spinner style={{ display: 'block', margin: '81px auto', width: 30 }} />}
      <Grid container item spacing={2} className={classes.root} style={{ display: payFieldsLoading ? 'none' : 'flex' }}>
        <Grid item xs={12} md={6} className={classes.payField}>
          <InputLabel className={classes.inputLabel} htmlFor="name">
            Full Name
          </InputLabel>
          <div id="name"></div>
        </Grid>
        <Grid item xs={12} md={6} className={classes.payField}>
          <InputLabel className={classes.inputLabel} htmlFor="account_number">
            Account Number
          </InputLabel>
          <div id="account_number"></div>
        </Grid>
        <Grid item xs={12} md={6}>
          <DropdownSelect
            fullWidth
            label={'Account Type:'}
            options={[
              { label: 'Personal Checking', value: 8 },
              { label: 'Personal Savings', value: 9 },
              { label: 'Corporate (Business) Checking', value: 10 },
              { label: 'Corporate (Business) Savings', value: 11 }
            ]}
            variant="single"
            value={accountType}
            onChange={setAccountType}
            popperModifiers={{
              flip: {
                enabled: false
              }
            }}
          />
          <input id="account_type" type="hidden" value={accountType.value} />
        </Grid>
        <Grid item xs={12} md={6} className={classes.payField}>
          <InputLabel className={classes.inputLabel} htmlFor="routing">
            Routing Number
          </InputLabel>
          <div id="routing"></div>
        </Grid>
        <div style={{ display: 'none' }}>
          <div id="number"></div> {/* Number must be present for PayField divs to load */}
        </div>
      </Grid>
    </>
  )
}
