import type { MutableRefObject } from 'react'
import React from 'react'
import Grid from '../Grid'
import InputField from '../InputField'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'
import type { Moment } from 'moment'
import type { DatePickerInputProps } from './DateRangePicker'
import PencilIcon from '@material-ui/icons/CreateOutlined'
import InputLabel from '../InputLabel'

const useStyles = makeStyles((theme) => ({
  base: {
    ...theme.typography.body1,
    borderRadius: `${theme.borderRadius.md} !important`,
    cursor: 'pointer !important',
    '& input': {
      cursor: 'pointer !important'
    },
    '& svg': {
      fontSize: '1rem'
    }
  },
  selected: {
    borderRadius: `${theme.borderRadius.md} !important`,
    borderColor: theme.paintscout.palette.primary + ' !important',
    boxShadow: `0 0 0 0.5px ${theme.paintscout.palette.primary} !important`,
    '-webkit-box-shadow': `0 0 0 0.5px ${theme.paintscout.palette.primary} !important`,
    '-webkit-border-radius': '0px',
    '-webkit-appearance': 'none'
  },
  unselected: {
    borderColor: theme.palette.grey[500],
    '& input': {
      opacity: 0.6
    }
  },
  inputLabel: {
    marginBottom: theme.typography.pxToRem(5)
  },
  adornment: {
    cursor: 'pointer',
    color: theme.palette.grey[400],
    marginRight: -theme.spacing(1.5),
    width: '40px',
    height: '26px',
    padding: theme.spacing(0.5),
    paddingRight: theme.spacing(1)
  },
  inputAdornment: {
    marginLeft: 0
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    '&.selected': {
      borderRadius: `${theme.borderRadius.md} !important`,
      boxShadow: `0 0 0 1px ${theme.paintscout.palette.primary} !important`,
      '-webkit-box-shadow': `0 0 0 1px ${theme.paintscout.palette.primary} !important`,
      '-webkit-border-radius': '0px',
      '-webkit-appearance': 'none'
    }
  }
}))

interface DatePickerInputsProps {
  focusedInput: 'startDate' | 'endDate'
  anchorRef?: MutableRefObject<any>
  startDate?: Moment
  endDate?: Moment
  disabled?: boolean
  anchorRefRight?: boolean
  inputProps?: DatePickerInputProps
  onStartClick: () => void
  onEndClick: () => void
  onAdornmentClick?: () => void
}

export function DatePickerInputs(props: DatePickerInputsProps) {
  const {
    anchorRef,
    focusedInput,
    anchorRefRight,
    startDate,
    endDate,
    disabled,
    inputProps,
    onAdornmentClick,
    onStartClick,
    onEndClick
  } = props
  const classes = useStyles()
  const dateFormat = 'MMM D, YYYY'

  const onStartAdornmentClick = (e) => {
    if (focusedInput === 'startDate' && onAdornmentClick) {
      e.stopPropagation()
      onAdornmentClick()
    }
  }

  const onEndAdornmentClick = (e) => {
    if (focusedInput === 'endDate' && onAdornmentClick) {
      e.stopPropagation()
      onAdornmentClick()
    }
  }
  return (
    <>
      <Grid item xs={6}>
        <InputLabel className={classes.inputLabel}>{inputProps?.fromLabel || 'From'}</InputLabel>
        <div className={classnames(classes.inputWrapper, focusedInput === 'startDate' && 'selected')}>
          <InputField
            className={classnames(
              classes.base,
              focusedInput === 'startDate' && classes.selected,
              focusedInput === 'endDate' && classes.unselected
            )}
            classes={{ adornment: classes.inputAdornment }}
            placeholder={inputProps?.fromPlaceholder || 'Select Date'}
            inputRef={anchorRefRight ? undefined : anchorRef}
            readOnly
            fullWidth
            value={startDate ? startDate.format(dateFormat) : ''}
            onClick={onStartClick}
            disabled={disabled}
            onFocus={(e) => e.target.blur()} // Prevent the input from receiving focus
            endAdornment={<PencilIcon onClick={onStartAdornmentClick} className={classes.adornment} />}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <InputLabel className={classes.inputLabel}>{inputProps?.toLabel || 'To'}</InputLabel>
        <div className={classnames(classes.inputWrapper, focusedInput === 'endDate' && 'selected')}>
          <InputField
            className={classnames(
              classes.base,
              focusedInput === 'endDate' && classes.selected,
              focusedInput === 'startDate' && classes.unselected
            )}
            classes={{ adornment: classes.inputAdornment }}
            readOnly
            placeholder={inputProps?.toPlaceholder || 'Select Date'}
            inputRef={anchorRefRight ? anchorRef : undefined}
            fullWidth
            value={endDate ? endDate.format(dateFormat) : ''}
            onClick={onEndClick}
            disabled={disabled}
            onFocus={(e) => e.target.blur()} // Prevent the input from receiving focus
            endAdornment={<PencilIcon onClick={onEndAdornmentClick} className={classes.adornment} />}
          />
        </div>
      </Grid>
    </>
  )
}
