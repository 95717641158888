import type { OptionsDocument, EmailTemplate, UserPreferencesDocument } from 'paintscout'
import reduce from 'lodash/reduce'
import uniq from 'lodash/uniq'
import { getFeature } from '../get-features'

export const defaultTemplates = {
  'send-invoice-client': {
    label: 'Send Invoice to Client',
    subject: 'Your Invoice from {{companyName}}',
    body: 'To access your invoice from {{companyName}} please click the link below.\n\nBest regards,\n\n{{companyName}}',
    name: 'send-invoice-client'
  },
  'send-work-order-staff': {
    label: 'Send Work Order to Staff',
    subject: 'Your Job from {{companyName}}',
    body: 'To access your job from {{companyName}} please click the link below.\n\nBest regards,\n\n{{companyName}}',
    name: 'send-work-order-staff'
  },
  'send-quote-client': {
    label: 'Send Quote to Client',
    subject: 'Your Estimate from {{companyName}}',
    body: 'To access your estimate from {{companyName}} please click the link below.\n\nBest regards,\n\n{{companyName}}',
    name: 'send-quote-client'
  },
  'send-quote-staff': {
    label: 'Quote Sent Notification to Staff',
    subject: 'Quote #{{quoteNumber}} has been sent',
    body: 'To view the quote please click the link below.',
    name: 'send-quote-staff',
    enabled: true
  },
  'send-invoice-staff': {
    label: 'Invoice Sent Notification to Staff',
    subject: 'Invoice #{{quoteNumber}} has been sent',
    body: 'To view the invoice please click the link below.',
    name: 'send-invoice-staff'
  },
  'quote-accepted-notification': {
    label: 'Quote Accepted Notification to Staff',
    subject: 'Your Quote has been Accepted',
    body: 'To view the quote, please click the link below.',
    name: 'accept-quote-staff',
    enabled: false
  },
  'accept-thank-you': {
    label: 'Quote Accepted Thank You to Customer',
    subject: 'Thank You for Accepting!',
    body: "Thank you for accepting your quote from {{companyName}}. We'll be in touch!",
    name: 'accept-thank-you',
    enabled: false
  },
  'decline-thank-you': {
    label: 'Quote Declined Email Sent to Client',
    subject: 'You have declined #{{quoteNumber}}!',
    body: 'Hey #{{clientName}}, we see you declined your quote! If you have any questions, please let us know!',
    name: 'decline-thank-you',
    enabled: false
  },
  'quote-declined-notification': {
    label: 'Quote Declined Notification to Staff',
    subject: 'Your Quote has been Declined',
    body: 'To view the quote, please click the link below.',
    name: 'decline-quote-staff',
    enabled: false
  },
  'product-order-form': {
    label: 'Product Order Form',
    subject: 'Product Order Form',
    body: 'To view the product order form, please click the link below.',
    name: 'product-order-form'
  },
  'payment-received': {
    label: 'Payment Received Email Sent to Client',
    subject: 'Your Payment to {{companyName}} has been Received',
    body: 'Hey {{clientName}},\nWe have received your payment. Thank you!\n\nBest regards,\n\n{{companyName}}.',
    name: 'payment-received',
    enabled: true
  },
  'payment-request': {
    label: 'Payment Request Email Sent to Client',
    subject: 'Payment Request',
    body: 'Hey {{clientName}}, you have a payment request from {{companyName}}.',
    name: 'payment-request'
  }
}

export function getEmailTemplates({
  options,
  userPreferences
}: {
  options?: OptionsDocument
  userPreferences?: UserPreferencesDocument
}): Record<keyof typeof defaultTemplates, EmailTemplate> {
  const optionEmails = options ? options.options?.emails?.values ?? {} : {}
  const userEmails = userPreferences ? userPreferences.emails?.templates?.values ?? {} : {}
  const hasProfileCommunications = !getFeature({
    options,
    preferences: userPreferences,
    path: 'settingsCommunications.enabled'
  })

  const emails = {
    ...defaultTemplates,
    ...optionEmails,
    ...(hasProfileCommunications ? userEmails : {})
  }

  // merge email additionalRecipient values
  const emailsWithMergedRecipients = reduce(
    emails,
    (result, email, key) => {
      const optionRecipients = optionEmails[key] ? optionEmails[key].additionalRecipients ?? [] : []
      const userRecipients = userEmails[key] ? userEmails[key].additionalRecipients ?? [] : []

      return {
        ...result,
        [key]: {
          ...email,
          additionalRecipients: uniq([...(hasProfileCommunications ? userRecipients : []), ...optionRecipients])
        }
      }
    },
    { ...emails }
  )

  return emailsWithMergedRecipients
}
