import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core/styles'
import type { DialogProps } from '@ui/paintscout'
import type { StyleClasses } from '@ui/core/theme'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Typography,
  Checkbox,
  Collapse
} from '@ui/paintscout'
import * as Sentry from '@sentry/core'
import { useSnackbar } from 'notistack'
import type { OptionsDocument, OrderableOption } from 'paintscout'
import type { ValueType } from '@ui/paintscout/src/TileList'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { getQuoteTypeOptions } from '@paintscout/util/builder'

const useStyles = makeStyles<Theme, BulkTypeAssignDialogDialogProps>(
  (_theme) => ({
    root: {},
    dialogTitle: {},
    dialogContent: {},
    cancel: {},
    confirm: {},
    typeSelector: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center'
    },
    typeSelectorHolder: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start'
    },
    typeSelectorChild: {
      paddingLeft: '20px'
    }
  }),
  { name: 'BulkTypeAssignDialogDialog' }
)

export interface BulkTypeAssignDialogDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof useStyles>
  keys: ValueType[]
  field: string
  options: OptionsDocument

  onConfirm: (updatedOptions: OptionsDocument) => void
  onCancel: (event: React.MouseEvent<HTMLElement>) => any
}

function BulkTypeAssignDialogDialog(props: BulkTypeAssignDialogDialogProps) {
  const classes = useStyles(props)
  const { onConfirm, onCancel, options, keys, field, ...baseDialogProps } = props
  const { enqueueSnackbar } = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(null)
  const quoteTypes = getQuoteTypeOptions({ options, inactive: true })
  const selectedItems = keys.map((key: string) => {
    return {
      ...(options.options[field]?.values[key] as OrderableOption),
      key
    }
  })

  const [changeState, setChangeState] = useState({
    ...quoteTypes.reduce((acc, quoteType) => {
      return {
        ...acc,
        [quoteType.value]: {
          isSelected: false,
          selectedChildren: selectedItems.reduce((acc, item) => {
            console.log({ item, quoteType })
            if (item.quoteTypes.includes(quoteType.value)) {
              return [...acc, item]
            } else {
              return acc
            }
          }, [])
        }
      }
    }, {})
  })

  const leftButton = (
    <Button className={classes.cancel} disabled={isLoading} variant={'text'} onClick={onCancel}>
      {'< Back'}
    </Button>
  )

  return (
    <Dialog {...baseDialogProps}>
      <DialogTitle classes={{ root: classes.dialogTitle }}>
        <Typography variant="h2">Select Quote Type Assignments</Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Typography variant="body1">Select quote type assignment adjustments below</Typography>
        {quoteTypes.map((quoteType, index) => {
          const selectedChildrenLength = changeState[quoteType.value].selectedChildren.length
          const isQuoteTypeSelected =
            changeState[quoteType.value].isSelected || selectedChildrenLength === selectedItems.length
          return (
            <div key={index} className={classes.typeSelectorHolder}>
              <div className={classes.typeSelector}>
                <div style={{ marginTop: '5px' }} onClick={() => setShow((show) => (show === index ? null : index))}>
                  {show === index ? <ExpandLess /> : <ExpandMore />}
                </div>
                {quoteType?.label}
                <Checkbox
                  key={index}
                  checked={isQuoteTypeSelected || selectedChildrenLength === selectedItems.length}
                  indeterminate={
                    selectedChildrenLength > 0 &&
                    selectedChildrenLength !== selectedItems.length &&
                    !isQuoteTypeSelected
                  }
                  onClick={() => {
                    if (isQuoteTypeSelected) {
                      setChangeState((changeState) => {
                        return {
                          ...changeState,
                          [quoteType.value]: {
                            isSelected: false,
                            selectedChildren: []
                          }
                        }
                      })
                    } else {
                      setChangeState((changeState) => {
                        return {
                          ...changeState,
                          [quoteType.value]: {
                            isSelected: true,
                            selectedChildren: selectedItems
                          }
                        }
                      })
                    }
                  }}
                />
              </div>
              <Collapse show={show === index}>
                {selectedItems.map((item, index) => {
                  return (
                    <div key={index} className={classes.typeSelectorChild}>
                      {item?.label}
                      <Checkbox
                        key={index}
                        checked={changeState[quoteType.value].selectedChildren.some((child) => item.key === child.key)}
                        onClick={() => {
                          setChangeState((changeState) => {
                            const selectedChildren = changeState[quoteType.value].selectedChildren.some(
                              (child) => item.key === child.key
                            )
                              ? changeState[quoteType.value].selectedChildren.filter(
                                  (selectedChild) => selectedChild.key !== item.key
                                )
                              : [
                                  ...changeState[quoteType.value].selectedChildren,
                                  { ...item, quoteTypes: [...item.quoteTypes, quoteType.value] }
                                ]
                            return {
                              ...changeState,
                              [quoteType.value]: {
                                isSelected: selectedChildren.length ? changeState[quoteType.value].isSelected : false,
                                selectedChildren: selectedChildren
                              }
                            }
                          })
                        }}
                      />
                    </div>
                  )
                })}
              </Collapse>
            </div>
          )
        })}
      </DialogContent>
      <DialogActions leftButton={leftButton}>
        <Button
          className={classes.confirm}
          form="bulk-type-assign-dialog-form"
          variant={'contained'}
          disabled={isLoading}
          data-testid="bulk-type-assign-dialog-confirm-button"
          onClick={handleSubmit}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )

  async function handleSubmit() {
    setIsLoading(true)
    // For each selected item, check if it is in any of changeStates quote type selected children
    // add that quote type to the items quote items if it does
    const newSelectedItems = selectedItems.map((item) => {
      const newQuoteTypes = Object.keys(changeState).reduce((acc, quoteType) => {
        const selectedChildren = changeState[quoteType].selectedChildren
        if (selectedChildren.some((child) => child.key === item.key)) {
          return [...acc, quoteType]
        } else {
          return acc
        }
      }, [])

      return {
        ...item,
        quoteTypes: newQuoteTypes
      }
    })

    const updatedOptions = {
      ...options,
      options: {
        ...options.options,
        [field]: {
          ...options.options[field],
          values: {
            ...options.options[field].values,
            ...newSelectedItems.reduce((acc, item) => {
              return {
                ...acc,
                [item.key]: item
              }
            }, {})
          }
        }
      }
    }
    try {
      onConfirm(updatedOptions)
    } catch (error) {
      Sentry.captureException(error)
      enqueueSnackbar('error', { variant: 'error' })
    }
    setIsLoading(false)
  }
}

export default BulkTypeAssignDialogDialog
