import {
  Button,
  Grid,
  DialogStackContext,
  ExportJsonDialog,
  FormSection,
  FormSectionTitle,
  FormControlLabel,
  ImportJsonDialog,
  Checkbox,
  SplitMenuButton,
  MenuItem,
  Typography,
  ConfirmationDialog,
  Select,
  SelectUserDialog,
  InputField
} from '@ui/paintscout'
import { isEmailValid } from '@paintscout/util'
import { useFormikContext } from 'formik'
import ArrowIcon from '@material-ui/icons/ArrowDropDown'
import type { OptionsDocument, RatesDocument, ClientMetaDocument } from 'paintscout'
import ImportUserDialog from '../ImportUserDialog'
import React, { useContext, useState } from 'react'
import type { User } from '@paintscout/api'
import { useTransferUserDocsMutation, useDeleteUserDocsMutation, useSendUserStatDigestMutation } from '@paintscout/api'
import { useSnackbar } from 'notistack'
import find from 'lodash/find'

export interface ClientImportExportProps {}

export interface OptionItem {
  value: number | string
  label: string
}

export const selectOptions = [
  { value: 'quote', label: 'Quote' },
  { value: 'contact', label: 'Contact' },
  { value: 'both', label: 'Quote & Contact' }
]
export interface UserImportExportProps {
  quoteEmails: boolean
  customEmailTemplates: boolean
  thankYouAcceptEmails: boolean
  thankYouDeclineEmails: boolean
  paymentReceivedEmail: boolean
  followUps: boolean
}

const UserImportExport = () => {
  const { openDialog, dismissDialog } = useContext(DialogStackContext)
  const { values, setFieldValue } = useFormikContext<{
    options: OptionsDocument
    rates: RatesDocument
    user: User
    meta: ClientMetaDocument
  }>()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = values

  const defaultExportOptions = {
    quoteEmails: false,
    customEmailTemplates: false,
    thankYouAcceptEmails: false,
    thankYouDeclineEmails: false,
    paymentReceivedEmail: false,
    followUps: false
  } as UserImportExportProps
  const [exportOptions, setExportOptions] = useState(defaultExportOptions)
  const [newEstimator, setNewEstimator] = useState<User>(null)
  const [transferUserDocs] = useTransferUserDocsMutation()
  const [deleteUserDocs] = useDeleteUserDocsMutation()
  const [sendUserStatDigest] = useSendUserStatDigestMutation()
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false)
  const [transferLoading, setTransferLoading] = useState<boolean>(false)
  const [deleteOption, setDeleteOption] = useState<string>('')
  const [transferOption, setTransferOption] = useState<string>('')
  const [sendEmail, setSendEmail] = useState<string>('')
  const exportingTemplateEmails =
    exportOptions.thankYouAcceptEmails ||
    exportOptions.thankYouDeclineEmails ||
    exportOptions.quoteEmails ||
    exportOptions.paymentReceivedEmail
  function handleExport() {
    openDialog(ExportJsonDialog, {
      item: {
        user: {
          preferences: {
            emails: {
              ...(exportOptions.followUps && { followUps: user.preferences.emails.followUps }),
              ...(exportingTemplateEmails
                ? {
                    templates: {
                      order: user.preferences.emails.templates.order,
                      values: {
                        ...(exportOptions.thankYouAcceptEmails && {
                          'accept-thank-you': user.preferences.emails.templates.values['accept-thank-you']
                        }),
                        ...(exportOptions.thankYouDeclineEmails && {
                          'decline-thank-you': user.preferences.emails.templates.values['decline-thank-you']
                        }),
                        ...(exportOptions.paymentReceivedEmail && {
                          'payment-received': user.preferences.emails.templates.values['payment-received']
                        }),
                        ...(exportOptions.quoteEmails && {
                          'send-invoice-client': user.preferences.emails.templates.values['send-invoice-client'],
                          'send-quote-client': user.preferences.emails.templates.values['send-quote-client'],
                          'send-work-order-staff': user.preferences.emails.templates.values['send-work-order-staff']
                        })
                      }
                    }
                  }
                : null),
              ...(exportOptions.customEmailTemplates && {
                customTemplates: user?.preferences?.emails?.customTemplates ?? { order: [], values: {} }
              })
            }
          }
        }
      },
      onConfirm: dismissDialog
    })
  }

  function handleEstimatorSelect() {
    openDialog(SelectUserDialog, {
      companyId: user.client.meta._id,
      currentUser: user,
      onConfirm: (newUser: User) => {
        dismissDialog()
        if (!newUser) {
          setNewEstimator(null)
        }

        if (newUser.user_id === user.user_id) {
          enqueueSnackbar('Must choose a different Estimator', { variant: 'warning' })
          setNewEstimator(null)
        } else {
          setNewEstimator(newUser)
        }
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDocTransfer(type: string) {
    openDialog(ConfirmationDialog, {
      title: `Confirm ${type === 'both' ? 'Quote & Contact' : type} Transfer`,
      message: `Are you sure you want to transfer all owned ${
        type === 'both' ? 'Quotes & Contacts' : `${type}'s`
      } from ${user.name} to ${newEstimator.name}?`,
      scary: true,
      onConfirm: async () => {
        dismissDialog()
        setTransferLoading(true)
        const res = await transferUserDocs({
          variables: {
            id: user.user_id,
            transferTo: newEstimator.user_id,
            type
          }
        })
        if (res) {
          enqueueSnackbar('Transfer has been started, you will receive an email summary when complete', {
            variant: 'success'
          })
        } else {
          enqueueSnackbar('Error starting transfer', { variant: 'error' })
        }
        setTransferLoading(false)
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  function handleDocDelete(type: string) {
    openDialog(ConfirmationDialog, {
      title: `Confirm ${type === 'both' ? 'Quote & Contact' : type} Delete`,
      message: `Are you sure you want to DELETE all user owned ${
        type === 'both' ? 'Quotes & Contacts' : `${type}'s`
      }? This can't be undone!`,
      scary: true,
      onConfirm: async () => {
        dismissDialog()
        setDeleteLoading(true)
        const res = await deleteUserDocs({
          variables: {
            id: user.user_id,
            type
          }
        })
        if (res) {
          enqueueSnackbar('Delete processing has been started, you will receive an email summary when complete', {
            variant: 'success'
          })
        } else {
          enqueueSnackbar('Error starting delete process', { variant: 'error' })
        }
        setDeleteLoading(false)
      },
      onCancel: () => {
        dismissDialog()
      }
    })
  }

  const handleExportCheckboxChange = (target: string) => {
    return function () {
      setExportOptions({ ...exportOptions, [target]: !exportOptions[target] })
    }
  }

  const checkForSelected = () => {
    for (const x in exportOptions) {
      if (exportOptions[x]) return false
    }
    return true
  }

  const queueDocUpdates = (json: any, selectedOptions?: UserImportExportProps) => {
    const { user: newUser } = json
    if (newUser && selectedOptions) {
      // Selected options user create, only copy selected
      const updatedUser: User = {
        ...user,
        preferences: {
          ...user.preferences,
          emails: {
            ...user.preferences.emails,
            followUps: {
              ...user.preferences.emails.followUps,
              ...(selectedOptions?.followUps && {
                values: newUser.preferences.emails.followUps.values,
                order: newUser.preferences.emails.followUps.order
              })
            },
            templates: {
              ...user.preferences.emails.templates,
              values: {
                ...user.preferences.emails.templates.values,
                ...(selectedOptions?.thankYouAcceptEmails && {
                  'accept-thank-you': newUser.preferences.emails.templates.values['accept-thank-you']
                }),
                ...(selectedOptions?.thankYouDeclineEmails && {
                  'decline-thank-you': newUser.preferences.emails.templates.values['decline-thank-you']
                }),
                ...(selectedOptions?.paymentReceivedEmail && {
                  'payment-received': newUser.preferences.emails.templates.values['payment-received']
                }),
                ...(selectedOptions?.quoteEmails && {
                  'send-invoice-client': newUser.preferences.emails.templates.values['send-invoice-client'],
                  'send-quote-client': newUser.preferences.emails.templates.values['send-quote-client'],
                  'send-work-order-staff': newUser.preferences.emails.templates.values['send-work-order-staff']
                })
              }
            },
            customTemplates: {
              ...(user.preferences?.emails?.customTemplates ?? { order: [], values: {} }),
              ...(selectedOptions?.customEmailTemplates && {
                values: newUser.preferences?.emails?.customTemplates?.values ?? {},
                order: newUser.preferences?.emails?.customTemplates?.order ?? []
              })
            }
          }
        }
      }

      setFieldValue('preferences', updatedUser.preferences)
    } else if (newUser) {
      // Pure JSON with no selected options, copy all

      const updatedUser: User = {
        ...user,
        preferences: {
          ...user.preferences,
          emails: {
            ...user.preferences.emails,
            templates: {
              ...user.preferences.emails.templates,
              ...(newUser.preferences.emails?.templates?.values && {
                values: {
                  ...user.preferences.emails.templates.values,
                  ...newUser.preferences.emails.templates.values
                },
                order: newUser.preferences.emails.templates.order
              })
            },
            followUps: {
              ...user.preferences.emails.followUps,
              ...(newUser.preferences.emails.followUps && {
                values: {
                  ...user.preferences.emails.followUps?.values,
                  ...newUser.preferences.emails.followUps?.values
                },
                order: newUser.preferences.emails.followUps.order
              })
            }
          }
        }
      }

      setFieldValue('preferences', updatedUser.preferences)
    }
  }

  const handleImportJson = () => {
    openDialog(ImportJsonDialog, {
      queueDocUpdates: queueDocUpdates,
      onClose: dismissDialog
    })
  }

  const handleImportUser = () => {
    openDialog(ImportUserDialog, {
      queueDocUpdates: queueDocUpdates,
      onClose: dismissDialog
    })
  }
  const handleSendTestEmail = async () => {
    await sendUserStatDigest({ variables: { email: sendEmail, id: user.user_id } })
  }

  return (
    <>
      <FormSectionTitle title="Import" />
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <SplitMenuButton
              onClick={handleImportJson}
              variant={'contained'}
              color={'primary'}
              Icon={ArrowIcon}
              label={'Import JSON'}
            >
              <MenuItem onClick={handleImportUser}>Import From User</MenuItem>
            </SplitMenuButton>
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionTitle title="Export" />
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <FormControlLabel
              label={'Quote Emails'}
              control={
                <Checkbox checked={exportOptions.quoteEmails} onChange={handleExportCheckboxChange('quoteEmails')} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Custom Email Templates'}
              control={
                <Checkbox
                  checked={exportOptions.customEmailTemplates}
                  onChange={handleExportCheckboxChange('customEmailTemplates')}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Thank You Accept Email'}
              control={
                <Checkbox
                  checked={exportOptions.thankYouAcceptEmails}
                  onChange={handleExportCheckboxChange('thankYouAcceptEmails')}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Thank You Decline Email'}
              control={
                <Checkbox
                  checked={exportOptions.thankYouDeclineEmails}
                  onChange={handleExportCheckboxChange('thankYouDeclineEmails')}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={'Payment Received Email'}
              control={
                <Checkbox
                  checked={exportOptions.paymentReceivedEmail}
                  onChange={handleExportCheckboxChange('paymentReceivedEmail')}
                />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              label={"Follow Up's"}
              control={
                <Checkbox checked={exportOptions.followUps} onChange={handleExportCheckboxChange('followUps')} />
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleExport} disabled={checkForSelected()}>
              Export
            </Button>
            <Button
              style={{ marginLeft: '16px' }}
              onClick={() => setExportOptions(defaultExportOptions)}
              disabled={checkForSelected()}
              variant="text"
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionTitle title="Transfer Docs" />
      <FormSection>
        <Grid container spacing={3} alignItems="flex-end">
          <Grid item xs={12}>
            <Button onClick={handleEstimatorSelect}>{newEstimator ? 'Update' : 'Select'} Receiver</Button>
          </Grid>
          {newEstimator && (
            <>
              <Grid item xs={12}>
                <Typography variant={'h4'}>Selected Receiver</Typography>
                <Typography variant={'body1'}>
                  {newEstimator?.name} - ({newEstimator.app_metadata.roles})
                </Typography>
                <Typography variant={'body1'}>{newEstimator?.email}</Typography>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={2}>
                  <Button
                    style={{ marginLeft: '16px' }}
                    disabled={transferOption === ''}
                    onClick={() => handleDocTransfer(transferOption)}
                  >
                    Transfer
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Select
                    name={'transferOptionSelect'}
                    disabled={transferLoading}
                    value={transferOption}
                    onChange={(ev: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                      setTransferOption(ev.target.value)
                    }}
                    displayEmpty
                    variant={'outlined'}
                    renderValue={(selectedValue: string) => {
                      const selected = find(selectOptions, { value: selectedValue }) as OptionItem
                      return selected?.label ?? 'Select Item...'
                    }}
                  >
                    {(selectOptions ?? []).map((item, i) => (
                      <MenuItem key={i} value={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </FormSection>
      <FormSectionTitle title="Delete Docs" />
      <FormSection>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Button disabled={deleteOption === ''} onClick={() => handleDocDelete(deleteOption)}>
              Delete
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Select
              name={'deleteOptionSelect'}
              disabled={deleteLoading}
              value={deleteOption}
              onChange={(ev: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
                setDeleteOption(ev.target.value)
              }}
              displayEmpty
              variant={'outlined'}
              renderValue={(selectedValue: string) => {
                const selected = find(selectOptions, { value: selectedValue }) as OptionItem
                return selected?.label ?? 'Select Item...'
              }}
            >
              {(selectOptions ?? []).map((item, i) => (
                <MenuItem key={i} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionTitle title="Test Gamification Email" />
      <FormSection>
        <Grid container spacing={3}>
          <Grid item xs={2}>
            <Button disabled={!isEmailValid(sendEmail)} onClick={() => handleSendTestEmail()}>
              Send
            </Button>
          </Grid>
          <Grid item xs={3}>
            <InputField
              name={'sendEmail'}
              label={'Send To'}
              placeholder={'Enter Email'}
              fullWidth
              value={sendEmail}
              onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                setSendEmail(ev.target.value)
              }}
            />
          </Grid>
        </Grid>
      </FormSection>
    </>
  )
}

export default UserImportExport
