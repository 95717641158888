import React from 'react'
import type { StyleClasses } from '@ui/core/theme'
import type { DialogProps } from '@ui/paintscout'
import { QuoteTagChip } from '@ui/paintscout'
import { SketchPicker } from 'react-color'
import { Button, Dialog, DialogActions, DialogContent, EditableDialogTitle, Grid, InputField } from '@ui/paintscout'
import type { QuoteTag } from '@paintscout/util/builder'
import { updateQuoteTag } from '@paintscout/util/builder'
import type { OptionsDocument } from 'paintscout'
import type { Theme } from '@material-ui/core'
import { createStyles, withStyles } from '@material-ui/core'
import clone from 'lodash/clone'

export interface EditQuoteTagDialogProps extends DialogProps {
  classes?: DialogProps['classes'] & StyleClasses<typeof styles>
  item: QuoteTag
  options: OptionsDocument
  isNew?: boolean
  onConfirm?: (options: OptionsDocument, isDirty?: boolean) => void
  onCancel?: () => void
}

export interface EditQuoteTagDialogState {
  item: QuoteTag
  isDirty?: boolean
}

const styles = (_theme: Theme) => {
  return createStyles({})
}

class EditQuoteTagDialog extends React.PureComponent<EditQuoteTagDialogProps, EditQuoteTagDialogState> {
  constructor(props: EditQuoteTagDialogProps) {
    super(props)
    const { item } = this.props
    this.state = {
      item: clone(item),
      isDirty: false
    }
  }

  public render() {
    const { loading, classes, options: _options, isNew: _isNew, ...otherProps } = this.props
    const { item } = this.state

    const leftButton = (
      <Button disabled={loading} onClick={this.props.onCancel} variant={'text'}>
        Cancel
      </Button>
    )
    return (
      <Dialog fullWidth={true} maxWidth={'md'} {...otherProps}>
        <EditableDialogTitle title={`${this.props.isNew ? 'Add' : 'Edit'} Tag`} loading={loading} />
        <DialogContent>
          <form
            id="area-option"
            onSubmit={(ev) => {
              ev.preventDefault()
              this.handleConfirm(ev)
            }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <InputField
                  classes={{ root: classes.input }}
                  label={'Label'}
                  name={'label'}
                  value={item.label}
                  fullWidth={true}
                  onChange={this.handleChangeLabel}
                  required
                  error={!item.label}
                />
              </Grid>
              <Grid container item alignItems="center" justifyContent="center" xs={12}>
                <SketchPicker
                  color={item.color}
                  onChange={({ hex }) => {
                    this.handleChangeColor(hex)
                  }}
                  disableAlpha={true}
                  width="55%"
                />
              </Grid>
              {item.label && (
                <Grid container justifyContent="center" item xs={12}>
                  <QuoteTagChip tag={item} />
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions showBorder={true} leftButton={leftButton}>
          <Button type="submit" form="area-option" disabled={loading} variant={'contained'}>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  public handleConfirm = (_: any) => {
    if (this.props.onConfirm) {
      const { options } = this.props
      const updatedOptions = updateQuoteTag({ options, quoteTag: this.state.item })
      this.props.onConfirm(updatedOptions, this.state.isDirty)
    }
  }

  public handleChangeLabel = (event: any) => {
    const { name, value } = event.target

    this.setState({
      item: {
        ...this.state.item,
        [name]: value
      },
      isDirty: true
    })
  }

  public handleChangeColor = (newColor: string) => {
    this.setState({
      item: {
        ...this.state.item,
        color: newColor
      },
      isDirty: true
    })
  }
}

export default withStyles(styles)(EditQuoteTagDialog)
