import React from 'react'
import type { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'

import Chip from '@material-ui/core/Chip'

import find from 'lodash/find'
import { getFeature, getObjectLabels } from '@paintscout/util/builder'
import type { DropdownSelectOption, SingleSelectProps } from '../DropdownSelect'
import DropdownSelect from '../DropdownSelect'
import { useClientOptions } from '../ClientOptionsProvider'
import Grid from '../Grid'

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    ...theme.typography.overline,
    borderRadius: theme.borderRadius.md,
    height: '41px'
  }
}))

export interface RateTypeSelectProps extends Omit<SingleSelectProps, 'value' | 'onChange' | 'variant'> {
  rateType?: string
  calculationType?: string
  onChange?: (rateType: string, calculationType: string) => void
}

export interface RateCalculationType {
  rateType: string
  calculationType: string
}

export interface RateTypeVariables {
  variable1: string
  variable2: string
}

export default function RateTypeSelect(props: RateTypeSelectProps) {
  const { rateType = 'sqftWalls', calculationType = 'amountPerHour', onChange, disabled } = props
  const classes = useStyles()
  const { options } = useClientOptions()

  const cubicFeetFeature = getFeature({ options, path: 'features.quotes.areaSubstrates.advancedOptions.cubicFeet' })
  const { unit, currency } = getObjectLabels({ options })
  const unitLabel = unit.abbreviation.square

  function convertToVariables(args: RateCalculationType): RateTypeVariables {
    const { rateType, calculationType } = args

    let variable1
    let variable2

    if (calculationType === 'amountPerHour') {
      if (rateType === 'quantity') {
        variable1 = 'hours'
        variable2 = rateType
      } else {
        variable1 = rateType
        variable2 = 'hours'
      }
    } else if (calculationType === 'pricePerAmount') {
      variable1 = 'price'
      variable2 = rateType
    } else if (calculationType === 'hoursPerAmount') {
      variable1 = 'hours'
      variable2 = rateType
    } else if (calculationType === 'amountPerPrice') {
      variable1 = rateType
      variable2 = 'price'
    } else if (calculationType === 'hours') {
      variable1 = 'hours'
      variable2 = 'none'
    } else if (calculationType === 'price') {
      variable1 = 'price'
      variable2 = 'none'
    } else {
      variable1 = 'sqftWalls'
      variable2 = 'hours'
    }

    return {
      variable1,
      variable2
    }
  }

  function getDropdownOptions(isFirst?: boolean): DropdownSelectOption[] {
    return [
      {
        value: 'sqftWalls',
        label: `${unitLabel} (Walls)`
      },
      {
        value: 'sqftCeiling',
        label: `${unitLabel} (Ceiling)`
      },
      {
        value: 'sqftFloor',
        label: `${unitLabel} (Floor)`
      },
      {
        value: 'lnft',
        label: `Linear ${unit.plural}`
      },
      !isFirst && {
        value: 'quantity',
        label: 'Item'
      },

      cubicFeetFeature && {
        value: 'cuft',
        label: `Cubic ${isFirst ? unit.plural : unit.value}`
      },
      {
        value: 'hours',
        label: `Hour${isFirst ? 's' : ''}`
      },
      {
        value: 'price',
        label: currency.symbol
      },
      !isFirst && {
        value: 'none',
        label: '-'
      }
    ].filter((item) => item)
  }

  function getVariable2Options(variable1: string): DropdownSelectOption[] {
    switch (variable1) {
      case 'sqftWalls':
      case 'sqftCeiling':
      case 'sqftFloor':
      case 'lnft':
      case 'cuft':
        return [
          {
            value: 'hours',
            label: 'Hour'
          }
        ]
      case 'hours':
        return [
          {
            value: 'quantity',
            label: 'Item'
          },
          {
            value: 'none',
            label: '-'
          }
        ]
      case 'price':
        return [
          {
            value: 'sqftWalls',
            label: `${unitLabel} (Walls)`
          },
          {
            value: 'sqftCeiling',
            label: `${unitLabel} (Ceiling)`
          },
          {
            value: 'sqftFloor',
            label: `${unitLabel} (Floor)`
          },
          {
            value: 'lnft',
            label: `Linear ${unit.plural}`
          },
          {
            value: 'quantity',
            label: 'Item'
          },
          cubicFeetFeature
            ? {
                value: 'cuft',
                label: `Cubic ${unit.plural}`
              }
            : null,
          {
            value: 'none',
            label: '-'
          }
        ].filter((item) => item)
      default:
        return []
    }
  }

  function convertToTypes(args: RateTypeVariables): RateCalculationType {
    const { variable1, variable2 } = args

    let rateType
    let calculationType

    if (isDimension(variable1)) {
      // this is a sqft per XX rate
      rateType = variable1
      if (variable2 === 'hours' && rateType !== 'quantity') {
        calculationType = 'amountPerHour'
      } else if (variable2 === 'hours' && rateType === 'quantity') {
        calculationType = 'hoursPerAmount'
      } else {
        calculationType = 'amountPerPrice'
      }
    } else {
      rateType = variable2
      if (variable1 === 'hours' && rateType === 'none') {
        calculationType = 'hours'
      } else if (variable1 === 'price' && rateType === 'none') {
        calculationType = 'price'
      } else if (variable1 === 'hours' && rateType !== 'quantity') {
        // I dont think this situation is possible, leaving just incase..
        calculationType = 'hoursPerAmount'
      } else if (variable1 === 'hours' && rateType === 'quantity') {
        calculationType = 'hoursPerAmount'
      } else {
        calculationType = 'pricePerAmount'
      }
    }

    return {
      rateType,
      calculationType
    }
  }

  function checkOptions(args: { variable1: string; variable2: string }) {
    const dropdownOptions = getDropdownOptions(true)
    const { variable1, variable2 } = args

    const selectedVariable1 = find(dropdownOptions, { value: variable1 })
    const availableOptions = getVariable2Options(variable1)

    if (!selectedVariable1 || !find(availableOptions, { value: variable2 })) {
      return {
        variable1,
        variable2: availableOptions[0].value
      }
    }

    return {
      variable1,
      variable2
    }
  }

  function isDimension(s1: string, s2?: string): boolean {
    const r1 = s1.startsWith('sqft') || s1 === 'lnft' || s1 === 'quantity' || s1 === 'cuft'
    const r2 = s2 ? s2.startsWith('sqft') || s2 === 'lnft' || s2 === 'quantity' || s2 === 'cuft' : true

    return r1 && r2
  }

  function handleChange(name: string, option: DropdownSelectOption) {
    if (!onChange) {
      return
    }

    const variables = checkOptions({
      ...convertToVariables({ rateType, calculationType }),
      [name]: option.value
    })

    const { rateType: updatedRateType, calculationType: updatedCalculationType } = convertToTypes(variables)
    onChange(updatedRateType, updatedCalculationType)
  }

  const { variable1, variable2 } = convertToVariables({ rateType, calculationType })

  const variable1DropdownOptions = getDropdownOptions(true)
  const selectedVariable1 = find(variable1DropdownOptions, { value: variable1 })

  const variable2DropdownOptions = getVariable2Options(variable1)
  const selectedVariable2 = find(getDropdownOptions(), { value: variable2 })

  return (
    <Grid container spacing={2} justifyContent="space-between" alignItems="flex-end">
      <Grid item xs>
        <DropdownSelect
          name={'variable1'}
          label={'Variable 1'}
          value={selectedVariable1}
          variant={'single'}
          onChange={(option: DropdownSelectOption) => handleChange('variable1', option)}
          options={variable1DropdownOptions}
          disabled={disabled}
        />
      </Grid>

      <Grid item>
        <Chip label="per" classes={{ root: classes.chip }} />
      </Grid>
      <Grid item xs>
        <DropdownSelect
          name={'variable2'}
          label={'Variable 2'}
          value={selectedVariable2}
          variant={'single'}
          onChange={(option: DropdownSelectOption) => handleChange('variable2', option)}
          options={variable2DropdownOptions}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}
