import React, { useEffect, useState } from 'react'
import InputLabel from '../InputLabel'
import type { InputProps as CoreInputProps } from '@ui/core/Input'
import { default as CoreInput } from '@ui/core/Input'
import { makeStyles } from '@material-ui/core'
import type { Theme } from '@material-ui/core'
import MuiInput from '@material-ui/core/Input'
import Typography from '../Typography'
import Tooltip from '../Tooltip'

const useStyles = makeStyles<Theme, InputProps>(
  (theme) => ({
    root: {
      ...theme.typography.body1,
      background: 'none',
      boxSizing: 'border-box',
      color: theme.palette.text.primary,
      border: `1px solid`,
      borderRadius: theme.borderRadius.md,
      transition: 'border 0.1s, box-shadow 0.1s',
      '& textarea': {
        minHeight: ({ multilineGrow }) => (multilineGrow ? 0 : '38px')
      },
      backgroundColor: ({ disabled, error }) =>
        disabled ? theme.palette.grey[200] : error ? '#f9e6e6' : theme.palette.background.paper,
      borderColor: ({ error }) => (error ? theme.palette.error.main : theme.palette.grey.A100),
      borderWidth: 1,
      boxShadow: ({ error }) => (error ? `0 0 0 1px ${theme.palette.error.main}` : 'none'),
      padding: ({ multilineGrow }) => (multilineGrow ? '0px !important' : 'unset'),
      '&:hover:not(:focus-within)': {
        borderColor: ({ disabled, error }) =>
          disabled ? theme.palette.grey.A100 : error ? theme.palette.error.main : 'transparent',
        boxShadow: ({ disabled, error }) =>
          disabled ? 'none' : `0 0 0 2px ${error ? theme.palette.error.main : theme.palette.grey[500]}`,
        boxSizing: 'border-box'
      },
      '&:focus-within': {
        borderColor: 'transparent',
        boxShadow: `0 0 0 2px ${theme.paintscout.palette.primary}`
      }
    },
    input: {
      minHeight: 20,
      padding: '0.60em',
      color: ({ disabled }) => (disabled ? theme.palette.text.disabled : theme.palette.text.primary),
      '&::placeholder': {
        opacity: 1,
        color: theme.palette.grey.A100
      }
    },
    sublabel: {},
    focused: {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
    },
    inputWrapper: (props) => ({
      ...((props.label || props.sublabel) && {
        marginTop: theme.typography.pxToRem(5)
      })
    }),
    toolTipDiv: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'flex-start'
    },
    toolTipLabel: {
      fontSize: '0.75rem',
      fontWeight: '500'
    },
    inputMultiline: ({ multilineGrow }) => {
      if (!multilineGrow) return {}
      return {
        transition: 'min-height 0.3s ease',
        '&:focus': {
          minHeight: 45
        }
      }
    },
    required: {
      color: theme.palette.error.main
    },
    error: {}
  }),
  { name: 'Input' }
)

export interface InputProps extends Omit<CoreInputProps, 'Typography'> {
  form?: any
  format?: string
  toolTip?: string | React.ReactNode
  showErrorBeforeValidation?: boolean
  multilineGrow?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(function Input(props: InputProps, ref) {
  const [showError, setShowError] = useState(!!props.showErrorBeforeValidation)
  const error = props.error && showError
  const classes = useStyles({ ...props, error })

  useEffect(() => {
    if (props.form && props.form.submitCount > 0 && !props.form.isValid) {
      setShowError(true)
    }
  }, [props.form])

  useEffect(() => {
    setShowError(!!props.showErrorBeforeValidation)
  }, [props.showErrorBeforeValidation])

  /* eslint-disable-next-line */
  let { toolTip = null, ...inputProps } = props

  // Make sure price fields are correctly formatted
  if (inputProps.format === 'price') {
    inputProps = {
      ...inputProps,
      value: Number(inputProps.value).toFixed(2)
    }
  }

  const FullInputLabel = toolTip ? (
    <div className={classes.toolTipDiv}>
      <Typography className={classes.toolTipLabel} variant="overline">
        {props.label} {props.required && <span className={classes.required}>*</span>}
      </Typography>
      <Tooltip interactive={typeof props.toolTip !== 'string'} content={props.toolTip} className={classes.toolTip} />
    </div>
  ) : (
    <InputLabel shrink={false} required={props.required} disabled={props.disabled}>
      {props.label}
    </InputLabel>
  )

  return (
    <CoreInput
      {...props}
      error={error}
      helperTextState={error ? 'error' : props.helperTextState}
      Typography={Typography}
      label={props.label ? FullInputLabel : ''}
      sublabel={
        props.sublabel ? (
          <InputLabel className={classes.sublabel} variant={'sublabel'}>
            {props.sublabel}
          </InputLabel>
        ) : (
          ''
        )
      }
      inputContent={
        <MuiInput
          {...inputProps}
          onInvalid={(e) => {
            e.preventDefault()
            setShowError(true)
          }}
          classes={{
            root: classes.root,
            input: classes.input,
            focused: classes.focused,
            inputMultiline: classes.inputMultiline
          }}
          inputRef={ref}
        />
      }
      classes={classes}
    />
  )
})

Input.defaultProps = {
  disableUnderline: true
}

export default Input
