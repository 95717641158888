import React, { useContext, useEffect, useState } from 'react'
import type { Theme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

import { Grid, Button, useClientOptions, useMediaQuery, useUser } from '@ui/paintscout'

import {
  getObjectLabels,
  getQuoteOptions,
  hasIntegrationInterface,
  isSignatureRequired
} from '@paintscout/util/builder'

import CheckIcon from '@material-ui/icons/Check'
import MoneyIcon from '@material-ui/icons/AttachMoney'

import PresentationNavMore from '../PresentationNavMore'
import PresentationNavTotal from '../PresentationNavTotal'
import { usePresentationNav } from '../PresentationNavContext'

import { QuoteContext } from '../../../context/QuoteContext'
import getDepositAmount from '@paintscout/util/util/getDepositAmount/getDepositAmount'

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      textAlign: 'right'
      // height: '100%'
    },
    acceptGridRoot: {
      height: '100%',
      padding: theme.spacing(1, 0)
    },

    button: {
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.up('lg')]: {
        marginLeft: theme.spacing(2),
        marginBottom: 0
      }
    }
  }
})

export interface PresentationAcceptButtonProps {
  type?: 'open' | 'closed'
  showMore?: boolean
  showPrice?: boolean
}

export default function PresentationAcceptButton({
  type,
  showMore,
  showPrice = true,
  ...props
}: PresentationAcceptButtonProps) {
  const classes = useStyles(props)
  const alignment = getAlignment(type)

  const { options } = useClientOptions()
  const { preferences } = useUser()
  const [responding, setResponding] = useState(false)
  const { view, allowResponse, hasPending, onAcceptClick, onPayClick, onMoreClick, revision } = usePresentationNav()
  const [variant, _] = useState(hasPending ? 'additional' : 'quote')
  const { quote, isCustomerView } = useContext(QuoteContext)
  const { allowPaymentsOnInvoices, allowPaymentsOnQuotes, depositAmount } = getQuoteOptions({
    quote,
    options
  })

  const hasStripe = hasIntegrationInterface({ preferences, options, integration: 'stripe' })
  const hasPayrix = hasIntegrationInterface({ preferences, options, integration: 'payrix' })

  useEffect(() => {
    if (variant === 'quote' && responding && quote.status.value === 'accepted') {
      setResponding(false)
    }
    if (variant === 'additional' && responding && !hasPending) {
      setResponding(false)
    }
  }, [responding, hasPending, quote.status.value])

  const objectLabels = getObjectLabels({ options, quote })

  const smDown = useMediaQuery('sm')
  const xlUp = useMediaQuery('xl', { up: true })

  const buttonLabel = getButtonLabel({ objectLabels, smDown })

  const showMoreButton = (type === 'open' || showMore) && onMoreClick

  const hasPayments = (hasStripe || hasPayrix) && !!onPayClick && quote.totals.balance_due > 0

  const hideButtonViews = ['work-order', 'product-order-form']
  const hideButtonStatus = ['invoiced', 'paid', 'declined']
  const allowQuotePayment =
    hasPayments &&
    allowPaymentsOnQuotes &&
    !quote.is_invoice &&
    !!depositAmount.value &&
    quote.totals.amount_paid === 0 &&
    quote.status.value === 'accepted' &&
    !quote.depositInfo &&
    !hideButtonViews.includes(view)

  const allowInvoicePayment =
    hasPayments && allowPaymentsOnInvoices && quote.is_invoice && !hideButtonViews.includes(view)

  const hasPaymentError = quote.paymentError

  const showAcceptButton =
    (!hideButtonStatus.includes(quote.status.value) &&
      !hideButtonViews.includes(view) &&
      (hasPending || allowResponse) &&
      onAcceptClick) ||
    responding

  const showTotal = showPrice && view !== 'product-order-form'

  const requireSignature = isSignatureRequired({
    options,
    quote,
    path: hasPending
      ? 'requireSignatureAdditionalWork'
      : isCustomerView
        ? 'requireSignatureSent'
        : 'requireSignatureOnSpot'
  })

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={1}
        wrap="nowrap"
        direction={type === 'open' ? 'column' : 'row'}
        alignItems={type === 'open' ? 'flex-end' : 'center'}
        justifyContent="flex-end"
        classes={{ container: classes.acceptGridRoot }}
      >
        <Grid item>
          <Grid container {...alignment} wrap="nowrap" direction={xlUp ? 'row-reverse' : 'column'}>
            {(allowQuotePayment || allowInvoicePayment) && (
              <Grid item>
                <Button
                  icon={MoneyIcon}
                  classes={{ root: classes.button }}
                  disabled={hasPaymentError}
                  onClick={() =>
                    onPayClick(
                      null,
                      allowQuotePayment ? getDepositAmount({ quote, options }) : quote.totals.balance_due,
                      hasPayrix
                    )
                  }
                >
                  {allowQuotePayment ? 'Pay Deposit' : 'Pay Now'}
                </Button>
              </Grid>
            )}
            {showAcceptButton && (
              <Grid item>
                <Button
                  loading={responding}
                  icon={CheckIcon}
                  disabled={!allowResponse || revision}
                  classes={{ root: classes.button }}
                  onClick={async (e) => {
                    if (!requireSignature) {
                      setResponding(true)
                    }
                    onAcceptClick(e, hasPending)
                  }}
                >
                  {buttonLabel}
                </Button>
              </Grid>
            )}
            {showTotal && (
              <Grid item>
                <PresentationNavTotal type={type} />
              </Grid>
            )}
          </Grid>
        </Grid>
        {showMoreButton && (
          <Grid item>
            <PresentationNavMore />
          </Grid>
        )}
      </Grid>
    </div>
  )

  function getButtonLabel({ objectLabels, smDown }) {
    if (objectLabels.acceptButton) {
      return objectLabels.acceptButton.value
    } else if (smDown) {
      return 'Accept'
    } else {
      const item = hasPending ? objectLabels.additionalWork.value : objectLabels.quote.value
      return `Accept ${item}`
    }
  }

  function getAlignment(type) {
    const spacing = 0 as any
    const direction = 'row-reverse' as any
    const alignItems = 'center' as any
    const justify = 'flex-end' as any

    if (type === 'open') {
      // spacing = 0 as any
      // direction = 'column' as any
      // alignItems = 'flex-end' as any
      // justify = 'flex-start' as any
    }

    return {
      spacing,
      direction,
      alignItems,
      justify
    }
  }
}
