import React from 'react'
import type { Theme, WithStyles } from '@material-ui/core'
import { withStyles, createStyles } from '@material-ui/core'

import FormatSize from '@material-ui/icons/FormatSize'

import type { InputFieldProps } from '../InputField'
import InputField from '../InputField'
import { InlineColorPicker } from '../ColorPicker'
import Grid from '../Grid'
import FontSelect from '../FontSelect'

import classnames from 'classnames'
import type { DropdownSelectOption } from '../DropdownSelect'
import DropdownSelect from '../DropdownSelect'

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    input: {},
    inputOpen: {
      $inputRoot: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
      }
    },
    toggleButton: {
      cursor: 'pointer',
      color: theme.palette.grey[800]
    },
    toggleButtonActive: {
      color: theme.palette.primary.main
    },
    formattingBoxWrapper: {
      padding: theme.spacing(1),
      border: `1px solid ${theme.palette.grey[300]}`,
      borderTop: 'none',
      borderRadius: theme.borderRadius.md
    },
    formattingBox: {
      padding: theme.spacing(2)

      // borderWidth: 1,
      // borderStyle: 'solid',
      // borderColor: theme.palette.grey[300],
      // background: theme.palette.grey[100]
    }
  })
}

export interface TextFormatInputFieldProps extends Omit<InputFieldProps, 'classes'>, WithStyles<typeof styles> {
  styleValue?: any
  onStyleChange?: (styles: any) => void
  open?: boolean
  onOpenToggle?: (ev: React.MouseEvent) => void
}

const sizeOptions: DropdownSelectOption[] = [
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' }
]

const shadowOptions: DropdownSelectOption[] = [
  { label: 'None', value: 'none' },
  { label: 'Small', value: 'small' },
  { label: 'Large', value: 'large' }
]

const transformOptions: DropdownSelectOption[] = [
  { label: 'None', value: 'none' },
  { label: 'Lowercase', value: 'lowercase' },
  { label: 'Uppercase', value: 'uppercase' }
]

function TextFormatInputField({
  classes,
  styleValue,
  onStyleChange,
  open,
  onOpenToggle,
  ...baseInputProps
}: TextFormatInputFieldProps) {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <InputField
          {...baseInputProps}
          fullWidth={true}
          classes={{ root: classnames({ [classes.input]: true, [classes.inputOpen]: open }) }}
          endAdornment={
            <FormatSize
              onClick={onOpenToggle}
              classes={{ root: classnames({ [classes.toggleButton]: true, [classes.toggleButtonActive]: open }) }}
            />
          }
        />
      </Grid>
      {open && (
        <Grid item xs={12}>
          <div className={classes.formattingBoxWrapper}>
            <div className={classes.formattingBox}>
              <Grid container spacing={2}>
                <Grid item md={12}>
                  <FontSelect label={'Font'} fullWidth={true} onChange={handleFontChange} value={styleValue?.font} />
                </Grid>
                <Grid item md={6}>
                  <InlineColorPicker
                    label={'Color'}
                    fullWidth={true}
                    onChange={handleColorChange}
                    value={styleValue?.color}
                  />
                </Grid>
                <Grid item md={6}>
                  <DropdownSelect
                    variant="single"
                    label="Size"
                    options={sizeOptions}
                    value={sizeOptions.find((item) => item.value === styleValue?.size)}
                    onChange={(option) => onStyleChange({ ...styleValue, size: option.value })}
                  />
                </Grid>
                <Grid item md={6}>
                  <DropdownSelect
                    variant="single"
                    label="Shadow"
                    options={shadowOptions}
                    value={shadowOptions.find((item) => item.value === styleValue?.shadow)}
                    onChange={(option) => onStyleChange({ ...styleValue, shadow: option.value })}
                  />
                </Grid>
                <Grid item md={6}>
                  <DropdownSelect
                    variant="single"
                    label="Transform"
                    options={transformOptions}
                    value={transformOptions.find((item) => item.value === styleValue?.transform)}
                    onChange={(option) => onStyleChange({ ...styleValue, transform: option.value })}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      )}
    </Grid>
  )

  function handleColorChange(color: string) {
    onStyleChange({
      ...styleValue,
      color
    })
  }

  function handleFontChange(font: string) {
    onStyleChange({
      ...styleValue,
      font
    })
  }
}

export default withStyles(styles)(TextFormatInputField)
